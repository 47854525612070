import {callLambda} from '@/lib/api/lambda';

/**
 * getId
 * @return {String} id
 */
const getId = () => {
  const now = new Date().getTime().toString(36);
  const eventID = Math.random().toString(36);
  return `${eventID}${now}`.slice(2, 12);
};

/**
 * Check is electron
 * @param {String} eventName eventName
 * @param {Object} contents contents
 */
const fbTrack = async (eventName, contents = {}) => {
  if (window.fbq) {
    const options = {eventID: getId()};
    try {
      await window.fbq('track', eventName, contents, options);
    } catch (error) {
      console.log('fbq');
      console.log(error);
    }

    try {
      await callLambda({
        category: 'hjj',
        method: 'post',
        apiName: 'fbApi',
        data: {eventName, contents, options},
      });
    } catch (error) {
      console.log('fbApi');
      console.log(error);
    }
  }
};

export default fbTrack;
