const nftArr = {
  Level: '等級',
  Background: '背景',
  Modeling: '造型',
  Headdress: '配飾',
  'Year of Game': '對局年代',
  'Long hair with chiffon smock_open eyes': '長髮搭雪紡衫-睜眼',
  'Long hair with chiffon smock_close eyes': '長髮搭雪紡衫-閉眼',
  'Ponytail with chiffon smock_open eyes': '馬尾搭雪紡衫-睜眼',
  'Ponytail with chiffon smock_close eyes': '馬尾搭雪紡衫-閉眼',
  'Long hair with blazer_open eyes': '長髮搭西裝-睜眼',
  'Long hair with blazer_close eyes': '長髮搭西裝-閉眼',
  'Ponytail with blazer_open eyes': '馬尾搭西裝-睜眼',
  'Ponytail with blazer_close eyes': '馬尾搭西裝-閉眼',
  'Bun with kimono_open eyes': '盤髮搭和服-睜眼',
  'Bun with kimono_close eyes': '盤髮搭和服-閉眼',
  'Cloudy day of digital style': '天空數位雲',
  'Cloudy night of digital style': '黑夜數位雲',
  'Sakura of digital style': '數位櫻花樹',
  'BUBU mask': 'BUBU面具',
  'Flower hair accessory': '小花髮飾',
  'Golden flower hair accessory': '金絲花髮飾',
  'Angel wings hair accessory': '天使翅膀髮飾',
  SS: 'SS',
  S: 'S',
  A: 'A',
};

const lang = {
  我的課程: '我的課程',
  測試訊息: '測試訊息',
  課程: '課程',
  練習: '練習',
  檢定: '檢定',
  個人: '個人',
  儲存: '儲存',
  對戰: '對戰',
  上次觀看: '上次觀看',
  開始學習: '開始學習',
  第: '第',
  課: '課',
  歡迎來到黑嘉嘉圍棋教室: '歡迎來到黑嘉嘉圍棋教室',
  早安: '早安',
  午安: '午安',
  晚安: '晚安',
  有新課程: '有新課程',
  下棋: '下棋',
  做題: '做題',
  棋力檢定: '棋力檢定',
  載入中: '載入中',
  AI計算中: 'AI計算中',
  黑勝: '黑勝',
  白勝: '白勝',
  黑中盤勝: '黑中盤勝',
  白中盤勝: '白中盤勝',
  請耐心等待: '請耐心等待',
  '有地方沒下完，還不能申請算輸贏': '有地方沒下完，還不能申請算輸贏',
  手以上才能申請算輸赢哦: '手以上才能申請算輸赢哦',
  '（黑{blackScore}，黑收後）': '（黑{blackScore}，黑收後）',
  '（黑{blackScore}，白收後）': '（黑{blackScore}，白收後）',
  '準備好和我一起進入圍棋的世界了嗎？': '準備好和我一起進入圍棋的世界了嗎？',
  黑嘉嘉圍棋教室: '黑嘉嘉圍棋教室',
  '由淺入深，量身打造學習體驗': '由淺入深，量身打造學習體驗',
  '通過線上檢定，取得棋力證書': '通過線上檢定，取得棋力證書',
  開啟圍棋之旅: '開啟圍棋之旅',
  請輸入帳號: '請輸入帳號',
  '請輸入8-15位密碼': '請輸入8-15位密碼',
  查無此帳號: '查無此帳號',
  '*請輸入帳號': '*請輸入帳號',
  '*請輸入密碼': '*請輸入密碼',
  '*查無此帳號': '*查無此帳號',
  '*您的帳號已被停用': '*您的帳號已被停用',
  '*不能少於6位數': '*不能少於6位數',
  '*密碼錯誤': '*密碼錯誤',
  登入: '登入',
  '忘記密碼？': '忘記密碼？',
  我同意: '我同意',
  和: '和',
  '還沒有帳號？前往': '還沒有帳號？前往',
  官網購買課程: '官網購買課程',
  尚未同意服務條款和隱私政策: '尚未同意服務條款和隱私政策',
  '*伺服器發生錯誤，請稍後再試': '*伺服器發生錯誤，請稍後再試',
  提醒: '提醒',
  關閉螢幕錄影才能繼續使用: '關閉螢幕錄影才能繼續使用',
  下棋累計數: '下棋累計數',
  做題累計數: '做題累計數',
  下棋勝率: '下棋勝率',
  做題正確率: '做題正確率',
  證書: '證書',
  棋譜: '棋譜',
  密碼: '密碼',
  設定: '設定',
  聯絡我們: '聯絡我們',
  登出: '登出',
  成長紀錄: '成長紀錄',
  編輯: '編輯',
  頭像: '頭像',
  名字: '名字',
  編輯名字: '編輯名字',
  編輯個人頭像: '編輯個人頭像',
  完成: '完成',
  請輸入新名字: '請輸入新名字',
  '*請輸入新名字': '*請輸入新名字',
  更改密碼: '更改密碼',
  請輸入舊密碼: '請輸入舊密碼',
  '*請輸入舊密碼': '*請輸入舊密碼',
  '*舊密碼輸入錯誤': '*舊密碼輸入錯誤',
  '請輸入8-15位新密碼': '請輸入8-15位新密碼',
  '*請輸入新密碼': '*請輸入新密碼',
  '*兩次新密碼輸入不同': '*兩次新密碼輸入不同',
  請再次輸入新密碼: '請再次輸入新密碼',
  '*請再次輸入新密碼': '*請再次輸入新密碼',
  '*新密碼和舊密碼一樣': '*新密碼和舊密碼一樣',
  '*不能少於8碼': '*不能少於8碼',
  密碼更改失敗: '密碼更改失敗',
  密碼更改成功: '密碼更改成功',
  返回上一頁: '返回上一頁',
  棋力證書: '棋力證書',
  音效: '音效',
  直接落子: '直接落子',
  檢定說明: '檢定說明',
  歷史成績單: '歷史成績單',
  開始檢定: '開始檢定',
  檢定進行中: '檢定進行中',
  成績將於隔日早上10點公布: '成績將於隔日早上10點公布',
  '{count}個項目。': '{count}個項目。',
  你已完成所有項目: '你已完成所有項目',
  棋力檢定包含: '棋力檢定包含',
  公布: '公布',
  '各項目皆需在規定時間內完成作答。': '各項目皆需在規定時間內完成作答。',
  '分數達評分標準即通過測驗 , 將升級個人棋力並授予證書。':
    '分數達評分標準即通過測驗 , 將升級個人棋力並授予證書。',
  隔日早上10點: '隔日早上10點',
  評分標準: '評分標準',
  題: '題',
  大局: '大局',
  佈局: '佈局',
  戰鬥: '戰鬥',
  官子: '官子',
  綜合: '綜合',
  好棋率: '好棋率',
  盤: '盤',
  滿分100分: '滿分100分',
  得分超過: '得分超過',
  所有項目加總滿分為100分: '所有項目加總滿分為100分',
  '測驗時間{time}分鐘': '測驗時間{time}分鐘',
  '共{count}題': '共{count}題',
  '每題{score}分': '每題{score}分',
  計分項目為: '計分項目為',
  以上: '以上',
  視為通過: '視為通過',
  開始: '開始',
  已完成: '已完成',
  目前沒有成績單: '目前沒有成績單',
  檢定後歷史成績單將會顯示在這: '檢定後歷史成績單將會顯示在這',
  已通過: '已通過',
  未通過: '未通過',
  成績單: '成績單',
  棋力檢定成績單: '棋力檢定成績單',
  分: '分',
  得分: '得分',
  勝負: '勝負',
  其他: '其他',
  已領取: '已領取',
  領取證書: '領取證書',
  再次檢定: '再次檢定',
  請再試一次: '請再試一次',
  你已完成所有檢定: '你已完成所有檢定',
  '恭喜你完成所有課程！': '恭喜你完成所有課程！',
  立即觀賞完課特輯: '立即觀賞完課特輯',
  提子: '提子',
  算輸贏: '算輸贏',
  題目: '題目',
  我的答案: '我的答案',
  解答: '解答',
  將繼續上次未下完的棋局: '將繼續上次未下完的棋局',
  對局中: '對局中',
  看起來還沒下完哦: '看起來還沒下完哦',
  目前沒有棋譜: '目前沒有棋譜',
  下棋後個人棋譜將會顯示在這: '下棋後個人棋譜將會顯示在這',
  共有: '共有',
  個棋譜: '個棋譜',
  白超時負: '白超時負',
  黑超時負: '黑超時負',
  '黑{v1}又{v2}/4子勝': '黑{v1}又{v2}/4子勝',
  '黑{v2}/4子勝': '黑{v2}/4子勝',
  '白{v1}又{v2}/4子勝': '白{v1}又{v2}/4子勝',
  '白{v2}/4子勝': '白{v2}/4子勝',
  '網路不穩定，將自動重新整理': '網路不穩定，將自動重新整理',
  '棋局結束啦，請點擊［算輸贏］按鈕！': '棋局結束啦，請點擊［算輸贏］按鈕！',
  還沒輪到你哦: '還沒輪到你哦',
  請先離開: '請先離開',
  數地: '數地',
  模式: '模式',
  投降: '投降',
  取消: '取消',
  確定: '確定',
  返回: '返回',
  再一局: '再一局',
  再一次: '再一次',
  '確定要認輸？': '確定要認輸？',
  下棋練習: '下棋練習',
  '開放條件：通過10k檢定': '開放條件：通過10k檢定',
  解鎖條件: '解鎖條件',
  開放: '開放',
  中級: '中級',
  高級: '高級',
  基礎: '基礎',
  入門: '入門',
  初學: '初學',
  初級: '初級',
  '是否繼續上次的對局？': '是否繼續上次的對局？',
  繼續: '繼續',
  不繼續: '不繼續',
  返回首頁: '返回首頁',
  看解答: '看解答',
  做題練習: '做題練習',
  切換難度: '切換難度',
  難度: '難度',
  '新手 30k': '新手 30k',
  '基礎 25k': '基礎 25k',
  '入門 20k': '入門 20k',
  '初學 15k': '初學 15k',
  '高級 9級~15級': '高級 9級~15級',
  '衝段 4級~1段': '衝段 4級~1段',
  答對: '答對',
  共: '共',
  黑棋如何做活: '黑棋如何做活',
  黑棋如何殺掉白棋: '黑棋如何殺掉白棋',
  黑棋如何吃掉白棋: '黑棋如何吃掉白棋',
  黑棋如何不被白棋吃掉: '黑棋如何不被白棋吃掉',
  黑棋如何比氣吃掉白棋: '黑棋如何比氣吃掉白棋',
  黑棋如何用接不歸吃掉白棋: '黑棋如何用接不歸吃掉白棋',
  黑棋要從哪個方向叫吃白棋: '黑棋要從哪個方向叫吃白棋',
  黑棋要如何把白棋切斷: '黑棋要如何把白棋切斷',
  如何把黑棋連在一起: '如何把黑棋連在一起',
  黑棋要如何把空圍好: '黑棋要如何把空圍好',
  黑棋如何用門吃吃掉白棋: '黑棋如何用門吃吃掉白棋',
  黑棋如何用反提吃掉白棋: '黑棋如何用反提吃掉白棋',
  黑棋要如何打劫: '黑棋要如何打劫',
  黑棋如何避免被白棋雙叫吃: '黑棋如何避免被白棋雙叫吃',
  黑棋如何做出眼睛: '黑棋如何做出眼睛',
  黑棋如何製造打劫: '黑棋如何製造打劫',
  黑棋如何下成雙活: '黑棋如何下成雙活',
  黑棋如何破掉白棋的眼: '黑棋如何破掉白棋的眼',
  黑棋如何用征子吃掉白棋: '黑棋如何用征子吃掉白棋',
  黑棋如何利用打劫做活: '黑棋如何利用打劫做活',
  黑棋如何利用打劫殺掉白棋: '黑棋如何利用打劫殺掉白棋',
  黑棋如何反提吃掉白棋: '黑棋如何反提吃掉白棋',
  如何把黑棋連起來: '如何把黑棋連起來',
  黑棋如何反提吃白: '黑棋如何反提吃白',
  黑先吃白: '黑先吃白',
  黑先殺白: '黑先殺白',
  黑棋如何逃跑: '黑棋如何逃跑',
  打劫殺白: '打劫殺白',
  打劫做活: '打劫做活',
  打劫活: '打劫活',
  黑先做活: '黑先做活',
  黑棋如何雙叫吃白棋: '黑棋如何雙叫吃白棋',
  黑先如何切斷白棋: '黑先如何切斷白棋',
  黑先如何連接: '黑先如何連接',
  題正確: '題正確',
  題錯誤: '題錯誤',
  '確定要離開？': '確定要離開？',
  題目列表: '題目列表',
  正確: '正確',
  錯誤: '錯誤',
  上一題: '上一題',
  下一題: '下一題',
  離開將會自動結算: '離開將會自動結算',
  點選下列網址或掃描QRCode: '點選下列網址或掃描QRCode',
  '加入［黑嘉嘉圍棋教室］LINE 好友': '加入［黑嘉嘉圍棋教室］LINE 好友',
  '加入LINE@': '加入LINE@',
  '{rank}級棋力檢定證書': '{rank}級棋力檢定證書',
  '{text}棋力檢定證書': '{text}棋力檢定證書',
  參加棋力檢定測驗已達標準: '參加棋力檢定測驗已達標準',
  '特頒鑑定證書，以資證明': '特頒鑑定證書，以資證明',
  目前沒有棋力證書: '目前沒有棋力證書',
  棋力檢定通過後證書將會顯示在這: '棋力檢定通過後證書將會顯示在這',
  '成為正式會員 , 棋力 {rank} 級': '成為正式會員 , 棋力 {rank} 級',
  '通過棋力檢定 , 棋力上升至 {rank} 級': '通過棋力檢定 , 棋力上升至 {rank} 級',
  '發生未知錯誤，再試一次': '發生未知錯誤，再試一次',
  手: '手',
  路: '路',
  日期: '日期',
  棋譜資訊: '棋譜資訊',
  黑方: '黑方',
  白方: '白方',
  結果: '結果',
  知道了: '知道了',
  之後可以在歷史成績單查看: '之後可以在歷史成績單查看',
  證書儲存成功: '證書儲存成功',
  證書儲存失敗: '證書儲存失敗',
  圖片下載發生錯誤: '圖片下載發生錯誤',
  再按一次離開應用程式: '再按一次離開應用程式',
  請輸入暱稱: '請輸入暱稱',
  暱稱: '暱稱',
  註冊: '註冊',
  '已經有帳號？前往': '已經有帳號？前往',
  請輸入手機號碼: '請輸入手機號碼',
  請輸入票券序號: '請輸入票券序號',
  '*手機號碼必須為數字': '*手機號碼必須為數字',
  '*此帳號已註冊過': '*此帳號已註冊過',
  '*請輸入驗證碼': '*請輸入驗證碼',
  '*驗證碼錯誤': '*驗證碼錯誤',
  '*請輸入暱稱': '*請輸入暱稱',
  '*請輸入手機號碼': '*請輸入手機號碼',
  '*請輸入序號': '*請輸入序號',
  '*此序號不存在': '*此序號不存在',
  '*此序號已退費': '*此序號已退費',
  '*請輸入正確的手機號碼': '*請輸入正確的手機號碼',
  '*手機號碼已被使用': '*手機號碼已被使用',
  '*序號已被使用': '*序號已被使用',
  '*序號已失效': '*序號已失效',
  '*操作過於頻繁，請稍後再嘗試': '*操作過於頻繁，請稍後再嘗試',
  '*驗證碼已逾期': '*驗證碼已逾期',
  輸入驗證碼: '輸入驗證碼',
  傳送驗證碼: '傳送驗證碼',
  再一次傳送: '再一次傳送',
  序號: '序號',
  '沒有序號嗎？前往': '沒有序號嗎？前往',
  獲得序號: '獲得序號',
  忘記密碼: '忘記密碼',
  送出: '送出',
  '系統將為你登出帳號，': '系統將為你登出帳號，',
  '確定要返回上一頁嗎？': '確定要返回上一頁嗎？',
  成功啟用序號: '成功啟用序號',
  重設密碼: '重設密碼',
  註冊成功: '註冊成功',
  總分: '總分',
  更改成功: '更改成功',
  服務條款和隱私政策: '服務條款和隱私政策',
  '*手機號碼尚未註冊': '*手機號碼尚未註冊',
  '*請再次輸入密碼': '*請再次輸入密碼',
  '*兩次密碼輸入不同': '*兩次密碼輸入不同',
  '我在［黑嘉嘉圍棋教室］等你一決高下～':
    '我在［黑嘉嘉圍棋教室］等你一決高下～',
  '你有新的對弈邀請！': '你有新的對弈邀請！',
  勝利: '勝利',
  失敗: '失敗',
  很熟練喔: '很熟練喔',
  要再加強: '要再加強',
  請再次輸入密碼: '請再次輸入密碼',
  解說影片: '解說影片',
  歡迎來到: '歡迎來到',
  時限: '時限',
  讀秒: '讀秒',
  讀秒秒數: '讀秒秒數',
  讀秒次數: '讀秒次數',
  讓子: '讓子',
  手合: '手合',
  '段差一級讓一子，有讓子上限': '段差一級讓一子，有讓子上限',
  '{v1}分鐘': '{v1}分鐘',
  '{v1}秒': '{v1}秒',
  '{v1}次': '{v1}次',
  '{v1}路  - {v2} 分鐘 - {v3}秒{v4}次': '{v1}路  - {v2} 分鐘 - {v3}秒{v4}次',
  不讓子: '不讓子',
  建立對局: '建立對局',
  邀請好友下棋: '邀請好友下棋',
  點擊分享連結按鈕邀請好友一起對弈: '點擊分享連結按鈕邀請好友一起對弈',
  對局條件: '對局條件',
  棋盤: '棋盤',
  '{v1}路': '{v1}路',
  有: '有',
  無: '無',
  離開: '離開',
  分享連結: '分享連結',
  '確定要離開對局？': '確定要離開對局？',
  同意: '同意',
  拒絕: '拒絕',
  對方想要申請算輸贏: '對方想要申請算輸贏',
  '是否同意？': '是否同意？',
  正在等待對方回應: '正在等待對方回應',
  '對方拒絕算輸贏，請繼續對局': '對方拒絕算輸贏，請繼續對局',
  '還沒下完，還不能算輸贏哦！': '還沒下完，還不能算輸贏哦！',
  有一方不同意輸贏結果: '有一方不同意輸贏結果',
  對方婉拒你的邀請: '對方婉拒你的邀請',
  '對方網路不穩定，請耐心等候': '對方網路不穩定，請耐心等候',
  '落子異常，刷新棋盤資訊中...': '落子異常，刷新棋盤資訊中...',
  '對方斷線，不能算輸贏': '對方斷線，不能算輸贏',
  請確認網路連線的狀態後再次嘗試: '請確認網路連線的狀態後再次嘗試',
  '對局仍在進行中，確定要離開嗎？': '對局仍在進行中，確定要離開嗎？',
  '發言時間過近，請{v1}秒後再發言': '發言時間過近，請{v1}秒後再發言',
  只有落子方可以申請算輸贏: '只有落子方可以申請算輸贏',
  請先完成目前的操作: '請先完成目前的操作',
  '黑{blackScore}，黑收後': '黑{blackScore}，黑收後',
  '黑{blackScore}，白收後': '黑{blackScore}，白收後',
  '( 你已確認結果，對方仍在確認中 )': '( 你已確認結果，對方仍在確認中 )',
  對方已婉拒或離開房間: '對方已婉拒或離開房間',
  等待好友: '等待好友',
  房間不存在: '房間不存在',
  此房間已關閉: '此房間已關閉',
  複製成功: '複製成功',
  秒: '秒',
  次: '次',
  無法加入自己創建的房間: '無法加入自己創建的房間',
  繼續練習: '繼續練習',
  天: '天',
  '即將開課，敬請期待': '即將開課，敬請期待',
  '（黑{blackScore}子）': '（黑{blackScore}子）',
  '非預期的錯誤發生，請重新整理': '非預期的錯誤發生，請重新整理',
  '棋局還沒結束哦！': '棋局還沒結束哦！',
  '此頁面無法重複開啟，按確定後自動跳至首頁':
    '此頁面無法重複開啟，按確定後自動跳至首頁',
  已有其他使用者登入: '已有其他使用者登入',
  登入其他帳號: '登入其他帳號',
  將他人登出: '將他人登出',
  已有其他使用者登入本帳號: '已有其他使用者登入本帳號',
  '有新版本，請至商店下載更新': '有新版本，請至商店下載更新',
  請記得於時間內完成: '請記得於時間內完成',
  逾期將視為放棄: '逾期將視為放棄',
  '共有 {totalKifus} 個棋譜，': '共有 {totalKifus} 個棋譜，',
  代表有覆盤: '代表有覆盤',
  '問題手，': '問題手，',
  查看變化圖: '查看變化圖',
  '好棋！': '好棋！',
  '下得真好～': '下得真好～',
  仍有進行中的對局: '仍有進行中的對局',
  離開將自動判輸: '離開將自動判輸',
  '確定要離開嗎？': '確定要離開嗎？',
  對方想要再一局: '對方想要再一局',
  '國家/地區': '國家/地區',
  台灣: '台灣',
  香港: '香港',
  馬來西亞: '馬來西亞',
  新加坡: '新加坡',
  作答結束: '作答結束',
  請先確認算輸贏結果: '請先確認算輸贏結果',
  我的學習進度: '我的學習進度',
  數據紀錄: '數據紀錄',
  '目前版本：{version}': '目前版本：{version}',
  修改密碼: '修改密碼',
  系統設定: '系統設定',
  我的棋譜: '我的棋譜',
  目前沒有網路: '目前沒有網路',
  無法使用耶: '無法使用耶',
  '完課了還是可以自己練習喔！': '完課了還是可以自己練習喔！',
  課程影片也可以無限次重複觀看: '課程影片也可以無限次重複觀看',
  手數: '手數',
  '{winCount}勝': '{winCount}勝',
  '{loseCount}敗': '{loseCount}敗',
  '勝率{winRate}%': '勝率{winRate}%',
  '累計{winCount}題': '累計{winCount}題',
  '正確率{winRate}%': '正確率{winRate}%',
  開始上課: '開始上課',
  棋聚一堂: '棋聚一堂',
  一起學棋: '一起學棋',
  一起下棋: '一起下棋',
  切換課程: '切換課程',
  這邊可以切換新的課程喔: '這邊可以切換新的課程喔',
  查看完課特輯: '查看完課特輯',
  學習進度: '學習進度',
  你還沒有任何課程: '你還沒有任何課程',
  前往官網了解: '前往官網了解',
  限時倒數: '限時倒數',
  勝利條件: '勝利條件',
  '對方 Pass': '對方 Pass',
  '對方 Pass，建議點擊［算輸贏］按鈕結束棋局':
    '對方 Pass，建議點擊［算輸贏］按鈕結束棋局',
  黑: '黑',
  子: '子',
  認輸: '認輸',
  手數顯示: '手數顯示',
  確定落子: '確定落子',
  直播: '直播',
  你與黑嘉嘉的專屬課程: '你與黑嘉嘉的專屬課程',
  課程影片: '課程影片',
  已切換課程: '已切換課程',
  視頻解說: '視頻解說',
  查看解答: '查看解答',
  目前沒有直播: '目前沒有直播',
  直播開放後將會顯示在這: '直播開放後將會顯示在這',
  房間: '房間',
  進入直播: '進入直播',
  已結束: '已結束',
  未開始: '未開始',
  觀看回放: '觀看回放',
  第一手: '第一手',
  最終手: '最終手',
  上十手: '上十手',
  下十手: '下十手',
  上一手: '上一手',
  下一手: '下一手',
  問題手: '問題手',
  離開變化圖: '離開變化圖',
  兒童啟蒙: '兒童啟蒙',
  手數隱藏: '手數隱藏',
  請將裝置: '請將裝置',
  橫向: '橫向',
  使用: '使用',
  保存更改: '保存更改',
  查看: '查看',
  個人資訊更改成功: '個人資訊更改成功',
  個人資訊更改失敗: '個人資訊更改失敗',
  編輯個人資訊: '編輯個人資訊',
  我的成績單: '我的成績單',
  你有新的成績: '你有新的成績',
  查看成績單: '查看成績單',
  在: '在',
  立即: '立即',
  成績將於5分鐘後公布: '成績將於5分鐘後公布',
  '本次{rank}棋力檢定：未通過': '本次{rank}棋力檢定：未通過',
  維修中: '維修中',
  尚未開課: '尚未開課',
  當前課程不開放檢定: '當前課程不開放檢定',
  尋找對手中: '尋找對手中',
  快速配對: '快速配對',
  和朋友下棋: '和朋友下棋',
  '仍有進行中的對局，無法配對': '仍有進行中的對局，無法配對',
  找不到對手: '找不到對手',
  配對已取消: '配對已取消',
  創建對局: '創建對局',
  建立: '建立',
  '{v1}路  - {v2} 分鐘': '{v1}路  - {v2} 分鐘',
  '{v3}秒{v4}次': '{v3}秒{v4}次',
  邀請再一局: '邀請再一局',
  複製連結: '複製連結',
  發表情: '發表情',
  '第 {newDayText} 堂': '第 {newDayText} 堂',
  開放檢定: '開放檢定',
  提示: '提示',
  點擊: '點擊',
  按鈕可切換購買的新課程喔: '按鈕可切換購買的新課程喔',
  '課程－做題': '課程－做題',
  '檢定－做題': '檢定－做題',
  覆盤: '覆盤',
  我要覆盤: '我要覆盤',
  覆盤中: '覆盤中',
  已覆盤: '已覆盤',
  此局手數未達20無法進行覆盤: '此局手數未達20無法進行覆盤',
  '課程 - 下棋': '課程 - 下棋',
  '這手棋下得真好！': '這手棋下得真好！',
  '這是個很棒的【征子】，對方一定逃不走！':
    '這是個很棒的【征子】，對方一定逃不走！',
  '這是漂亮的【雙叫吃】，一定可以吃到其中一邊！':
    '這是漂亮的【雙叫吃】，一定可以吃到其中一邊！',
  '這是厲害的【抱吃】！對方死定了！': '這是厲害的【抱吃】！對方死定了！',
  '這手【叫吃】的方向很正確': '這手【叫吃】的方向很正確',
  '這是厲害的【門封】，對方逃不走了！': '這是厲害的【門封】，對方逃不走了！',
  '這是厲害的【接不歸】，對方想救也救不回去！':
    '這是厲害的【接不歸】，對方想救也救不回去！',
  '這手【撲吃】下得很好，撲完再把對手吃回來！':
    '這手【撲吃】下得很好，撲完再把對手吃回來！',
  '這手棋是漂亮的【長氣】，氣比對方多，攻殺就會贏！':
    '這手棋是漂亮的【長氣】，氣比對方多，攻殺就會贏！',
  '漂亮的【逃走】，差點被對方吃掉！': '漂亮的【逃走】，差點被對方吃掉！',
  '這是【要子】，非吃不可！': '這是【要子】，非吃不可！',
  '這手【連接】非常好，棋子變強了': '這手【連接】非常好，棋子變強了',
  '這是個很棒的【切斷】，對手慘了！': '這是個很棒的【切斷】，對手慘了！',
  '這是個很棒的【雙】，連接得非常好！': '這是個很棒的【雙】，連接得非常好！',
  '這是個很棒的【刺】，瞄準對方的斷點！':
    '這是個很棒的【刺】，瞄準對方的斷點！',
  '這個【長】很棒': '這個【長】很棒',
  '這個【撞】很棒': '這個【撞】很棒',
  '這個【彎】很棒': '這個【彎】很棒',
  '這個【並】很棒': '這個【並】很棒',
  '這個【立】很棒': '這個【立】很棒',
  '這個【衝】很棒': '這個【衝】很棒',
  '這是個很棒的【擋】，這樣對手就過不來了！':
    '這是個很棒的【擋】，這樣對手就過不來了！',
  '這個【扳】很棒': '這個【扳】很棒',
  '這個【尖】很棒': '這個【尖】很棒',
  '這個【虎】很棒': '這個【虎】很棒',
  '這個【跳】很棒': '這個【跳】很棒',
  '這個【飛】很棒': '這個【飛】很棒',
  '這個【定石】選得真好！': '這個【定石】選得真好！',
  '【切斷】這裡比較厲害！': '【切斷】這裡比較厲害！',
  '這個【連接】很重要唷': '這個【連接】很重要唷',
  '下在這裡會更厲害喔！': '下在這裡會更厲害喔！',
  '這是【虎口】，會被吃掉！': '這是【虎口】，會被吃掉！',
  '這裡有個【雙叫吃】！一定可以吃到其中一邊！':
    '這裡有個【雙叫吃】！一定可以吃到其中一邊！',
  '下這裡就可以【征子】對方唷！': '下這裡就可以【征子】對方唷！',
  '應該下【門封】！把對方直接關起來！': '應該下【門封】！把對方直接關起來！',
  '這裡可以使用【抱吃】！對方死定了！': '這裡可以使用【抱吃】！對方死定了！',
  '這樣【叫吃】方向才正確': '這樣【叫吃】方向才正確',
  '這裡有個【接不歸】，對方救不走的！': '這裡有個【接不歸】，對方救不走的！',
  '這裡可以使用【撲吃】！對方吃掉，我們再吃回來！':
    '這裡可以使用【撲吃】！對方吃掉，我們再吃回來！',
  '這裡剩一氣！快逃跑！': '這裡剩一氣！快逃跑！',
  '這個要立刻吃掉，別讓對方逃走！': '這個要立刻吃掉，別讓對方逃走！',
  '這裡不要救，再救也沒用！': '這裡不要救，再救也沒用！',
  '這個【叫吃】是吃不到對方的，不要下比較好唷！':
    '這個【叫吃】是吃不到對方的，不要下比較好唷！',
  '這裡不能下，下這裡是送對手吃唷！': '這裡不能下，下這裡是送對手吃唷！',
  '要先下這裡把氣變多，才能繼續戰鬥！': '要先下這裡把氣變多，才能繼續戰鬥！',
  '這裡要快點【連接】，被切斷的話會危險的！':
    '這裡要快點【連接】，被切斷的話會危險的！',
  '下這裡可以【切斷】對方，再看要攻擊哪一塊？':
    '下這裡可以【切斷】對方，再看要攻擊哪一塊？',
  下這裡可以一邊逃走一邊連接: '下這裡可以一邊逃走一邊連接',
  '這個【定石】這樣下不太適合，下在這比較好':
    '這個【定石】這樣下不太適合，下在這比較好',
  '現在這裡已經不重要了，應該【手拔】，下這裡更好！！':
    '現在這裡已經不重要了，應該【手拔】，下這裡更好！！',
  '現在應該【手拔】，下這個要點！': '現在應該【手拔】，下這個要點！',
  '這裡下【長】會更好喔！': '這裡下【長】會更好喔！',
  '這裡下【彎】會更好喔！': '這裡下【彎】會更好喔！',
  '這裡下【並】會更好喔！': '這裡下【並】會更好喔！',
  '這裡下【立】會更好喔！': '這裡下【立】會更好喔！',
  '這裡下【擠】會更好喔！': '這裡下【擠】會更好喔！',
  '這裡下【撞】會更好喔！': '這裡下【撞】會更好喔！',
  '這裡要用這種方式【叫吃】才厲害喔！': '這裡要用這種方式【叫吃】才厲害喔！',
  '這裡下【沖】會更好喔！': '這裡下【沖】會更好喔！',
  '這裡下【擋】會更好喔！': '這裡下【擋】會更好喔！',
  '這裡下【扳】會更好喔！': '這裡下【扳】會更好喔！',
  '這裡下【尖】會更好喔！': '這裡下【尖】會更好喔！',
  '這裡下【虎】會更好喔！': '這裡下【虎】會更好喔！',
  '這裡下【跳】會更好喔！': '這裡下【跳】會更好喔！',
  '這裡下【飛】會更好喔！': '這裡下【飛】會更好喔！',
  '這裡下【刺】會更好喔！': '這裡下【刺】會更好喔！',
  '這裡下【雙】來連接會更好喔！': '這裡下【雙】來連接會更好喔！',
  '現在應該【做眼】，來做活這塊棋！': '現在應該【做眼】，來做活這塊棋！',
  '現在應該【破眼】，來攻擊這塊棋！': '現在應該【破眼】，來攻擊這塊棋！',
  '這個【連接】很重要唷～': '這個【連接】很重要唷～',
  好棋: '好棋',
  AI對弈: 'AI對弈',
  好友對戰: '好友對戰',
  直播講堂: '直播講堂',
  找朋友: '找朋友',
  對AI: '對AI',
  如何使用平台: '如何使用平台',
  '通關 AI 對弈 Lv.{aiLevel}': '通關 AI 對弈 Lv.{aiLevel}',
  '學習進度星星達 {count} 顆': '學習進度星星達 {count} 顆',
  下列條件滿足後開放檢定: '下列條件滿足後開放檢定',
  '【檢定】改版說明': '【檢定】改版說明',
  '【直播講堂】規則說明': '【直播講堂】規則說明',
  '【AI 對弈】改版說明': '【AI 對弈】改版說明',
  獲勝即解鎖下一關: '獲勝即解鎖下一關',
  連贏: '連贏',
  '次解鎖下一關(': '次解鎖下一關(',
  '達成 ': '達成 ',
  ' 檢定「通關 AI 對弈 Lv.': ' 檢定「通關 AI 對弈 Lv.',
  '」條件': '」條件',
  落點提示: '落點提示',
  '【落點提示】使用說明': '【落點提示】使用說明',
  '在對局中使用【落點提示】，AI 將提供 3 個落點，其中一點會是最佳落點。落點提示功能每一局只能使用 10 次。':
    '在對局中使用【落點提示】，AI 將提供 3 個落點，其中一點會是最佳落點。落點提示功能每一局只能使用 10 次。',
  查看正解: '查看正解',
  正解: '正解',
  回到問題手: '回到問題手',
  '切換{text}': '切換{text}',
  切換程度: '切換程度',
  切換類型: '切換類型',
  切換老師: '切換老師',
  切換時間: '切換時間',
  今天: '今天',
  明天: '明天',
  本週: '本週',
  下週: '下週',
  分類: '分類',
  老師: '老師',
  時間: '時間',
  '已結束（可播放的課程）': '已結束（可播放的課程）',
  全部: '全部',
  篩選條件: '篩選條件',
  研修: '研修',
  段位班: '段位班',
  '研修班（含進階班）': '研修班（含進階班）',
  於: '於',
  下架: '下架',
  程度: '程度',
  類型: '類型',
  您目前篩選的條件沒有資料: '您目前篩選的條件沒有資料',
  設定提醒失敗: '設定提醒失敗',
  '直播講堂到期日還有 {day} 天，請點此前往續費':
    '直播講堂到期日還有 {day} 天，請點此前往續費',
  您的直播講堂已到期: '您的直播講堂已到期',
  播放: '播放',
  此直播無法回放: '此直播無法回放',
  不分齡: '不分齡',
  兒童: '兒童',
  變化圖運算中: '變化圖運算中',
  變化圖: '變化圖',
  規則說明: '規則說明',
  直播講堂列表: '直播講堂列表',
  '對局已結束，可以算輸贏囉！': '對局已結束，可以算輸贏囉！',
  '檢定－下棋': '檢定－下棋',
  邀請對局: '邀請對局',
  '在黑嘉嘉圍棋教室等你一決高下～': '在黑嘉嘉圍棋教室等你一決高下～',
  無讓子: '無讓子',
  直播時數: '直播時數',
  歷史紀錄: '歷史紀錄',
  直播講堂到期日: '直播講堂到期日',
  直播講堂規則說明: '直播講堂規則說明',
  '到期：直播講堂到期後，將無法觀看任何內容。':
    '到期：直播講堂到期後，將無法觀看任何內容。',
  '計算方式：以每月 30 天，不分大小月的方式來計算。如有購買多堂課程或續購直播講堂方案，時數將自動累加延長。':
    '計算方式：以每月 30 天，不分大小月的方式來計算。如有購買多堂課程或續購直播講堂方案，時數將自動累加延長。',
  'LIVE結束：每個直播講堂結束後將保留 90 天。若錯過喜歡的講堂，可以在直播列表的篩選器中，選擇時間的【已結束（可播放的課程）】分類，找到該講堂點擊【播放】觀看喔！':
    'LIVE結束：每個直播講堂結束後將保留 90 天。若錯過喜歡的講堂，可以在直播列表的篩選器中，選擇時間的【已結束（可播放的課程）】分類，找到該講堂點擊【播放】觀看喔！',
  個月: '個月',
  贈送: '贈送',
  '必修直播講堂（購買兒童啟蒙班）': '必修直播講堂（購買兒童啟蒙班）',
  '您還沒有購買任何課程，快來加入我們一起快樂學圍棋！':
    '您還沒有購買任何課程，快來加入我們一起快樂學圍棋！',
  您可以前往官網購買課程: '您可以前往官網購買課程',
  '需購買課程才能開放{type}': '需購買課程才能開放{type}',
  '當前課程不開放{type}': '當前課程不開放{type}',
  您可以切換其他課程或前往官網購買: '您可以切換其他課程或前往官網購買',
  推播通知: '推播通知',
  编辑: '编辑',
  刪除帳號: '刪除帳號',
  開始試下: '開始試下',
  結束試下: '結束試下',
  '這盤棋沒有問題手喔！': '這盤棋沒有問題手喔！',
  功能: '功能',
  來源: '来源',
  這邊可以查看推播通知喔: '這邊可以查看推播通知喔',
  已經滑到底了: '已經滑到底了',
  目前沒有推播通知: '目前沒有推播通知',
  取得推播通知錯誤: '取得推播通知錯誤',
  '已幫您切換至{name}課程': '已幫您切換至{name}課程',
  前往官網購買時數: '前往官網購買時數',
  購買直播講堂: '購買直播講堂',
  購買: '購買',
  '【推播通知】功能說明': '【推播通知】功能說明',
  '貼心小提醒！': '貼心小提醒！',
  'iOS 設定方式': 'iOS 設定方式',
  安卓設定方式: '安卓設定方式',
  課後做題: '課後做題',
  '加入LINE@聯繫客服': '加入LINE@聯繫客服',
  '聯繫［黑嘉嘉圍棋教室］LINE 客服刪除帳號':
    '聯繫［黑嘉嘉圍棋教室］LINE 客服刪除帳號',
  '試下（限教學）': '試下（限教學）',
  登入失敗: '登入失敗',
  學習歷程: '學習歷程',
  今天休息: '今天休息',
  明天再加油: '明天再加油',
  該名稱不得使用: '該名稱不得使用',
  棋力: '棋力',
  立即前往: '立即前往',
  追求更棒的自己: '追求更棒的自己',
  當前形勢: '當前形勢',
  項目: '項目 ',
  數據記錄: '數據記錄',
  棋盤座標: '棋盤座標',
  圍棋紀錄: '圍棋紀錄',
  所有功能: '所有功能',
  '嘉嘉小提醒：': '嘉嘉小提醒：',
  '- 最近一筆': '- 最近一筆',
  目前沒有紀錄: '目前沒有紀錄',
  電子信箱: '電子信箱',
  請輸入電子信箱: '請輸入電子信箱',
  '按下註冊後請至信箱收信，完成信箱驗證後即可登入！':
    '按下註冊後請至信箱收信，完成信箱驗證後即可登入！',
  '設定 8-15 位英數密碼': '設定 8-15 位英數密碼',
  已送出: '已送出',
  '請至信箱收信，完成信箱驗證後即可登入！':
    '請至信箱收信，完成信箱驗證後即可登入！',
  '歡迎加入黑嘉嘉圍棋教室，一起快樂學習！':
    '歡迎加入黑嘉嘉圍棋教室，一起快樂學習！',
  時效已過: '時效已過',
  驗證失敗: '驗證失敗',
  '此驗證信時效已到期，將自動轉回首頁': '此驗證信時效已到期，將自動轉回首頁',
  '自動跳轉至首頁({debounceSec})': '自動跳轉至首頁({debounceSec})',
  尚未註冊: '尚未註冊',
  序號已被使用: '序號已被使用',
  密碼錯誤: '密碼錯誤',
  '想要隨時隨地上課，也可以下載手機 APP 唷！':
    '想要隨時隨地上課，也可以下載手機 APP 唷！',
  課程專屬平台: '課程專屬平台',
  請輸入密碼: '請輸入密碼',
  手機號碼: '手機號碼',
  請輸入正確的電子信箱: '請輸入正確的電子信箱',
  '伺服器發生錯誤，請稍後再試': '伺服器發生錯誤，請稍後再試',
  '成為學員，享受對弈樂趣': '成為學員，享受對弈樂趣',
  請輸入驗證碼: '請輸入驗證碼',
  請至信箱收信: '請至信箱收信',
  兩次密碼輸入不同: '兩次密碼輸入不同',
  該使用者已註冊: '該使用者已註冊',
  自動跳轉登入頁面: '自動跳轉登入頁面',
  開始你的圍棋學習之旅: '開始你的圍棋學習之旅',
  '按下送出後請至信箱收信，點擊信中連結即可重設密碼。':
    '按下送出後請至信箱收信，點擊信中連結即可重設密碼。',
  '此驗證信時效已到期，將幫您導回登入頁。':
    '此驗證信時效已到期，將幫您導回登入頁。',
  重設密碼成功: '重設密碼成功',
  請使用新設定的密碼登入: '請使用新設定的密碼登入',
  請至信箱收信以重設密碼: '請至信箱收信以重設密碼',
  此棋院代號已綁定: '此棋院代號已綁定',
  此棋院代號不存在: '此棋院代號不存在',
  '輸入成功，您已與': '輸入成功，您已與',
  棋院綁定: '棋院綁定',
  確定要移除: '確定要移除',
  成功移除所屬棋院: '成功移除所屬棋院',
  移除: '移除',
  我所屬的棋院: '我所屬的棋院',
  尚無所屬棋院: '尚無所屬棋院',
  請輸入棋院代號: '請輸入棋院代號',
  棋院代號: '棋院代號',
  基本資料: '基本資料',
  不分齡課程: '不分齡課程',
  兒童課程: '兒童課程',
  技術專題: '技術專題',
  NFT專屬課程: 'NFT專屬課程',
  課程地圖: '課程地圖',
  更多: '更多',
  商城: '商城',
  '圍棋社群（Discord）': '圍棋社群（Discord）',
  '實體活動（Accupass）': '實體活動（Accupass）',
  官方網站: '官方網站',
  重要連結: '重要連結',
  快速連結: '快速連結',
  其他功能: '其他功能',
  '目前沒有持有 NFT': '目前沒有持有 NFT',
  優惠券: '優惠券',
  編輯棋院: '編輯棋院',
  姓名: '姓名',
  電話: '電話',
  編輯姓名: '編輯姓名',
  信箱: '信箱',
  棋院: '棋院',
  級: '級',
  段: '段',
  '預計於 11/18 揭曉 NFT 造型，': '預計於 11/18 揭曉 NFT 造型，',
  '敬請期待！': '敬請期待！',
  '查無此 NFT': '查無此 NFT',
  查看棋譜: '查看棋譜',
  屬性: '屬性',
  細節: '細節',
  下載失敗: '下載失敗',
  問題類別: '問題類別',
  請選擇類別: '請選擇類別',
  請輸入內容: '請輸入內容',
  '恭喜你完成第 {index} 課': '恭喜你完成第 {index} 課',
  課程結束: '課程結束',
  看下一課: '看下一課',
  記憶力題型: '記憶力題型',
  題目尚未設定: '題目尚未設定',
  詳細賦能及說明: '詳細賦能及說明',
  敘述: '敘述',
  '黑{number}目勝': '黑{number}目勝',
  '白{number}目勝': '白{number}目勝',
  可使用: '可使用',
  已失效: '已失效',
  前往購買: '前往購買',
  關閉: '關閉',
  前往查看: '前往查看',
  '完成綁定後，可以用該{type}登入帳號。':
    '完成綁定後，可以用該{type}登入帳號。',
  '原帳號發生無法登入等問題，可以透過綁定的{type}恢復帳號。':
    '原帳號發生無法登入等問題，可以透過綁定的{type}恢復帳號。',
  '手機號碼及信箱只會被用來寄送黑嘉嘉圍棋教室通知簡訊/郵件；我們或任何第三方公司不會透過這個聯絡資料寄送垃圾簡訊/郵件。':
    '手機號碼及信箱只會被用來寄送黑嘉嘉圍棋教室通知簡訊/郵件；我們或任何第三方公司不會透過這個聯絡資料寄送垃圾簡訊/郵件。',
  '您使用的{type}已與其他黑嘉嘉圍棋教室帳號綁定。':
    '您使用的{type}已與其他黑嘉嘉圍棋教室帳號綁定。',
  '恭喜你通過 {rank} 檢定！': '恭喜你通過 {rank} 檢定！',
  '＼棋味無窮 初段成就達成／': '＼棋味無窮 初段成就達成／',
  '真是太棒了，你已經成為小小棋士囉～想要和其他小朋友一起下棋嗎？快來看看家裡附近有哪些圍棋教室！':
    '真是太棒了，你已經成為小小棋士囉～想要和其他小朋友一起下棋嗎？快來看看家裡附近有哪些圍棋教室！',
  '繼續跟著黑咩、白咩一起，向歸一爺爺學習更多的圍棋知識吧～系統將提供專屬折扣，快去看看！':
    '繼續跟著黑咩、白咩一起，向歸一爺爺學習更多的圍棋知識吧～系統將提供專屬折扣，快去看看！',
  '你已經跨越初學者階段，準備好進入19路大棋盤了嗎～系統將提供專屬折扣，快去看看！':
    '你已經跨越初學者階段，準備好進入19路大棋盤了嗎～系統將提供專屬折扣，快去看看！',
  '持之以恆的學習，就能得到滿滿的收穫！為了鼓勵你繼續加油，系統將提供專屬折扣，快去看看！':
    '持之以恆的學習，就能得到滿滿的收穫！為了鼓勵你繼續加油，系統將提供專屬折扣，快去看看！',
  '太厲害了，你已達成重要的里程碑—通過10級檢定！系統將提供專屬折扣，快去看看！':
    '太厲害了，你已達成重要的里程碑—通過10級檢定！系統將提供專屬折扣，快去看看！',
  '此刻你已掌握19x19宇宙的多數觀念，能在每次對弈中獲得無窮的樂趣！系統將提供專屬折扣，快去看看！':
    '此刻你已掌握19x19宇宙的多數觀念，能在每次對弈中獲得無窮的樂趣！系統將提供專屬折扣，快去看看！',
  '我們又往「初段」的路上更邁進一步了！為了鼓勵你繼續加油，系統將提供專屬折扣，快去看看！':
    '我們又往「初段」的路上更邁進一步了！為了鼓勵你繼續加油，系統將提供專屬折扣，快去看看！',
  '太棒了，你已完成重大成就—通過初段檢定！系統將提供限時折扣金500元，期待與你在通往神乎棋技的道路上再次相':
    '太棒了，你已完成重大成就—通過初段檢定！系統將提供限時折扣金500元，期待與你在通往神乎棋技的道路上再次相',
  虛手: '虛手',
  對手虛手: '對手虛手',
  白: '白',
  '不分齡－全部': '不分齡－全部',
  '兒童－全部': '兒童－全部',
  列表查詢: '列表查詢',
  進行中: '進行中',
  所有直播: '所有直播',
  尚未開始的直播: '尚未開始的直播',
  '已結束的直播（重播）': '已結束的直播（重播）',
  搜尋直播講堂或老師名稱: '搜尋直播講堂或老師名稱',
  搜尋: '搜尋',
  狀態: '狀態',
  日曆查詢: '日曆查詢',
  週: '週',
  月: '月',
  年: '年',
  日: '日',
  一: '一',
  二: '二',
  三: '三',
  四: '四',
  五: '五',
  六: '六',
  沒有直播講堂: '沒有直播講堂',
  沒有直播: '沒有直播',
  試試其他篩選條件或關鍵字: '試試其他篩選條件或關鍵字',
  清除: '清除',
  最近的搜尋: '最近的搜尋',
  目前沒有直播時數: '目前沒有直播時數',
  您可以前往官網購買方案: '您可以前往官網購買方案',
  沒有搜尋到: '沒有搜尋到',
  相關的直播課程: '相關的直播課程',
  沒有找到相關直播課: '沒有找到相關直播課',
  已設通知: '已設通知',
  通知我: '通知我',
  重播: '重播',
  免費註冊: '免費註冊',
  純棋官方網站: '純棋官方網站',
  服務條款: '服務條款',
  隱私政策: '隱私政策',
  '純棋 10分鐘學會下圍棋': '純棋 10分鐘學會下圍棋',
  '不分男女老少，大家都可以一起同樂': '不分男女老少，大家都可以一起同樂',
  可免費遊玩的益智遊戲: '可免費遊玩的益智遊戲',
  '邊玩邊訓練大腦、保持智力活躍': '邊玩邊訓練大腦、保持智力活躍',
  '現在馬上開始學！': '現在馬上開始學！',
  '快速註冊 / 登入方式': '快速註冊 / 登入方式',
  訪客帳號註冊: '訪客帳號註冊',
  訪客帳號登入: '訪客帳號登入',
  '還沒有帳號嗎？': '還沒有帳號嗎？',
  '訪客 ID': '訪客 ID',
  選擇語言: '選擇語言',
  不能少於8碼: '不能少於8碼',
  '設定 8 位以上英數密碼': '設定 8 位以上英數密碼',
  請輸入ID: '請輸入ID',
  ID尚未註冊: 'ID尚未註冊',
  您的帳號已被停用: '您的帳號已被停用',
  請輸入正確的ID: '請輸入正確的ID',
  訪客註冊: '訪客註冊',
  '已經持有訪客帳號了嗎？': '已經持有訪客帳號了嗎？',
  terms: '註冊代表您同意 {0} 和 {1}。',
  '開始前我們建議您...': '開始前我們建議您...',
  下載截圖: '下載截圖',
  略過此步驟: '略過此步驟',
  '點擊下方【下載截圖】按鈕': '點擊下方【下載截圖】按鈕',
  '保存您的帳號及密碼。': '保存您的帳號及密碼。',
};

export default {
  ...lang,
  ...nftArr,
};
