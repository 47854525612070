import Video from '@/config/video';
const homeHash = '/#/hjj-ready';
const state = {
  originUrl: '',
  isVisible: false,
  hash: homeHash,
  params: '',
  videoOrientation: null,
};

const getters = {
  url(state) {
    return state.originUrl + state.hash + state.params;
  },
  isHomePage(state) {
    return state.hash === homeHash;
  },
  isVisible(state) {
    return state.isVisible;
  },
};

const mutations = {
  setUrlData(state, {aiCourseId, dailyNumber, courseUniqueKey, videoId}) {
    if (aiCourseId && dailyNumber != null && courseUniqueKey) {
      state.hash = `/#/ai-course/${aiCourseId}/course-uniqueKey/${courseUniqueKey}/day/${dailyNumber}`;
      if (Video.portraitVideoIds.includes(videoId)) {
        state.videoOrientation = 'portrait';
      }
    }
    if (videoId != null) {
      state.params = `?videoId=${videoId}`;
    }
    window.location.href = state.originUrl + state.hash + state.params;
  },
  setUrlToHomePage(state) {
    state.hash = homeHash;
    state.params = '';
  },
  setIsVisible(state, value) {
    state.isVisible = value;
  },
  setVideoOrientation(state, value) {
    state.videoOrientation = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions: {},
  mutations,
};
