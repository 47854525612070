import * as capacitorApp from '@capacitor/app';
import {Device} from '@capacitor/device';

const eventNameEnum = {
  APP_STATE_CHANGE: 'appStateChange',
  APP_URL_OPEN: 'appUrlOpen',
};

class AppEvent {
  constructor() {
    this.isInit = false;
    this.eventBus = {};
    this.eventNameEnum = eventNameEnum;
    this.deviceInfo = null;
    this.init();
    this.getDeviceInfo();
    this.appStateActive = true;
  }

  init() {
    this.isInit = true;
    for (const eventName in this.eventNameEnum) {
      const event = this.eventNameEnum[eventName];
      this.eventBus[event] = [];
      capacitorApp.App.addListener(event, async (doc) => {
        const deviceInfo = await this.getDeviceInfo();
        if (deviceInfo.platform !== 'web') {
          this.eventBus[event].forEach((cb) => {
            cb(doc);
          });
        }
      });
    }
  }

  async getDeviceInfo() {
    if (this.deviceInfo) {
      return this.deviceInfo;
    } else {
      this.deviceInfo = await Device.getInfo();
      return this.deviceInfo;
    }
  }
  on(eventName, cb) {
    this.eventBus[eventName].push(cb);
  }
  removeEvent(eventName, cb) {
    const i = this.eventBus[eventName].indexOf(cb);
    if (i > -1) {
      this.eventBus[eventName].splice(i, 1);
    }
  }
  clearEvent(event) {
    this.eventBus[event] = [];
  }
  clearEvents(events) {
    events.forEach((event) => {
      this.clearEvent(event);
    });
  }
  setAppStateActive(appStateActive) {
    this.appStateActive = appStateActive;
  }
  getAppStateActive() {
    return this.appStateActive;
  }
}

export default new AppEvent();
