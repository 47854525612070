<template>
  <b-modal v-model="modalShow" title="System Info">
    <p>Version : {{ gitTag }}</p>
    <p>Build Time: {{ buildTime }}</p>
    <p>Client Version: {{ clientVersion }}</p>
    <p>Git Hash : {{ gitHash }}</p>
    <p>Stage: {{ stage }}</p>
    <p>AB Version: {{ abVersion }}</p>
    <p>View Mode: {{ viewMode }}</p>
    <p>Device: {{ device }}</p>
    <p>Orientation: {{ orientation }}</p>
    <p>Height: {{ height }}</p>
    <p>Width: {{ width }}</p>
  </b-modal>
</template>

<script>
import Egg from '@/lib/base/egg';
import {upperCase} from 'lodash';
import {stage, clientVersion, abVersion} from '@/constant/env';

export default {
  data() {
    return {
      modalShow: false,
      buildTime: process.env.VUE_APP_BUILD_TIME,
      stage,
      abVersion: upperCase(abVersion),
      gitHash: process.env.VUE_APP_GIT_HASH,
      gitTag: process.env.VUE_APP_GIT_TAG,
      clientVersion: upperCase(clientVersion),
    };
  },
  computed: {
    viewMode() {
      return this.$store.state.env.viewMode;
    },
    device() {
      return this.$store.state.env.device;
    },
    orientation() {
      return this.$store.state.env.orientation;
    },
    height() {
      return this.$store.state.env.height;
    },
    width() {
      return this.$store.state.env.width;
    },
  },
  mounted() {
    const egg = new Egg();
    egg
      .addCode('up,up,down,down,left,left,right,right', () => {
        this.modalShow = true;
      })
      .listen();
  },
};
</script>
