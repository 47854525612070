module.exports = {
  'stage': 'prod',
  'clientVersion': 'hjj-jungo',
  'locale': 'ja-JP',
  'regionCode': 'JP',
  'infraRegion': 'JAPAN',
  'manifest': { 'name': '純碁', 'web': 'http://jungo.go-en.com/' },
  'googleAnalyticsId': '',
  'academyId': {
    'beta': '87ea6df3-c592-479e-8a16-427cdaa3a18d',
    'prod': 'c8c2bd9d-17a9-4dcd-b0d0-d399ec39c6cd',
  },
  'aiGame': { 'victoryCondition': { '9': 43, '13': 87, '19': 185 } },
  'appConfigs': { 'ios': {}, 'android': {} },
  'googleClientId': '279484342506-u8fv7b64irbtbfn1ie4crr3l3iaou8rb.apps.googleusercontent.com',
  'appleClientId': 'com.hjjJungo.login',
  'lineClientId': '1657397686',
  'loginMethods': [ 'username', 'google', 'apple' ],
  'avatarsNumber': 11,
};