<template>
  <div
    class="wrap-pass-animation"
    :class="[
      {
        leave: leave,
        'long-device': deviceRatio >= 1.8,
        'short-device': deviceRatio < 1.8,
      },
      {
        'jungo-wrap-pass-animation': $isJungo,
      },
    ]"
  >
    <b-row
      ref="userCardAnimation"
      class="d-flex flex-column justify-content-center align-items-center h-100 position-relative wrap-user"
      :class="{leave: leave}"
    >
      <div v-show="mode === 'game'" class="p-0 card-user">
        <b-card
          bg-variant="blackChess"
          text-variant="white"
          border-variant="blackChess"
          class="position-relative"
        >
          <div class="d-flex flex-nowrap align-items-center">
            <b-img
              class="avatar ml-4 mt-4 mb-4 mr-4"
              rounded="circle"
              width="80"
              height="80"
              :srcset="blackPlayerAvatar"
              alt="avatar"
            ></b-img>
            <p class="user-details w-100 m-0 text-20 font-weight-bold">
              {{
                blackPlayer.nickName +
                ' - ' +
                (blackPlayer.level
                  ? 'Lv.' + blackPlayer.level
                  : blackPlayer.rankLabel)
              }}
            </p>
          </div>
        </b-card>
      </div>
      <b-img
        v-show="mode === 'game'"
        class="text-vs"
        width="68"
        height="38"
        :srcset="require('@/assets/img/practice/vs.png?srcset')"
        alt="vs"
      ></b-img>
      <b-img
        v-show="mode === 'tsume'"
        class="text-start"
        width="194"
        height="82"
        :srcset="require('@/assets/img/practice/start.png?srcset')"
        alt="start"
      ></b-img>
      <div v-show="mode === 'game'" class="p-0 card-user">
        <b-card border-variant="white" class="card-shadow position-relative">
          <div class="d-flex flex-nowrap align-items-center">
            <b-img
              class="avatar ml-4 mt-4 mb-4 mr-4"
              rounded="circle"
              width="80"
              height="80"
              :srcset="whitePlayerAvatar"
              alt="avatar"
            ></b-img>
            <p class="user-details w-100 m-0 text-20 font-weight-bold">
              {{
                whitePlayer.nickName +
                ' - ' +
                (whitePlayer.level
                  ? 'Lv.' + whitePlayer.level
                  : whitePlayer.rankLabel)
              }}
            </p>
          </div>
        </b-card>
      </div>
    </b-row>
  </div>
</template>
<script>
import rankList from '@/json/rank.json';
import aiLevelMapping from '@/lib/base/aiLevel.js';
import aiCharacterMapping from '@/json/aiCharacter.json';

export default {
  name: 'PassAnimation',
  components: {},
  props: {
    leave: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      default: 'game',
    },
    black: {
      type: Object,
      default: null,
    },
    white: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rankList,
      aiCharacterMapping,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userColor() {
      return this.gameData.userColor;
    },
    blackPlayer() {
      if (this.black) {
        return {
          nickName: this.black.name || this.black.nickName,
          rankLabel: rankList[this.black.rank].label,
          avatar: this.black.avatar,
        };
      }
      if (this.userColor === 'black') {
        const userData = this.user.userData;
        userData.rankLabel = rankList[this.user.userData.rank].label;
        return userData;
      } else {
        // 防止prettier自動換行產生空白
        // prettier-ignore
        return {
          nickName: this.gameData.opponentName,
          rankLabel: aiLevelMapping.processAiLevel(this.gameData.aiLevel),
          level: this.gameData.aiId || null,
          avatar: this.gameData.createdTime && this.gameData.opponentName !== '黑小嘉'
            ? `avatar_tsumego_ai_${aiCharacterMapping[this.gameData.opponentName] || 1}`
            : 'ai_b',
        };
      }
    },
    whitePlayer() {
      if (this.white) {
        return {
          nickName: this.white.name || this.white.nickName,
          rankLabel: rankList[this.white.rank].label,
          avatar: this.white.avatar,
        };
      }
      if (this.userColor === 'white') {
        const userData = this.user.userData;
        userData.rankLabel = rankList[this.user.userData.rank].label;
        return userData;
      } else {
        // 防止prettier自動換行產生空白
        // prettier-ignore
        return {
          nickName: this.gameData.opponentName,
          rankLabel: aiLevelMapping.processAiLevel(this.gameData.aiLevel),
          level: this.gameData.aiId || null,
          avatar: this.gameData.createdTime && this.gameData.opponentName !== '白小嘉'
            ? `avatar_tsumego_ai_${aiCharacterMapping[this.gameData.opponentName] || 1}`
            : 'ai_w',
        };
      }
    },
    whitePlayerAvatar() {
      return require(`@/assets/img/personal/avatar/${this.whitePlayer.avatar}.png?srcset`);
    },
    blackPlayerAvatar() {
      return require(`@/assets/img/personal/avatar/${this.blackPlayer.avatar}.png?srcset`);
    },
    gameData() {
      return this.$store.state.aiGame;
    },
    deviceRatio() {
      const width = this.$store.state.env.width;
      const height = this.$store.state.env.height;
      return height / width;
    },
  },
  created() {
    this.$playSound('transition');
  },
  mounted() {
    this.$refs.userCardAnimation.addEventListener(
      'animationend',
      this.userCardAnimationHandler
    );
  },
  methods: {
    userCardAnimationHandler(event) {
      if (event.animationName.includes('userCardOpacity')) {
        this.$emit('animation-end');
        this.$refs.userCardAnimation.removeEventListener(
          'animationend',
          this.userCardAnimationHandler
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-pass-animation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1021;
  &.short-device::after {
    content: '';
    position: absolute;
    bottom: -75%;
    right: -146%;
    width: 200%;
    height: 200%;
    background: #ff855e;
    transform: rotate(45deg);
    animation: passAnimationXBottom 0.3s linear forwards;
  }
  &.leave.long-device::after,
  &.leave.short-device::after {
    animation: passAnimationXBottomLeave 0.3s linear 1s forwards;
  }
  &.short-device::before {
    content: '';
    position: absolute;
    top: -75%;
    left: -146%;
    width: 200%;
    height: 200%;
    background: $secondary;
    transform: rotate(45deg);
    animation: passAnimationXTop 0.3s linear forwards;
  }
  &.leave.long-device::before,
  &.leave.short-device::before {
    animation: passAnimationXTopLeave 0.3s linear 1s forwards;
  }
  /* iphoneX size */
  &.long-device::after {
    content: '';
    position: absolute;
    bottom: -75%;
    right: -133%;
    width: 200%;
    height: 200%;
    background: #ff855e;
    transform: rotate(45deg);
    animation: passAnimationXBottom 0.3s linear forwards;
  }
  &.long-device::before {
    content: '';
    position: absolute;
    top: -75%;
    left: -133%;
    width: 200%;
    height: 200%;
    background: $secondary;
    transform: rotate(45deg);
    animation: passAnimationXTop 0.3s linear forwards;
  }
  /* pad size */
  // @media screen and (min-device-aspect-ratio: 3/5) {
  //   &::after {
  //     bottom: -75%;
  //     right: -154%;
  //     animation: passAnimationYBottom 0.3s linear forwards;
  //   }
  //   &::before {
  //     top: -75%;
  //     left: -153%;
  //     animation: passAnimationYTop 0.3s linear forwards;
  //   }
  // }
  @keyframes passAnimationXBottom {
    from {
      transform: translateX(80%) rotate(45deg);
    }
    to {
      transform: translateX(0) rotate(45deg);
    }
  }
  @keyframes passAnimationXBottomLeave {
    from {
      transform: translateX(0%) rotate(45deg);
    }
    to {
      transform: translateX(80%) rotate(45deg);
    }
  }
  @keyframes passAnimationXTop {
    from {
      transform: translateX(-80%) rotate(45deg);
    }
    to {
      transform: translateX(0) rotate(45deg);
    }
  }
  @keyframes passAnimationXTopLeave {
    from {
      transform: translateX(0%) rotate(45deg);
    }
    to {
      transform: translateX(-80%) rotate(45deg);
    }
  }
  @keyframes passAnimationYBottom {
    from {
      transform: translateY(45%) rotate(45deg);
    }
    to {
      transform: translateX(0) rotate(45deg);
    }
  }
  @keyframes passAnimationYTop {
    from {
      transform: translateY(-45%) rotate(45deg);
    }
    to {
      transform: translateX(0) rotate(45deg);
    }
  }
  .wrap-user {
    z-index: 1;
    &.leave {
      animation: userCardOpacity 0.3s linear 1s forwards;
    }
    .text-vs {
      opacity: 0;
      margin: 34px 0;
      animation: vsMoveOn 0.1s ease 0.4s forwards;
    }
    .text-start {
      opacity: 0;
      transform: translateX(-20px);
      animation: startMoveOn 0.5s ease 0.5s forwards;
    }
    .card-user {
      width: 280px;
      &:first-of-type {
        transform: translateX(-100vw);
        animation: userCardLeftFadeIn 0.3s ease 0.5s forwards;
      }
      &:last-of-type {
        transform: translateX(100vw);
        animation: userCardRightFadeIn 0.3s ease 0.5s forwards;
      }
      @keyframes userCardLeftFadeIn {
        from {
          transform: translateX(-100vw);
        }
        to {
          transform: translateX(0);
        }
      }
      @keyframes userCardRightFadeIn {
        from {
          transform: translateX(100%);
        }
        to {
          transform: translateX(0);
        }
      }
      @keyframes userCardOpacity {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
      @keyframes vsMoveOn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      @keyframes startMoveOn {
        from {
          opacity: 0;
          transform: translateX(-20px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .card {
        border-radius: 40px;
        box-shadow: -30px 30px 3px 0 $grayscale-20;
        border: none;
        .user-details {
          max-width: 144px;
        }
      }
    }
  }

  &.jungo-wrap-pass-animation {
    &.short-device::before,
    &.long-device::before {
      background: $cyan-blue;
    }
    &.short-device::after,
    &.long-device::after {
      background: $font-secondary;
    }
  }
}
</style>
