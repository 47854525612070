<template>
  <div
    v-if="hasNavigation"
    class="navigation overflow-hidden"
    :class="[
      $device.isMobile || $isJungo ? 'bg-white' : 'bg-secondary',
      {'left-navigation h-100': !$device.isMobile},
      {'jungo-navigation': $isJungo},
    ]"
  >
    <div
      v-if="!$device.isMobile"
      class="logo-container cursor-pointer w-100"
      @click="back2home"
    >
      <img class="img-fluid" src="@/assets/img/login/base-logo-white.png" />
    </div>
    <b-nav
      class="navigation-container align-items-center overflow-auto scroll-bar"
      justified
    >
      <b-nav-item
        v-for="tab of tabs"
        :key="tab.key"
        class="cursor-pointer position-relative"
        :active="isActive(tab)"
        :class="[$device.isMobile ? 'text-12' : 'text-14']"
        @click="tabEvent(tab)"
      >
        <i class="text-32" :class="iconClass(tab)"></i>
        <p class="m-0">
          {{ tab.title }}
        </p>
        <div
          v-if="tab.key === 'more' && hasVerificationNotification"
          class="notification bg-accent position-absolute"
        ></div>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
/* eslint-disable indent */
export default {
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    notifiable() {
      return (
        this.verificationData.state === 'ENABLE' ||
        this.verificationData.state === 'SCORE'
      );
    },
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    hasVerificationNotification() {
      return this.$store.state.env.hasVerificationNotification;
    },
    hasNavigation() {
      if (this.$device.isMobile) {
        return this.$route.meta?.hasNavigation && !this.isMilestoneShow;
      } else {
        return this.$route.meta?.hasNavigation;
      }
    },
    isMilestoneShow() {
      return this.$store.state.verification.isMilestoneShow;
    },
    noPlans() {
      return this.$store.getters['course/noPlans'];
    },
    isCN() {
      return this.$store.getters['env/isCN'];
    },
    active() {
      return this.$route.matched[0].name;
    },
    hasUnfinishedGame() {
      return this.$store.getters['aiGame/hasUnfinishedGame'];
    },
  },
  created() {
    if (this.$device.isMobile) {
      this.tabs = [
        {
          key: 'home',
          icon: 'Home',
          title: this.$t('課程'),
          event: 'home',
        },
        {
          key: 'gamelist',
          icon: this.$isJungo ? 'Battle' : 'Go-Kifu2',
          title: this.$t('下棋'),
          event: this.$isJungo ? 'practice' : 'gamelist',
          toNoLessonPage: true,
        },
        {
          key: 'tsumelist',
          icon: 'Kifu2',
          title: this.$t('做題'),
          event: 'tsumelist',
          toNoLessonPage: true,
        },
        ...(this.isCN || this.$isJungo
          ? []
          : [
              {
                key: 'live',
                icon: 'live-stream',
                title: this.$t('直播'),
                event: 'liveList',
              },
            ]),
        {
          key: 'personal',
          icon: 'User',
          title: this.$t('個人'),
          event: 'personal',
        },
      ];
    } else {
      this.tabs = [
        {
          key: 'home',
          icon: 'Home',
          title: this.$t('課程'),
          event: 'home',
        },
        {
          key: 'game',
          icon: 'Go-Kifu2',
          title: this.$isJungo ? this.$t('AI對弈') : this.$t('下棋'),
          event: 'practiceGame',
          toNoLessonPage: true,
        },
        ...(this.$isJungo
          ? [
              {
                key: 'pvp',
                icon: 'Battle',
                title: this.$t('對戰'),
                event: 'pvp',
                toNoLessonPage: true,
              },
            ]
          : []),
        {
          key: 'tsume',
          icon: 'Kifu2',
          title: this.$t('做題'),
          event: 'practiceTsume',
          toNoLessonPage: true,
        },
        ...(this.isCN || this.$isJungo
          ? []
          : [
              {
                key: 'live',
                icon: 'live-stream',
                title: this.$t('直播'),
                event: 'liveList',
              },
            ]),
        {
          key: 'personal',
          icon: 'User',
          title: this.$t('個人'),
          event: 'personal',
        },
        ...(this.$isJungo
          ? []
          : [
              {
                key: 'more',
                icon: 'Dots-circle-horizontal',
                title: this.$t('更多'),
                event: 'more',
              },
            ]),
      ];
    }
  },
  methods: {
    iconClass(tab) {
      return `icon-${tab.icon}`;
    },
    back2home() {
      this.$router.push({name: 'home'});
    },
    async tabEvent(tab) {
      if (this.noPlans && tab.toNoLessonPage) {
        return this.$router.push({
          name: 'no-lesson',
          params: {
            type: tab.event,
          },
        });
      } else if (tab.event === 'practiceGame' || tab.event === 'gamelist') {
        this.$store.commit('env/setIsLoading', true);
        await this.getUnfinishedGame();
        if (this.hasUnfinishedGame && this.$device.isMobile) {
          return this.$router.push('/practice/game');
        } else {
          return this.$device.isMobile
            ? this.$router.push('/practice/gamelist')
            : this.$router.push({name: 'practiceGame'});
        }
      } else if (tab.event === 'practiceTsume') {
        return this.$router.push({
          name: 'practiceTsume',
          params: {rank: 'uncast', type: 'uncast'},
        });
      } else if (tab.event === 'more') {
        this.$store.commit('env/setIsSidebarShow', true);
      } else if (this.$route.name !== tab.event) {
        return this.$router.push({name: tab.event});
      }
    },
    async getUnfinishedGame() {
      await this.$store.dispatch('aiGame/getUnfinishedGame');
      this.$store.commit('env/setIsLoading', false);
    },
    isActive(tab) {
      if (this.$route.name === 'no-lesson') {
        const type = this.$route.params.type;
        return type === tab.event;
      } else if (this.$device.isMobile) {
        return this.active === tab.event;
      } else {
        return (
          this.$route.path.includes(tab.event) || this.active === tab.event
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  .logo-container {
    padding: 8px 10px;
    margin: 0 0 8px;
    img {
      width: 100px;
    }
  }
  .navigation-container {
    box-shadow: inset 0 1px 0 0 $line-2;
    min-height: 62px;
  }
  .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    min-height: 62px;
    color: rgba($primary, 0.5);
    border-radius: $rounded-md;
    &.active {
      color: $primary;
    }
  }

  &.jungo-navigation {
    p {
      color: rgba($font-normal, 0.5);
    }
    .active p {
      color: $font-normal;
    }
  }

  .notification {
    width: 14px;
    height: 14px;
    top: 10px;
    right: 9px;
    border-radius: 50%;
  }

  &.left-navigation {
    .navigation-container {
      box-shadow: none;
      flex-direction: column;
      flex-wrap: nowrap;
      max-width: 120px;
      height: calc(100% - 139px);
    }

    .nav-link {
      width: 84px;
      height: 80px;
      color: white;
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
      &.active {
        background: rgba(255, 255, 255, 0.3);
      }
    }
    .nav-item {
      margin-bottom: 8px;
      max-height: 80px;
    }

    &.jungo-navigation {
      .nav-link {
        color: $font-grayscale-1;
        &:hover {
          color: $secondary;
          border: 1px solid $primary;
        }
        &.active {
          color: $secondary;
          border: 1px solid $primary;
          background: $bgsection;
        }
      }
    }
  }
}
</style>
