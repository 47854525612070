import {registerPlugin} from '@capacitor/core';
import {callLambda} from '../api/lambda';
import delay from '../base/delay';
import _ from 'lodash';

class JPush {
  constructor() {
    this.tryCount = 0;
    this.isPluginError = null;
    this.registrationId = null;
    this.isHavePlugin = null;
    this.capacitorPlugin = registerPlugin('JPush');
    this.checkReady();
    this.updateTags = _.throttle(this._updateTags, 1000);
  }

  async checkReady() {
    const condition = await this.checkIsHavePlugin();
    const registrationId = await this.getRegistrationId();
    return condition && registrationId;
  }
  async _updateTags() {
    if (await this.checkReady()) {
      callLambda({
        category: 'user',
        apiName: 'registration/tags',
        method: 'post',
        data: {registrationId: this.registrationId},
      });
    }
  }
  async checkIsHavePlugin() {
    if (this.isHavePlugin === null) {
      try {
        await this.capacitorPlugin.echo({message: 'init'});
        this.isHavePlugin = true;
      } catch (error) {
        this.isHavePlugin = false;
      }
    }
    return this.isHavePlugin;
  }
  async getTempInfo() {
    if (await this.checkIsHavePlugin()) {
      try {
        return this.capacitorPlugin.getTempInfo();
      } catch (error) {
        console.log('please update app from store');
      }
      return null;
    }
  }
  async clearTempInfo() {
    if (await this.checkIsHavePlugin()) {
      try {
        return this.capacitorPlugin.clearTempInfo();
      } catch (error) {
        console.log('please update app from store');
      }
      return null;
    }
  }

  async getRegistrationId() {
    if (this.registrationId) return this.registrationId;
    if (this.isPluginError) return null;
    if (this.isGetting) {
      await delay(1000);
      return this.getRegistrationId();
    }
    this.isGetting = true;
    this.tryCount++;
    if (this.tryCount > 10) return (this.isPluginError = true);
    if (await this.checkIsHavePlugin()) {
      try {
        const {
          registrationID: registrationId,
        } = await this.capacitorPlugin.getRegistrationID();
        if (registrationId) {
          this.registrationId = registrationId;
          this.isGetting = false;
          return registrationId;
        } else throw new Error('no registrationId');
      } catch (error) {
        this.isGetting = false;
        await delay(1000);
        return this.getRegistrationId();
      }
    }
  }
}

export default JPush;
