import liveCourseService from '@/services/liveCourse';
import i18n from '@/i18n/index.js';
import day from 'dayjs';

const state = {
  tags: [],
  dailyLiveList: [],
};

const getters = {
  tags(state) {
    return state.tags;
  },
  teachers() {
    return state.tags.filter((tag) => tag.kind === i18n.t('老師'));
  },
  plans() {
    return state.tags.filter((tag) => tag.kind === i18n.t('課程'));
  },
  types() {
    return state.tags.filter((tag) => tag.kind === i18n.t('分類'));
  },
  others() {
    return state.tags.filter((tag) => tag.kind === i18n.t('其他'));
  },
};

const actions = {
  async getTags({commit}, coursePlan) {
    try {
      const result = await liveCourseService.getTags({coursePlan});
      commit('setTags', result);
    } catch (error) {
      throw error;
    }
  },
  async getKid2WeekLiveCourses({commit}) {
    try {
      const results = await liveCourseService.getKid2WeekLiveCourses();
      const liveList = results.filter(
        (result) => day().subtract(30, 'day').valueOf() <= result.endAt
      );

      commit('setDailyLiveList', liveList);
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  setTags(state, data) {
    state.tags = data;
  },
  setDailyLiveList(state, data) {
    state.dailyLiveList = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
