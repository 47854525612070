<template>
  <b-sidebar
    id="sidebar-backdrop"
    backdrop
    :width="$device.isMobile ? '320px' : '936px'"
    bg-variant="bgcontainer"
    body-class="scroll-bar"
    no-header
    :visible="visible"
    @change="visibleChange"
  >
    <template v-if="$device.isMobile" #default>
      <div class="header position-relative">
        <i
          class="icon-Circle-X text-32 text-primary cursor-pointer position-absolute"
          @click="onClickClose"
        ></i>
      </div>
      <div class="content">
        <div v-for="list in mobileList" :key="list.title" class="list">
          <div class="title text-14">{{ list.title }}</div>
          <template v-for="item in list.group">
            <div
              v-if="!item.isHidden"
              :key="item.key"
              class="list-item d-flex align-items-center bg-white position-relative"
              @click="onClickEvent(item)"
            >
              <i
                :class="`icon-${item.icon} ${
                  item.variant ? 'text-' + item.variant : 'text-primary'
                }`"
                class="text-32 mr-2"
              ></i>
              {{ item.title }}
              <div
                v-if="
                  item.key === 'verification' && hasVerificationNotification
                "
                class="notification bg-accent position-absolute"
              ></div>
            </div>
          </template>
        </div>
        <div
          v-if="advertisement.href"
          class="ads rounded-md m-3 overflow-hidden"
          @click="onClickEvent(advertisement)"
        >
          <img class="img-fluid" :src="advertisement.img" alt="" />
        </div>
      </div>
    </template>
    <template v-else #default>
      <div class="header position-relative px-4 pt-4">
        <img :src="BaseLogo" alt="" width="100" />
        <i
          class="icon-Circle-X text-40 text-primary cursor-pointer position-absolute"
          @click="onClickClose"
        ></i>
      </div>
      <div class="d-flex align-items-start px-4 py-3">
        <div
          v-for="(tabs, index) in webTabList"
          :key="index"
          class="btn-wrapper d-flex text-18 font-weight-bold"
        >
          <div class="mr-4">
            <div
              v-for="tab in tabs"
              :key="tab.key"
              class="button p-4 d-flex align-items-center cursor-pointer shadow-sm position-relative"
              :class="{active: isActive(tab)}"
              @click="onClickEvent(tab)"
            >
              <i :class="`icon-${tab.icon}`" class="text-32 mr-4"></i>
              {{ tab.title }}
              <div
                v-if="tab.key === 'verification' && hasVerificationNotification"
                class="notification bg-accent position-absolute"
              ></div>
            </div>
          </div>
        </div>
        <div class="link-wrapper w-100">
          <h4 class="font-weight-bold text-18">Links</h4>
          <template v-for="link in webLinks">
            <a
              v-if="!link.isHidden"
              :key="link.key"
              :href="link.href"
              class="link d-flex align-items-center px-4 py-1 d-block w-100 mt-3 shadow-sm"
              target="_blank"
            >
              <i class="icon-external-link text-32 mr-4"></i>
              {{ link.title }}
            </a>
          </template>
          <div
            v-if="advertisement.href"
            class="ads rounded-md mt-3 overflow-hidden"
          >
            <a :href="advertisement.href" target="_blank">
              <img class="img-fluid" :src="advertisement.img" alt="" />
            </a>
          </div>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import BaseLogo from '@/assets/img/login/base-logo.png';
import {getLocalData, saveLocalData} from '@/lib/base/localData';
import {Browser} from '@capacitor/browser';
import BackEvent from '@/lib/base/backEvent.js';
import {manifest} from '@/constant/env.js';

export default {
  data() {
    return {
      BaseLogo,
      webLinks: [
        {
          key: 'web',
          title: this.$t('官方網站'),
          href: manifest.homePageUrl,
        },
        {
          key: 'discord',
          title: this.$t('圍棋社群（Discord）'),
          href: manifest.discord,
        },
        {
          key: 'accupass',
          title: this.$t('實體活動（Accupass）'),
          href: manifest.accupass,
        },
        {
          key: 'store',
          title: this.$t('商城'),
          href: manifest.store,
        },
      ],
      webTabList: [
        [
          {
            key: 'home',
            icon: 'Home',
            title: this.$t('課程'),
            event: 'home',
          },
          {
            key: 'game',
            icon: 'Go-Kifu2',
            title: this.$t('下棋'),
            event: 'practiceGame',
            toNoLessonPage: true,
          },
          {
            key: 'tsume',
            icon: 'Kifu2',
            title: this.$t('做題'),
            event: 'practiceTsume',
            toNoLessonPage: true,
          },
          {
            key: 'live',
            icon: 'live-stream',
            title: this.$t('直播'),
            event: 'liveList',
          },
          {
            key: 'personal',
            icon: 'User',
            title: this.$t('個人'),
            event: 'personal',
          },
        ],
        [
          {
            key: 'verification',
            icon: 'Verification',
            title: this.$t('檢定'),
            event: 'verification',
            toNoLessonPage: true,
          },
          {
            key: 'pvp',
            icon: 'Battle',
            title: this.$t('對戰'),
            event: 'pvp',
            toNoLessonPage: true,
          },
          {
            key: 'kifus',
            icon: 'Kifu',
            title: this.$t('我的棋譜'),
            event: 'kifus',
          },
          {
            key: 'learning-process',
            icon: 'Learning-history',
            title: this.$t('學習歷程'),
            event: 'learning-process',
          },
          {
            key: 'nfts',
            icon: 'Go-nfts',
            title: 'NFTs',
            event: 'nfts',
          },
        ],
      ],
      mobileList: [
        {
          title: this.$t('其他功能'),
          group: [
            {
              key: 'pvp',
              icon: 'Battle',
              title: this.$t('對戰'),
              event: 'pvp-home',
              toNoLessonPage: true,
            },
            {
              key: 'verification',
              icon: 'Verification',
              title: this.$t('檢定'),
              event: 'verification',
              toNoLessonPage: true,
            },
          ],
        },
        {
          title: this.$t('快速連結'),
          group: [
            {
              key: 'kifus',
              icon: 'Kifu',
              title: this.$t('我的棋譜'),
              event: 'kifus',
            },
            {
              key: 'learning-process',
              icon: 'Learning-history',
              title: this.$t('學習歷程'),
              event: 'learning-process',
            },
            {
              key: 'nfts',
              icon: 'Go-nfts',
              title: 'NFTs',
              event: 'nfts',
            },
          ],
        },
        {
          title: this.$t('重要連結'),
          group: [
            {
              key: 'web',
              icon: 'external-link',
              title: this.$t('官方網站'),
              href: manifest.homePageUrl,
              variant: 'secondary',
            },
            {
              key: 'discord',
              icon: 'external-link',
              title: this.$t('圍棋社群（Discord）'),
              href: manifest.discord,
              variant: 'secondary',
            },
            {
              key: 'accupass',
              icon: 'external-link',
              title: this.$t('實體活動（Accupass）'),
              href: manifest.accupass,
              variant: 'secondary',
            },
            {
              key: 'store',
              icon: 'external-link',
              title: this.$t('商城'),
              href: manifest.store,
              variant: 'secondary',
            },
          ],
        },
      ],
    };
  },
  computed: {
    notifiable() {
      return (
        this.verificationData.state === 'ENABLE' ||
        this.verificationData.state === 'SCORE'
      );
    },
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    hasVerificationNotification() {
      return this.$store.state.env.hasVerificationNotification;
    },
    visible() {
      return this.$store.state.env.isSidebarShow;
    },
    noPlans() {
      return this.$store.getters['course/noPlans'];
    },
    active() {
      return this.$route.matched[0]?.name;
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    advertisement() {
      return {
        href: '',
        img: '',
      };
    },
  },
  watch: {
    verificationData() {
      if (this.currentPlan.id != null) {
        this.setVerificationNotification();
      }
    },
  },
  methods: {
    isActive(tab) {
      if (this.noPlans && tab.toNoLessonPage) {
        const type = this.$route.params.type;
        return type === tab.event;
      } else if (
        this.$device.isMobile ||
        ['kifus', 'learning-process', 'nfts', 'personal'].includes(tab.event)
      ) {
        return this.active === tab.event;
      } else {
        return (
          this.$route.path.includes(tab.event) || this.active === tab.event
        );
      }
    },
    async onClickEvent(tab) {
      if (tab.href) {
        return Browser.open({
          url: tab.href,
          presentationStyle: 'popover',
        });
      }
      if (tab.event === 'verification') {
        this.$store.commit('env/setHasVerificationNotification', false);
        const plansNotifiableStatus = {
          ...JSON.parse(getLocalData('plans-notifiable-status')),
        };
        plansNotifiableStatus[this.currentPlan.id] = this.notifiable;

        saveLocalData(
          'plans-notifiable-status',
          JSON.stringify(plansNotifiableStatus)
        );
      }
      if (this.noPlans && tab.toNoLessonPage) {
        return this.$router.push({
          name: 'no-lesson',
          params: {
            type: tab.event,
          },
        });
      } else if (tab.event === 'practiceGame') {
        this.$store.commit('env/setIsLoading', true);
        await this.getUnfinishedGame();
        return this.$router.push({
          name: 'practiceGame',
        });
      } else if (tab.event === 'practiceTsume') {
        return this.$router.push({
          name: 'practiceTsume',
          params: {rank: 'uncast', type: 'uncast'},
        });
      } else if (tab.event === 'more') {
        this.$store.commit('env/setIsSidebarShow', true);
      } else if (this.$route.name !== tab.event) {
        return this.$router.push({name: tab.event});
      }
      this.$store.commit('env/setIsSidebarShow', false);
    },
    setVerificationNotification() {
      const plansNotifiableStatus =
        JSON.parse(getLocalData('plans-notifiable-status')) || {};
      this.$store.commit(
        'env/setHasVerificationNotification',
        this.notifiable && !plansNotifiableStatus[this.currentPlan.id]
      );

      if (!this.notifiable) {
        plansNotifiableStatus[this.currentPlan.id] = this.notifiable;
        saveLocalData(
          'plans-notifiable-status',
          JSON.stringify(plansNotifiableStatus)
        );
      }
    },
    async getUnfinishedGame() {
      await this.$store.dispatch('aiGame/getUnfinishedGame');
      this.$store.commit('env/setIsLoading', false);
    },
    visibleChange(visible) {
      this.$store.commit('env/setIsSidebarShow', visible);
      if (!visible) {
        BackEvent.popEvent();
      }
    },
    onClickClose() {
      this.$store.commit('env/setIsSidebarShow', false);
      BackEvent.popEvent();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .b-sidebar-backdrop {
  background: $grayscale-20 !important;
}
/deep/ .b-sidebar {
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
}
#sidebar-backdrop {
  .icon-Circle-X {
    top: 16px;
    right: 16px;
  }
  .content {
    margin-top: 56px;
  }
  .list {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
  .title {
    padding: 0 20px;
    color: $font-grayscale-2;
    margin-bottom: 12px;
  }
  .list-item {
    border-bottom: 1px solid $line-2;
    padding: 9px 16px;
  }
  i {
  }
  .notification {
    width: 14px;
    height: 14px;
    top: 9px;
    left: 33px;
    border-radius: 50%;
  }
}

@media screen and (min-width: 768px) {
  #sidebar-backdrop {
    .icon-Circle-X {
      top: 27.75px;
      right: 27.75px;
    }

    h4 {
      color: $font-grayscale-1;
    }
    .button {
      width: 280px;
      border-radius: $rounded-md;
      line-height: 30px;
      background: $white;
      transition: all 0.3s;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      &:hover {
        background: rgba(255, 192, 105, 0.3);
      }
      &.active {
        color: $white;
        background: $secondary;
      }
    }
    .link {
      text-decoration: none;
      color: $font-grayscale-1;
      background: $white;
      border-radius: $rounded-md;
      &:hover {
        background: rgba(255, 192, 105, 0.3);
      }
    }
    .notification {
      width: 14px;
      height: 14px;
      top: 24px;
      left: 42px;
      border-radius: 50%;
    }
  }
}
</style>
