const lang = {
  我的課程: '講座',
  測試訊息: 'test',
  課程: '講座',
  練習: '練習',
  檢定: '検定',
  個人: 'マイページ',
  儲存: '保存',
  對戰: '対局',
  上次觀看: '前回閲覧',
  開始學習: '開始',
  第: 'Lesson',
  課: '',
  歡迎來到黑嘉嘉圍棋教室: '',
  早安: '',
  午安: '',
  晚安: '',
  有新課程: '',
  下棋: '対局',
  做題: '練習',
  棋力檢定: '棋力認定検定',
  載入中: '読み込み中',
  AI計算中: 'AI計算中',
  黑勝: '黒勝',
  白勝: '白勝',
  黑中盤勝: '黒中押し勝ち',
  白中盤勝: '白中押し勝ち',
  請耐心等待: 'しばらくお待ちください',
  '有地方沒下完，還不能申請算輸贏':
    'まだアキ地があるので【勝ち負け結果】申請できません',
  手以上才能申請算輸赢哦: '手以上で【勝ち負け結果】申請できます',
  '（黑{blackScore}，黑收後）': '',
  '（黑{blackScore}，白收後）': '',
  '準備好和我一起進入圍棋的世界了嗎？': '純碁10分で覚えられる囲碁',
  黑嘉嘉圍棋教室: '老若男女問わない、みんなで楽しめる',
  '由淺入深，量身打造學習體驗': '無料で遊べる脳トレゲーム',
  '通過線上檢定，取得棋力證書': '今すぐ始めましょう！',
  開啟圍棋之旅: 'スタート',
  請輸入帳號: 'アカウントを入力してください',
  '請輸入8-15位密碼': 'パスワードは8~15文字を入力してください',
  查無此帳號: 'アカウントが見つかりませんでした',
  '*請輸入帳號': '*アカウントを入力してください',
  '*請輸入密碼': '*パスワードを入力してください',
  '*查無此帳號': '*アカウントが見つかりませんでした',
  '*您的帳號已被停用': '*アカウントが無効になりました',
  '*不能少於6位數': '*最低8文字以上である必要があります',
  '*密碼錯誤':
    '*メールアドレスまたは携帯電話番号に誤りがあります。訂正してからもう一度試してください',
  登入: 'ログイン',
  '忘記密碼？': 'パスワードをお忘れですか？',
  我同意: '利用規約とプライバシーポリシーに同意します。',
  和: '',
  '還沒有帳號？前往': '初めてご利用ですか?',
  官網購買課程: '公式サイトにてコース購入可能です',
  尚未同意服務條款和隱私政策:
    'まだ利用規約とプライバシーポリシーに同意していません',
  '*伺服器發生錯誤，請稍後再試':
    '*サーバーエラーが発生し、しばらくしてから再試行してください',
  提醒: 'ヒント',
  關閉螢幕錄影才能繼續使用: '',
  下棋累計數: '',
  做題累計數: '',
  下棋勝率: '対戦勝率',
  做題正確率: '練習正確率',
  證書: '棋力認定証',
  棋譜: '棋譜',
  密碼: 'パスワード',
  設定: '設定',
  聯絡我們: 'お問い合わせ',
  登出: 'ログアウト',
  成長紀錄: '',
  編輯: '編集',
  頭像: 'アバター',
  名字: 'お名前',
  編輯名字: 'お名前編集',
  編輯個人頭像: 'アバター編集',
  完成: '完成',
  請輸入新名字: '新しいお名前を入力してください',
  '*請輸入新名字': '*新しいお名前を入力してください',
  更改密碼: 'パスワードの変更',
  請輸入舊密碼: '旧パスワードを入力してください',
  '*請輸入舊密碼': '*旧パスワードを入力してください',
  '*舊密碼輸入錯誤': '*旧パスワードが間違っています',
  '請輸入8-15位新密碼': 'パスワードは8~15文字を入力してください',
  '*請輸入新密碼': '*新パスワードを入力してください',
  '*兩次新密碼輸入不同': '*新パスワードと再入力パスワードが一致しません',
  請再次輸入新密碼: '新パスワードをもう一度入力してください',
  '*請再次輸入新密碼': '*新パスワードをもう一度入力してください',
  '*新密碼和舊密碼一樣': '*新パスワードは旧パスワードと同じです',
  '*不能少於8碼': '*最低8文字以上である必要があります',
  密碼更改失敗: 'パスワードの変更に失敗しました',
  密碼更改成功: 'パスワードの変更に成功しました',
  返回上一頁: '戻る',
  棋力證書: '棋力認定証',
  音效: '効果音',
  直接落子: '直接に打てる',
  檢定說明: '検定説明',
  歷史成績單: '成績履歴',
  開始檢定: '検定開始',
  檢定進行中: '検定中',
  成績將於隔日早上10點公布: '結果発表は30分後です',
  '{count}個項目。': '。',
  你已完成所有項目: '全項目をクリアしました',
  棋力檢定包含:
    '棋力認定検定は布石、計算、死活、官子、碁を打つで構成されています',
  公布: '結果発表',
  '各項目皆需在規定時間內完成作答。':
    '全項目は制限時間内に回答する必要があります',
  '分數達評分標準即通過測驗,將升級個人棋力並授予證書。':
    '採点基準を満たす点数でテストに合格すると、個人棋力がアップグレードされ、認定証が授与されます',
  隔日早上10點: '',
  評分標準: '採点基準',
  題: '問',
  大局: '',
  佈局: '',
  戰鬥: '',
  官子: '',
  綜合: '',
  好棋率: '',
  盤: '',
  滿分100分: '',
  得分超過: '',
  所有項目加總滿分為100分: '全項目100点満点で、75点以上で合格とします',
  '測驗時間{time}分鐘': 'テスト時間{time}分',
  '共{count}題': '全{count}問',
  '每題{score}分': '每問{score}分',
  計分項目為: '',
  以上: '',
  視為通過: '',
  開始: 'スタート',
  已完成: '完成',
  目前沒有成績單: '現在、成績はありません',
  檢定後歷史成績單將會顯示在這: '検定後の成果はここに表示されます',
  已通過: '合格',
  未通過: '未合格',
  成績單: '成績履歴',
  棋力檢定成績單: '棋力認定検定成果',
  分: '分',
  得分: '点数',
  勝負: '勝負',
  其他: '',
  已領取: '',
  領取證書: '',
  再次檢定: '検定開始',
  請再試一次: '',
  你已完成所有檢定: '全検定を完成しました',
  '恭喜你完成所有課程！': '全講座修了おめでとうございます！',
  立即觀賞完課特輯: '今すぐ講座終了スペシャルを見る',
  提子: 'アゲ石',
  算輸贏: 'パス',
  題目: '問題',
  我的答案: '私の答え',
  解答: '解答',
  將繼續上次未下完的棋局: '前回未完成対局の続き',
  對局中: '対局中',
  看起來還沒下完哦: 'まだ終わっていないようですよ',
  目前沒有棋譜: '現在、棋譜はありません',
  下棋後個人棋譜將會顯示在這: '対戦後、あなたの個人棋譜はここに表示されます',
  共有: '全部',
  個棋譜: '個棋譜があります。',
  白超時負: '白時間切れ負け',
  黑超時負: '黒時間切れ負け',
  '黑{v1}又{v2}/4子勝': '',
  '黑{v2}/4子勝': '',
  '白{v1}又{v2}/4子勝': '',
  '白{v2}/4子勝': '',
  '網路不穩定，將自動重新整理':
    'インターネット接続が不安定ですため、自動的に再読み込みします',
  '棋局結束啦，請點擊［算輸贏］按鈕！':
    '対局終了！【勝ち負け結果】ボタンをクリックしてください',
  還沒輪到你哦: 'まだあなたの番ではありませんよ',
  請先離開: 'まずは【勝ち負け結果】から戻ってください',
  數地: '',
  模式: '',
  投降: '投了',
  取消: 'いいえ',
  確定: 'はい',
  返回: '戻る',
  再一局: 'もう一回',
  再一次: '',
  '確定要認輸？': '投了しますか？',
  下棋練習: 'AI対局',
  '開放條件：通過10k檢定': '',
  解鎖條件: '',
  開放: '',
  中級: '',
  高級: '',
  基礎: '',
  入門: '',
  初學: '',
  初級: '',
  '是否繼續上次的對局？': '前回未完成対局に引き続き対局されるのでしょうか？',
  繼續: '続く',
  不繼續: '続かない',
  返回首頁: '戻る',
  看解答: '答えを見る',
  做題練習: '練習',
  切換難度: '',
  難度: '進捗状況確認',
  新手30k: '',
  基礎25k: '',
  入門20k: '',
  初學15k: '',
  '高級9級~15級': '',
  '衝段4級~1段': '',
  答對: '正解',
  共: '全',
  黑棋如何做活: '黒：活かし方',
  黑棋如何殺掉白棋: '黒：白を殺す方法',
  黑棋如何吃掉白棋: '黒：白を取る方法',
  黑棋如何不被白棋吃掉: '黒：白に取られない方法',
  黑棋如何比氣吃掉白棋: '黒：攻め合いで白を取る方法',
  黑棋如何用接不歸吃掉白棋: '黒：追落で白を取る方法',
  黑棋要從哪個方向叫吃白棋: '黒：どの方向から白石を取るのですか',
  黑棋要如何把白棋切斷: '黒：どうやって白をキリしますか',
  如何把黑棋連在一起: '黒：どうやって黒をツナギしますか',
  黑棋要如何把空圍好: '黒：',
  黑棋如何用門吃吃掉白棋: '黒：',
  黑棋如何用反提吃掉白棋: '黒：',
  黑棋要如何打劫: '黒：',
  黑棋如何避免被白棋雙叫吃: '黒：',
  黑棋如何做出眼睛: '黒：',
  黑棋如何製造打劫: '黒：',
  黑棋如何下成雙活: '黒：',
  黑棋如何破掉白棋的眼: '黒：',
  黑棋如何用征子吃掉白棋: '黒：',
  黑棋如何利用打劫做活: '黒：',
  黑棋如何利用打劫殺掉白棋: '黒：',
  黑棋如何反提吃掉白棋: '黒：',
  如何把黑棋連起來: '黒：',
  黑棋如何反提吃白: '黒：',
  黑先吃白: '黒が白を先に取る',
  黑先殺白: '黒が白を先に殺す',
  黑棋如何逃跑: '黒：逃げる方法',
  打劫殺白: '黒：',
  打劫做活: '黒：',
  打劫活: '黒：',
  黑先做活: 'まず黒を活かす',
  黑棋如何雙叫吃白棋: '黒：',
  黑先如何切斷白棋: '黒：',
  黑先如何連接: '黒：',
  題正確: '問正解',
  題錯誤: '問不正解',
  '確定要離開？': '閉じてよろしいでしょうか？',
  題目列表: '',
  正確: '正解',
  錯誤: '不正解',
  上一題: '前の問題',
  下一題: '次の問題',
  離開將會自動結算: '',
  點選下列網址或掃描QRCode: '',
  '加入［黑嘉嘉圍棋教室］LINE好友': '',
  '加入LINE@': '',
  '{rank}級棋力檢定證書': '{rank}棋力認定検定成果',
  參加棋力檢定測驗已達標準: '棋力認定検定の基準に達したことを証明するため、',
  '特頒鑑定證書，以資證明': 'ここに認定証を発行します',
  目前沒有棋力證書: '現在、棋力認定証はありません',
  棋力檢定通過後證書將會顯示在這: '検定合格後の棋力認定証はここに表示されます',
  '成為正式會員,棋力{rank}級': '',
  '通過棋力檢定,棋力上升至{rank}級': '',
  '發生未知錯誤，再試一次': '',
  手: '手',
  路: '路',
  日期: '時間',
  棋譜資訊: '棋譜情報',
  黑方: '黒',
  白方: '白',
  結果: '結果',
  知道了: '了解',
  之後可以在歷史成績單查看: 'その後、成績履歴で確認ができます',
  證書儲存成功: '棋力認定証、保存成功',
  證書儲存失敗: '棋力認定証、保存失敗',
  圖片下載發生錯誤: '画像のダウンロードでエラーが発生しました',
  再按一次離開應用程式: 'もう一度タップしてアプリを終了します',
  請輸入暱稱: 'ニックネームを入力してください',
  註冊: 'アカウントを作成する',
  '已經有帳號？前往': 'すでにアカウントをお持ちですか？',
  請輸入手機號碼: '電話番号を入力してください',
  請輸入票券序號: '',
  '*手機號碼必須為數字': '*電話番号はハイフンなし、半角数字で入力してください',
  '*此帳號已註冊過': '*このアカウントはすでに登録されています',
  '*請輸入驗證碼': '*認証コードを入力してください',
  '*驗證碼錯誤': '*認証コードが間違っています',
  '*請輸入暱稱': '*ニックネームを入力してください',
  '*請輸入手機號碼': '*電話番号を入力してください',
  '*請輸入序號': '',
  '*此序號不存在': '',
  '*此序號已退費': '',
  '*請輸入正確的手機號碼': '*有効な電話番号を入力してください',
  '*手機號碼已被使用': '*この電話番号はすでに使用されています',
  '*序號已被使用': '',
  '*序號已失效': '',
  '*操作過於頻繁，請稍後再嘗試': '*しばらくしてから、操作を再試行してください',
  '*驗證碼已逾期': '*認証コードの有効期限が過ぎています',
  輸入驗證碼: '認証コードを入力する',
  傳送驗證碼: '認証コードを送信する',
  再一次傳送: '',
  序號: '',
  '沒有序號嗎？前往': '',
  獲得序號: '',
  忘記密碼: 'パスワードをお忘れですか？',
  送出: '送信する',
  '系統將為你登出帳號，': '',
  '確定要返回上一頁嗎？': '',
  成功啟用序號: '',
  重設密碼: 'パスワードの変更',
  註冊成功: '',
  總分: '総点数',
  更改成功: '変更に成功',
  服務條款和隱私政策: '利用規約',
  '*手機號碼尚未註冊': '*電話番号が未登録',
  '*請再次輸入密碼': '*パスワードを再入力してください',
  '*兩次密碼輸入不同': '*新パスワードと再入力パスワードが一致しません',
  '我在［黑嘉嘉圍棋教室］等你一決高下～': '黒嘉嘉囲碁教室で勝負をしましょう〜',
  '你有新的對弈邀請！': '新しい対局への招待状が届きました',
  勝利: '勝利',
  失敗: '残念...',
  很熟練喔: '凄い！',
  要再加強: '残念...',
  請再次輸入密碼: 'パスワードをもう一度入力してください',
  解說影片: '解説動画',
  歡迎來到: '',
  時限: '時間制限',
  讀秒: '秒読み',
  讀秒秒數: '秒数',
  讀秒次數: '回数',
  讓子: 'あり',
  手合: '置き碁',
  '段差一級讓一子，有讓子上限':
    '棋力差は1級で、置き碁の数を1つとなります。置き碁の上限があります。',
  '{v1}分鐘': '{v1}分',
  '{v1}秒': '{v1}秒',
  '{v1}次': '{v1}回',
  '{v1}路-{v2}分鐘-{v3}秒{v4}次': '{v1}路-{v2}分-{v3}秒{v4}回',
  不讓子: 'なし',
  建立對局: '対局開設',
  邀請好友下棋: '友人を招待してゲーム',
  點擊分享連結按鈕邀請好友一起對弈:
    'リンクを共有して友達に対局の招待することができます',
  對局條件: '対局条件',
  棋盤: '碁盤',
  '{v1}路': '{v1}路',
  有: 'あり',
  無: 'なし',
  離開: '閉じる',
  分享連結: 'リンクをシェア',
  '確定要離開對局？': '閉じてよろしいでしょうか？',
  同意: '同意する',
  拒絕: '同意しない',
  對方想要申請算輸贏: '相手が【勝ち負け結果】を申請したいです',
  '是否同意？': '同意しますか？',
  正在等待對方回應: '相手の回答待ち',
  '對方拒絕算輸贏，請繼續對局':
    '相手が【勝ち負け結果】断っているため、対局を続行してください',
  '還沒下完，還不能算輸贏哦！':
    'まだアキ地があるので【勝ち負け結果】申請できません',
  有一方不同意輸贏結果:
    'どちらかが勝ち負け結果に納得しない場合、対局に戻ります',
  對方婉拒你的邀請: '相手が丁重にあなたの招待をお断りしています',
  '對方網路不穩定，請耐心等候':
    '相手のインターネット接続が不安定ですため、しばらくお待ちください',
  '落子異常，刷新棋盤資訊中...': '着手異常、碁盤情報更新中です。',
  '對方斷線，不能算輸贏': '相手が落ちましたため、勝ち負け結果になれません',
  請確認網路連線的狀態後再次嘗試:
    'インターネット接続の状態をご確認の上、再度お試しください',
  '對局仍在進行中，確定要離開嗎？':
    '対局はまだ進行中ですが、本当に閉じますか？',
  '發言時間過近，請{v1}秒後再發言': '{v1}秒待ってから発言してください',
  只有落子方可以申請算輸贏:
    '着手になっている一方だけが勝ち負け結果を申請できます',
  請先完成目前的操作: '現在の操作を先に完了してください',
  '黑{blackScore}，黑收後': '',
  '黑{blackScore}，白收後': '',
  '(你已確認結果，對方仍在確認中)':
    '（あなたが結果を確認しました。相手がまだ確認中です。）',
  對方已婉拒或離開房間: '相手が丁重にあなたの招待をお断りしています',
  等待好友: '友達を待つ',
  房間不存在:
    '対局が存在しません。相手がマッチをキャンセルかオフラインになりました',
  此房間已關閉:
    '対局が存在しません。相手がマッチをキャンセルかオフラインになりました',
  複製成功: 'コピーされました',
  秒: '秒',
  次: '回',
  無法加入自己創建的房間: '開設した対局ルームに参加することができません',
  繼續練習: '練習',
  天: '',
  '即將開課，敬請期待': '',
  '（黑{blackScore}子）': '（黒{blackScore}子）',
  '非預期的錯誤發生，請重新整理':
    '予期しないエラーが発生したため、再読み込みしてください',
  '棋局還沒結束哦！': '',
  '此頁面無法重複開啟，按確定後自動跳至首頁':
    '何度もリダイレクトされてページを開けなくなり、確定ボタンをタップすると自動的にトップへ戻ります',
  已有其他使用者登入:
    '他のデバイスでログインしていますため、このデバイスでは利用できなくなり、自動的にログアウトされます',
  登入其他帳號: '別のアカウントにログインする',
  將他人登出: '他の人をログアウトする',
  已有其他使用者登入本帳號: '別のユーザーがこのアカウントにログインしています',
  '有新版本，請至商店下載更新': 'ストアに最新バージョンへアップデートのお願い',
  請記得於時間內完成: '制限時間内に終了することを忘れないでください。',
  逾期將視為放棄: '遅れた場合は、放棄とみなされます。',
  '共有{totalKifus}個棋譜，': '全部{totalKifus}個棋譜があります。',
  代表有覆盤: 'を表示されるのは局後の検討があります。',
  '問題手，': '一手損，',
  查看變化圖: '変化図',
  '好棋！': '',
  '下得真好～': '',
  仍有進行中的對局: 'まだ進行中の対局があり、マッチングできません',
  離開將自動判輸: '閉じた場合には負けと判断され、',
  '確定要離開嗎？': '閉じてよろしいでしょうか？',
  對方想要再一局: '相手がもう一回の対局を希望しています',
  '國家/地區': '',
  台灣: '',
  香港: '',
  馬來西亞: '',
  新加坡: '',
  作答結束: '回答終了',
  請先確認算輸贏結果: 'まず【勝ち負け結果】を確認してください',
  我的學習進度: '学習進捗',
  數據紀錄: 'データレコード',
  '目前版本：{version}': '現在のバージョン：{version}',
  修改密碼: 'パスワードの変更',
  系統設定: 'システム設定',
  我的棋譜: '私の棋譜',
  目前沒有網路: 'インターネット接続の状態をご確認の上、再度お試しください。',
  無法使用耶: '再読み込み',
  '完課了還是可以自己練習喔！': '講座終了後も自主練習が可能ですよ！',
  課程影片也可以無限次重複觀看: '講座の映像も無制限で再視聴可能です',
  手數: '手数',
  '{winCount}勝': '{winCount}勝',
  '{loseCount}敗': '{loseCount}敗',
  '勝率{winRate}%': '勝率{winRate}%',
  '累計{winCount}題': '累積{winCount}問',
  '正確率{winRate}%': '正確率{winRate}%',
  開始上課: '',
  棋聚一堂: '',
  一起學棋: '',
  一起下棋: '',
  切換課程: '講座を切り替える',
  這邊可以切換新的課程喔: 'ここから新しい講座に切り替えられます',
  查看完課特輯: '講座終了スペシャルを見る',
  學習進度: '学習進捗',
  你還沒有任何課程: 'まだ講座を購入していません。',
  前往官網了解: '詳細は公式サイトへ',
  限時倒數: '制限時間',
  勝利條件: '勝利条件',
  對方Pass: '相手がPass',
  '對方Pass，建議點擊［算輸贏］按鈕結束棋局':
    '相手がPass、【勝ち負け結果】ボタンをクリックしてください',
  黑: '黒',
  子: '',
  確定落子: '着手決定',
  認輸: '投了',
  手數顯示: '手順表示',
  直播: '',
  你與黑嘉嘉的專屬課程: '',
  課程影片: '講座動画',
  已切換課程與檢定: '',
  視頻解說: '解説動画',
  查看解答: '解答を見る',
  目前沒有直播: '',
  直播開放後將會顯示在這: '',
  房間: '',
  進入直播: '',
  已結束: '',
  未開始: '',
  觀看回放: '',
  第一手: '',
  最終手: '',
  上十手: '',
  下十手: '',
  上一手: '前の手',
  下一手: '次の手',
  問題手: '一手損',
  離開變化圖: '',
  兒童啟蒙: '',
  手數隱藏: '手順隠し',
  請將裝置: 'デバイス画面を',
  橫向: '横向',
  使用: 'きに回転してください',
  保存更改: '変更を保存する',
  查看: '確認',
  個人資訊更改成功: '個人情報の変更に成功しました',
  個人資訊更改失敗: '個人情報の変更に失敗しました',
  編輯個人資訊: '個人情報編集',
  我的成績單: '私の成績',
  你有新的成績: '新しい成績が出ました',
  查看成績單: '私の成績を確認する',
  在: '',
  立即: '',
  成績將於5分鐘後公布: '結果発表は全項目が終了してから30分後です',
  '本次{rank}棋力檢定：未通過': '今回の{rank}棋力認定検定結果：未合格',
  維修中: '',
  尚未開課: '',
  當前課程不開放檢定: '現在の講座は、検定実施しません',
  尋找對手中: '対戦相手を探す中',
  快速配對: 'クイックマッチ',
  和朋友下棋: '友達対局',
  '仍有進行中的對局，無法配對': 'まだ進行中の対局があり、マッチングできません',
  找不到對手: '対戦相手が見つからず、',
  配對已取消: 'マッチングがキャンセルされました',
  創建對局: '対局開設',
  建立: '対局開設',
  '{v1}路-{v2}分鐘': '{v1}路-{v2}分',
  '{v3}秒{v4}次': '{v3}秒{v4}回',
  邀請再一局: 'もう一回',
  複製連結: 'リンクをコピー',
  發表情: '絵文字を送信する',
  '第 {newDayText} 堂': 'Lesson {newDayText}',
  開放檢定: '',
  提示: 'ヒント',
  點擊: '「講座を切り替える」ボタンをクリックすると、',
  按鈕可切換購買的新課程喔: '新しいご購入した講座に切り替わります',
  '課程－做題': '練習問題',
  '檢定－做題': '練習問題',
  覆盤: '局後検討',
  我要覆盤: '局後の検討が必要です',
  覆盤中: '局後検討中です',
  已覆盤: '局後検討済みです',
  此局手數未達20無法進行覆盤: '手数は20未満なので局後検討はできません',
  '課程-下棋': '対局',
  // 覆盤的評語會會因地區不同而用詞不同，CN版之後可能會改，先不做簡繁轉換，之後CN出來了再改
  '這手棋下得真好！': '这手棋下得真好！',
  '这是个很棒的【征子】，对方一定逃不走！':
    '這是個很棒的【征子】，對方一定逃不走！',
  '這是漂亮的【雙叫吃】，一定可以吃到其中一邊！':
    '这是漂亮的【双打吃】，一定可以吃到其中一边！',
  '這是厲害的【抱吃】！對方死定了！': '這是厲害的【抱吃】！對方死定了！',
  '這手【叫吃】的方向很正確': '这手【打吃】的方向很正确',
  '這是厲害的【門封】，對方逃不走了！': '这手【枷吃】很厉害，对方逃不走了！',
  '這是厲害的【接不歸】，對方想救也救不回去！':
    '这是厉害的【接不归】，对方想救也救不回去！',
  '這手【撲吃】下得很好，撲完再把對手吃回來！':
    '这手【扑吃】下得很好，扑完再把对手吃回来！',
  '這手棋是漂亮的【長氣】，氣比對方多，攻殺就會贏！':
    '这手棋是漂亮的【长气】，气比对方多，攻杀就会赢！',
  '漂亮的【逃走】，差點被對方吃掉！': '漂亮的【逃跑】，差点被对方吃掉！',
  '這是【要子】，非吃不可！': '这是【要子】，非吃不可！',
  '這手【連接】非常好，棋子變強了': '这手【连接】非常好，棋子变强了',
  '這是個很棒的【切斷】，對手慘了！': '这是个很棒的【切断】，对手惨了！',
  '這是個很棒的【雙】，連接得非常好！': '这是个很棒的【双】，连接得非常好！',
  '這是個很棒的【刺】，瞄準對方的斷點！':
    '这是个很棒的【刺】，瞄准对方的断点！',
  '這個【長】很棒': '这个【长】很棒',
  '這個【撞】很棒': '这个【撞】很棒',
  '這個【彎】很棒': '这个【弯】很棒',
  '這個【並】很棒': '这个【并】很棒',
  '這個【立】很棒': '这个【立】很棒',
  '這個【衝】很棒': '这个【冲】很棒',
  '這是個很棒的【擋】，這樣對手就過不來了！':
    '这是个很棒的【挡】，这样对手就过不来了！',
  '這個【扳】很棒': '这个【扳】很棒',
  '這個【尖】很棒': '这个【尖】很棒',
  '這個【虎】很棒': '这个【虎】很棒',
  '這個【跳】很棒': '这个【跳】很棒',
  '這個【飛】很棒': '这个【飞】很棒',
  '這個【定石】選得真好！': '这个【定石】选得真好！',
  '【切斷】這裡比較厲害！': '【切断】这里比较厉害！',
  '這個【連接】很重要唷': '这个【连接】很重要唷',
  '下在這裡會更厲害喔！': '下在这里会更厉害喔！',
  '這是【虎口】，會被吃掉！': '这是【虎口】，会被吃掉！',
  '這裡有個【雙叫吃】！一定可以吃到其中一邊！':
    '这里有个【双打吃】！一定可以吃到其中一边！',
  '下這裡就可以【征子】對方唷！': '下这里就可以【征吃】对方哦！',
  '應該下【門封】！把對方直接關起來！': '应该下【枷吃】！把对方直接关起来！',
  '這裡可以使用【抱吃】！對方死定了！': '这里可以使用【抱吃】！对方死定了！',
  '這樣【叫吃】方向才正確': '这样【打吃】方向才正确',
  '這裡有個【接不歸】，對方救不走的！': '这里有个【接不归】，对方救不走的！',
  '這裡可以使用【撲吃】！對方吃掉，我們再吃回來！':
    '这里可以使用【扑】！对方吃掉，我们再吃回来！',
  '這裡剩一氣！快逃跑！': '这里剩一气！快逃跑！',
  '這個要立刻吃掉，別讓對方逃走！': '这个要立刻吃掉，别让对方逃走！',
  '這裡不要救，再救也沒用！': '这里不要救，再救也没用！',
  '這個【叫吃】是吃不到對方的，不要下比較好唷！':
    '这个【打吃】是吃不到对方的，不要下比较好哦！',
  '這裡不能下，下這裡是送對手吃唷！': '这里不能下，下这里是送对手吃哦！',
  '要先下這裡把氣變多，才能繼續戰鬥！': '要先下这里把气变多，才能继续战斗！',
  '這裡要快點【連接】，被切斷的話會危險的！':
    '这里要快点【连接】，被切断的话会危险的！',
  '下這裡可以【切斷】對方，再看要攻擊哪一塊？':
    '下这里可以【切断】对方，再看要攻击哪一块？',
  下這裡可以一邊逃走一邊連接: '下这里可以一边逃走一边连接',
  '這個【定石】這樣下不太適合，下在這比較好':
    '这个【定式】这样下不太适合，下在这比较好',
  '現在這裡已經不重要了，應該【手拔】，下這裡更好！！':
    '现在这里已经不重要了，应该【手拔】，下这里更好！！',
  '現在應該【手拔】，下這個要點！': '现在应该【手拔】，下这个要点！',
  '這裡下【長】會更好喔！': '这里下【长】会更好喔！',
  '這裡下【彎】會更好喔！': '这里下【弯】会更好喔！',
  '這裡下【並】會更好喔！': '这里下【并】会更好喔！',
  '這裡下【立】會更好喔！': '这里下【立】会更好喔！',
  '這裡下【擠】會更好喔！': '这里下【挤】会更好喔！',
  '這裡下【撞】會更好喔！': '这里下【撞】会更好喔！',
  '這裡要用這種方式【叫吃】才厲害喔！': '这里要用这种方式【打吃】才厉害喔！',
  '這裡下【沖】會更好喔！': '这里下【冲】会更好喔！',
  '這裡下【擋】會更好喔！': '这里下【挡】会更好喔！',
  '這裡下【扳】會更好喔！': '这里下【扳】会更好喔！',
  '這裡下【尖】會更好喔！': '这里下【尖】会更好喔！',
  '這裡下【虎】會更好喔！': '这里下【虎】会更好喔！',
  '這裡下【跳】會更好喔！': '这里下【跳】会更好喔！',
  '這裡下【飛】會更好喔！': '这里下【飞】会更好喔！',
  '這裡下【刺】會更好喔！': '这里下【刺】会更好喔！',
  '這裡下【雙】來連接會更好喔！': '这里下【双】来连接会更好喔！',
  '現在應該【做眼】，來做活這塊棋！': '现在应该【做眼】，来做活这块棋！',
  '現在應該【破眼】，來攻擊這塊棋！': '现在应该【破眼】，来攻击这块棋！',
  '這個【連接】很重要唷～': '这个【连接】很重要喔！',
  好棋: 'いい手',
  AI對弈: 'AI対局',
  好友對戰: '友達対局',
  直播講堂: '',
  找朋友: '友達対局',
  對AI: 'AI対局',
  如何使用平台: '',
  '通關AI對弈Lv.{aiLevel}': 'AI対局Lv.{aiLevel}合格',
  '學習進度星星達{count}顆': '学習進捗の星は{count}個達成',
  下列條件滿足後開放檢定: '以下の条件を達成した上で検定を実施します',
  '【檢定】改版說明': '',
  '【直播講堂】規則說明': '',
  '【AI對弈】改版說明': '',
  獲勝即解鎖下一關: '勝つと次のステージに挑戦できる',
  連贏: '',
  '次解鎖下一關(': '回連続で勝つと次のステージに挑戦できる(',
  達成: '検定',
  '檢定「通關AI對弈Lv.': '「AI対局Lv.',
  '」條件': '合格」条件達成',
  落點提示: '着手場所ヒント',
  '【落點提示】使用說明': '【着手場所ヒント】使用説明',
  '在對局中使用【落點提示】，AI將提供3個落點，其中一點會是最佳落點。落點提示功能每一局只能使用10次。':
    '対局中で【着手場所ヒント】を使用すると、AIは3つの石を置く場所を提供し、そのうちの1つが最適な場所になります。【石を置く場所ヒント】は1ゲームにつき10回までしか使用できません',
  查看正解: '正解確認',
  正解: '正解',
  回到問題手: '戻る',
  '切換{text}': '',
  切換程度: '',
  切換類型: '',
  切換老師: '',
  切換時間: '',
  今天: '',
  明天: '',
  本週: '',
  下週: '',
  分類: '',
  老師: '',
  時間: '',
  '已結束（可播放的課程）': '',
  全部: '',
  篩選條件: '',
  研修: '',
  段位班: '',
  '研修班（含進階班）': '',
  於: '',
  下架: '',
  程度: '',
  類型: '',
  您目前篩選的條件沒有資料: '',
  設定提醒失敗: '',
  '直播講堂到期日還有{day}天，請點此前往續費': '',
  您的直播講堂已到期: '',
  播放: '',
  此直播無法回放: '',
  不分齡: '',
  兒童: '',
  變化圖運算中: '変化図を演算中',
  變化圖: '変化図',
  規則說明: '',
  直播講堂列表: '',
  '對局已結束，可以算輸贏囉！':
    '対局終了！【勝ち負け結果】ボタンをクリックしてください',
  '檢定－下棋': '対局',
  邀請對局: '対局招待',
  '在黑嘉嘉圍棋教室等你一決高下～': '黒嘉嘉囲碁教室で勝負をしましょう〜',
  無讓子: 'コミ無し',
  直播時數: '',
  歷史紀錄: '',
  直播講堂到期日: '',
  直播講堂規則說明: '',
  '到期：直播講堂到期後，將無法觀看任何內容。': '',
  '計算方式：以每月30天，不分大小月的方式來計算。如有購買多堂課程或續購直播講堂方案，時數將自動累加延長。':
    '',
  'LIVE結束：每個直播講堂結束後將保留30天。若錯過喜歡的講堂，可以在直播列表的篩選器中，選擇時間的【已結束（可播放的課程）】分類，找到該講堂點擊【播放】觀看喔！':
    '',
  個月: '',
  贈送: '',
  '必修直播講堂（購買兒童啟蒙班）': '',
  '您還沒有購買任何課程，快來加入我們一起快樂學圍棋！': '',
  您可以前往官網購買課程: '',
  '需購買課程才能開放{type}': '{type}するためにはコースの購入が必要です',
  推播通知: 'プッシュ通知',
  编辑: '編集',
  刪除帳號: 'アカウント削除',
  開始試下: '',
  結束試下: '',
  '這盤棋沒有問題手喔！': '',
  功能: '機能',
  来源: '出所',
  這邊可以查看推播通知喔: 'プッシュ通知はこちらで確認できます',
  已經滑到底了: '',
  目前沒有推播通知: '現在、プッシュ通知はありません',
  取得推播通知錯誤: '',
  '已幫您切換至{name}課程': '',
  前往官網購買時數: '',
  購買直播講堂: '',
  購買: '',
  '【推播通知】功能說明': '',
  '貼心小提醒！': '',
  iOS設定方式: '',
  安卓設定方式: '',
  課後做題: '練習問題',
  '加入LINE@聯繫客服': '',
  '聯繫［黑嘉嘉圍棋教室］LINE客服刪除帳號': '',
  '試下（限教學）': '',
  登入失敗: 'ログインに失敗しました',
  學習歷程: '',
  今天休息: '',
  明天再加油: '',
  該名稱不得使用: '個人情報の変更に失敗しました',
  棋力: '棋力',
  立即前往: '',
  追求更棒的自己: '',
  當前形勢: '形勢判断',
  項目: '項目',
  數據記錄: '',
  棋盤座標: '',
  圍棋紀錄: '対局履歴',
  所有功能: 'その他',
  '嘉嘉小提醒：': '',
  '-最近一筆': '',
  目前沒有紀錄: '',
  虛手: '偽の手',
  對手虛手: '対戦相手の空の手',
  白: '白',
  '黑勝（黑{scoreText}子）': '黒勝（黒{scoreText}子）',
  '白勝（白{scoreText}子）': '白勝（白{scoreText}子）',
  '對方 Pass': '相手のパス',
  免費註冊: '無料新規登録',
  純棋官方網站: '純碁公式サイト',
  服務條款: '利用規約',
  隱私政策: 'プライバシーポリシー',
  '純棋 10分鐘學會下圍棋': '純碁 10分で覚えられる囲碁',
  '不分男女老少，大家都可以一起同樂': '老若男女問わない、みんなで楽しめる',
  可免費遊玩的益智遊戲: '無料で遊べる脳トレゲーム',
  '邊玩邊訓練大腦、保持智力活躍': '遊びながら認知症予防の脳トレ',
  '現在馬上開始學！': '今すぐ始めましょう！',
  '快速註冊 / 登入方式': 'ソーシャルアカウントでログイン',
  訪客帳號註冊: 'ゲストアカウントで登録',
  訪客帳號登入: 'ゲストアカウントでログイン',
  '還沒有帳號嗎？': 'アカウントをお持ちでないですか？',
  '訪客 ID': 'ログインID',
  選擇語言: '言語を選ぶ',
  不能少於8碼: '最低8文字以上である必要があります',
  密碼錯誤: 'パスワードが間違っています',
  請輸入密碼: 'パスワードを入力してください',
  '設定 8 位以上英數密碼': '半角英数字を含む8文字以上',
  請輸入ID: 'IDを入力してください',
  ID尚未註冊: 'IDはまだ登録されていません',
  您的帳號已被停用: 'アカウントが無効になりました',
  請輸入正確的ID: '正しいIDを入力してください',
  '伺服器發生錯誤，請稍後再試': 'サーバーエラー、後でもう一度',
  訪客註冊: 'ゲストとして登録',
  '已經持有訪客帳號了嗎？': 'すでにアカウントをお持ちですか？',
  terms: '{0} と {1}に同意の上、 登録してください。',
  '開始前我們建議您...': '始まる前に',
  下載截圖: 'スクリーンショットを撮る',
  略過此步驟: 'スキップ',
  '點擊下方【下載截圖】按鈕': 'スクリーンショットを撮るボタンを押すと、',
  '保存您的帳號及密碼。': 'アカウント情報を保存することができます',
  '恭喜你完成第 {index} 課': 'レッスン {index} 受講終了おめでとう',
};

export default lang;
