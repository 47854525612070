import store from '@/store';
import AppEvent from '@/lib/base/appEvent.js';

class SoundService {
  constructor() {
    const resourcesUrlMap = {
      soundEffects: {
        click: require('@/assets/sound/click.mp3'),
        correct: require('@/assets/sound/correct.mp3'),
        incorrect: require('@/assets/sound/incorrect.mp3'),
        win: require('@/assets/sound/win.mp3'),
        lose: require('@/assets/sound/lose.mp3'),
        play: require('@/assets/sound/play.mp3'),
        finishVerification: require('@/assets/sound/finish_verification.mp3'),
        receiveCertification: require('@/assets/sound/receive_certification.mp3'),
        messagePop: require('@/assets/sound/message_pop.mp3'),
        transition: require('@/assets/sound/transition.mp3'),
      },
      voices: {
        'black-last': require('@/assets/sound/number/black-last.mp3'),
        'white-last': require('@/assets/sound/number/white-last.mp3'),
        10: require('@/assets/sound/number/10.mp3'),
        9: require('@/assets/sound/number/09.mp3'),
        8: require('@/assets/sound/number/08.mp3'),
        7: require('@/assets/sound/number/07.mp3'),
        6: require('@/assets/sound/number/06.mp3'),
        5: require('@/assets/sound/number/05.mp3'),
        4: require('@/assets/sound/number/04.mp3'),
        3: require('@/assets/sound/number/03.mp3'),
        2: require('@/assets/sound/number/02.mp3'),
        1: require('@/assets/sound/number/01.mp3'),
      },
    };
    this.resourcesMap = {};

    /**
     * 載入聲音資源
     */
    Object.keys(resourcesUrlMap).forEach((soundsType) => {
      const _soundsType = resourcesUrlMap[soundsType];
      this.resourcesMap[soundsType] = {};
      Object.keys(_soundsType).forEach((key) => {
        const url = _soundsType[key];
        const sound = new Audio(url);
        this.resourcesMap[soundsType][key] = sound;
      });
    });
  }

  /**
   * 播放音效
   * @param {String} soundName 音效名稱
   * @param {Number} volume 音量大小
   * @returns {String} 音效描述文字
   */
  playSound(soundName, volume = 0.5) {
    const sound =
      this.resourcesMap.soundEffects[soundName] ||
      this.resourcesMap.voices[soundName];

    const soundEffectSetting =
      store.getters['user/userData']?.config?.soundEffectSetting || false;

    if (sound && soundEffectSetting && AppEvent.getAppStateActive()) {
      sound.volume = volume;
      sound.play();
    }
    return true;
  }

  /**
   * 停止音效
   */
  stopSound() {
    for (const soundsType in this.resourcesMap) {
      for (const key in this.resourcesMap[soundsType]) {
        const sound = this.resourcesMap[soundsType][key];
        sound.pause();
        sound.currentTime = 0;
      }
    }
  }
}

export default new SoundService();
