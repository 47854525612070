/* eslint-disable require-jsdoc */
import UAParser from 'ua-parser-js';

/**
 * 取得裝置類別與翻轉資訊
 * @returns {Object} 裝置類別與翻轉資訊
 */
function getDeviceInfo() {
  const parser = new UAParser();
  const userAgent = parser.getResult();

  const width = window.innerWidth;
  const height = window.innerHeight;
  let viewMode;

  const screenRatio = width / height;
  const orientation = screenRatio <= 1 ? 'portrait' : 'landscape';

  const webUaList = [
    'Windows',
    'CrOS x86',
    'Macintosh',
    'Linux x86',
    'Linux i686',
    'Linux amd64',
    'Linux x86_64',
  ];
  for (const str of webUaList) {
    if (navigator.userAgent.includes(str)) {
      viewMode = 'web';
      break;
    }
  }

  const minSize = Math.min(width, height);
  const maxSize = Math.max(width, height);
  if (viewMode !== 'web') {
    if (maxSize >= 768) {
      viewMode = 'pad';
    } else {
      viewMode = 'phone';
    }

    if (minSize <= 600) {
      viewMode = 'phone';
    }
  }

  let device = 'web';

  if (userAgent.os.name === 'Android') {
    device = 'android';
  } else if (userAgent.os.name === 'iOS') {
    device = 'ios';
  }

  // detection for iPad since iPadOS user-agent is same as macintosh
  const iPad =
    userAgent.ua.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' &&
      navigator.maxTouchPoints > 1); /* iPad OS 13 */

  if (iPad) {
    device = 'ios';
    viewMode = 'pad';
  }

  return {
    viewMode,
    device,
    screenRatio,
    orientation,
    width,
    height,
  };
}

export default getDeviceInfo;
