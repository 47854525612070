import aiGame from '@/services/aiGame';
import pvp from '@/services/pvp';

const state = {
  gameStatistics: {},
  aiLevel: 1,
  sgf: null,
  userColor: 'black',
  boardSize: 9,
  capCount: {black: 0, white: 0},
  opponentName: null,
  createdTime: null,
  overGameCreatedTime: null,
  hasUnfinishedGame: false,
  kifu: null,
  kifus: null,
  pvpLastKey: null,
  pveLastKey: null,
  style: '',
  kifusAmount: 20,
  kifusType: [
    'practice',
    'challenge',
    'study',
    'pvp',
    'verification',
    'hjjCourse',
  ],
  kifuReviewGame: null,
  id: null,
  aiId: null,
  supportCount: 0,
  coursePlan: null,
};

const getters = {
  gameStatistics(state) {
    return state.gameStatistics;
  },
  hasUnfinishedGame(state) {
    return state.hasUnfinishedGame;
  },
};

const actions = {
  getGameStatistics({commit}) {
    return aiGame.getGameStatistics().then((response) => {
      commit('setGameUserData', response);
    });
  },
  async getKifus({commit}, username) {
    const result = await aiGame.getKifus(
      state.kifusAmount,
      username,
      state.kifusType
    );

    const kifus = result.data.kifuList;
    let pveLastKey = state.pveLastKey;
    let pvpLastKey = state.pvpLastKey;
    if (kifus.length !== 0) {
      if (result.data.pveLastKey) {
        pveLastKey = result.data.pveLastKey;
      }
      if (result.data.pvpLastKey) {
        pvpLastKey = result.data.pvpLastKey;
      }
    }

    commit('setKifus', {
      kifus,
      pveLastKey,
      pvpLastKey,
      first: true,
    });
  },
  createPracticeGame(
    {commit},
    {userColor, aiLevel, boardSize, style, opponentName, coursePlan, aiId}
  ) {
    return aiGame
      .createPracticeGame(
        userColor,
        aiLevel,
        boardSize,
        style,
        opponentName,
        coursePlan,
        aiId
      )
      .then((result) => {
        if (style) commit('setStyle', style);
        result.coursePlan = coursePlan;
        commit('setGameData', result);
        commit('setHasUnfinishedGame', false);
        return result;
      });
  },
  createCourseGame({commit}, {courseUniqueKey, coursePlan}) {
    return aiGame
      .createCourseGame(courseUniqueKey, coursePlan)
      .then((result) => {
        commit('setGameData', result);
        return result;
      });
  },
  createVerificationGame({commit}, gameData) {
    return aiGame
      .createVerificationGame(
        gameData.rank,
        gameData.verificationId,
        gameData.gameId,
        gameData.coursePlan
      )
      .then((result) => {
        commit('setGameData', result);
        return result;
      });
  },
  getUnfinishedGame({commit}) {
    return aiGame.getUnfinishedGame().then((result) => {
      if (result) {
        commit('setGameData', result);
        commit('setHasUnfinishedGame', true);
        return result;
      } else {
        commit('resetGameData');
        commit('setHasUnfinishedGame', false);
        return result;
      }
    });
  },
  getUnfinishedCourseGame({commit}, {courseUniqueKey}) {
    return aiGame.getUnfinishedCourseGame(courseUniqueKey).then((result) => {
      if (result) {
        commit('setGameData', result);
        commit('setHasUnfinishedGame', true);
        return result;
      } else {
        commit('resetGameData');
        commit('setHasUnfinishedGame', false);
        return result;
      }
    });
  },
  getUnfinishedVerificationGame({commit}, gameData) {
    return aiGame
      .getUnfinishedVerificationGame(gameData.verificationId, gameData.gameId)
      .then((result) => {
        if (result) {
          commit('setGameData', result);
          commit('setHasUnfinishedGame', true);
          return result;
        } else {
          commit('resetGameData');
          commit('setHasUnfinishedGame', false);
          return result;
        }
      });
  },
  updateGame({state}, {move}) {
    return aiGame.updateGame(state.createdTime, move);
  },
  nextMove({state}) {
    return aiGame.nextMove(state.createdTime);
  },
  getJudgement({state}) {
    return aiGame.getJudgement(state.createdTime);
  },
  confirmJudgement({state}) {
    return aiGame.confirmJudgement(state.createdTime);
  },
  resign({state, commit}) {
    return aiGame.resign(state.createdTime).then((result) => {
      commit('setHasUnfinishedGame', false);
      return result;
    });
  },
  getKifu({commit}, {createdTime, gameMode}) {
    if (gameMode === 'pvp') {
      return pvp.getPvpKifu(createdTime).then((result) => {
        commit('setKifuData', result);
      });
    } else {
      return aiGame.getKifu(createdTime).then((result) => {
        commit('setKifuData', result);
      });
    }
  },
};
const mutations = {
  setGameUserData(state, value) {
    state.gameStatistics = value;
  },
  overGameData(state) {
    state.overGameCreatedTime = state.createdTime;
    state.createdTime = null;
    state.sgf = null;
    state.supportCount = 0;
  },
  setGameData(state, game) {
    if (game) {
      state.sgf = game.sgf;
      state.userColor = game.userColor;
      state.opponentName = game.opponentName;
      state.createdTime = game.createdTime;
      state.aiLevel = game.aiLevel;
      state.boardSize = game.boardSize;
      state.id = game.id;
      state.aiId = game.aiId;
      state.supportCount = game.supportCount;
      state.coursePlan = game.coursePlan;
    }
  },
  resetGameData(state) {
    state.sgf = null;
    state.userColor = 'black';
    state.opponentName = null;
    state.createdTime = null;
    state.aiLevel = 1;
    state.boardSize = 9;
    state.style = '';
    state.id = null;
    state.aiId = null;
    state.supportCount = 0;
    state.coursePlan = null;
  },
  setStyle(state, style) {
    state.style = style;
  },
  setUserColor(state, color) {
    state.userColor = color > 0 ? 'black' : 'white'; // 1 or -1;
  },
  setSgf(state, sgf) {
    if (state.createdTime) {
      state.sgf = sgf;
    }
  },
  setCapCount(state, capCount) {
    state.capCount = capCount;
  },
  setBoardSize(state, size) {
    state.boardSize = size;
  },
  setHasUnfinishedGame(state, value) {
    state.hasUnfinishedGame = value;
  },
  setKifuData(state, kifu) {
    state.kifu = kifu;
  },
  resetKifuData(state) {
    state.kifu = null;
  },
  setKifuIsReviewed(state) {
    state.kifu.isReviewed = true;
  },
  setKifuReviewGame(state, kifuReviewGame) {
    state.kifuReviewGame = kifuReviewGame;
  },
  setKifus(state, {kifus, pveLastKey, pvpLastKey, first}) {
    if (first) {
      state.kifus = kifus;
    } else {
      state.kifus = [...state.kifus, ...kifus];
    }
    state.pveLastKey = pveLastKey;
    state.pvpLastKey = pvpLastKey;
  },
  setKifuAmount(state, data) {
    state.kifusAmount = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
