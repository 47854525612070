import {callLambda} from '@/lib/api/lambda';

/**
 * 取得課程資料
 * @param {String} planId 課程方案 ID
 * @param {String} isAll 顯示所有課程
 * @returns {Array} Array
 */
function getCourses(planId, isAll) {
  const apiName = isAll
    ? `courses?coursePlan=${planId}&isAll=${isAll}`
    : `courses?coursePlan=${planId}`;
  return callLambda({
    category: 'hjjCourse',
    method: 'get',
    apiName,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得課程方案
 * @param {String} username 帳號
 * @returns {Array} Array
 */
function getCoursePlans(username) {
  return callLambda({
    category: 'hjjCourse',
    apiName: 'coursePlans',
    method: 'get',
    params: {
      username,
    },
  }).then((res) => {
    return res.data;
  });
}

export default {
  getCourses,
  getCoursePlans,
};
