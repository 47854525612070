// hjj-jungo prod
const url = {
  lambda: {
    /* 專案後端api */
    academy:
      'https://jxp3dfizj3.execute-api.ap-northeast-2.amazonaws.com/prod/academy/',
    academy2:
      'https://e8heghngd9.execute-api.ap-northeast-2.amazonaws.com/prod/academy2/',
    aiCourse:
      'https://wlhh32xmj5.execute-api.ap-northeast-2.amazonaws.com/prod/aiCourse/',
    aiGame:
      'https://ljctktxqhd.execute-api.ap-northeast-2.amazonaws.com/prod/aiGame/',
    announcement:
      'https://o4suyg88u3.execute-api.ap-northeast-2.amazonaws.com/prod/announcement/',
    courseware:
      'https://g8pwtb0yp2.execute-api.ap-northeast-2.amazonaws.com/prod/courseware/',
    coursewareSgf:
      'https://g8pwtb0yp2.execute-api.ap-northeast-2.amazonaws.com/prod/online-crouse/',
    notification:
      'https://452z68f9c0.execute-api.ap-northeast-2.amazonaws.com/prod/notification/',
    tsumeGame:
      'https://6q7eas9g1b.execute-api.ap-northeast-2.amazonaws.com/prod/tsumeGame/',
    user: 'https://kv6onbxqah.execute-api.ap-northeast-2.amazonaws.com/prod/user/',
    password:
      'https://kv6onbxqah.execute-api.ap-northeast-2.amazonaws.com/prod/password/',
    form: '',
    socket: 'wss://10mc1wxej3.execute-api.ap-northeast-2.amazonaws.com/prod/',
    capsule:
      'https://jc60uoqa8d.execute-api.ap-northeast-2.amazonaws.com/prod/capsule/',
    maintenance:
      'https://7eumc2s8o4.execute-api.ap-northeast-2.amazonaws.com/prod/maintenance/',
    advertisement:
      'https://f3gcrpbue1.execute-api.ap-northeast-2.amazonaws.com/prod/advertisement/',
    backstage:
      'https://ihq40hzgs7.execute-api.ap-northeast-2.amazonaws.com/prod/backstage/',
    pvp: 'https://6fb8gtbrh3.execute-api.ap-northeast-2.amazonaws.com/prod/pvp/',
    homework:
      'https://4c8fofoskb.execute-api.ap-northeast-2.amazonaws.com/prod/',
    liveCourse:
      'https://ho9ay2qxp6.execute-api.ap-northeast-2.amazonaws.com/prod/liveCourse/',
    verification:
      'https://cqjoa2p44c.execute-api.ap-northeast-2.amazonaws.com/prod/verification/',
    hjjCourse:
      'https://q75nokmnt9.execute-api.ap-northeast-2.amazonaws.com/prod/hjjCourse/',
    multimediaClassroom:
      'https://khr4865vf7.execute-api.ap-northeast-2.amazonaws.com/prod/multimediaClassroom/',

    /* 共用、分階段api */
    leelaLZanalysis:
      'https://4qxzqdync3.execute-api.ap-northeast-1.amazonaws.com/prod/3in1-LV2/',

    /* 共用、不分階段api */
    newAiAnalysisOld:
      'https://6puqyzreh2.execute-api.ap-northeast-1.amazonaws.com/prod/',
    influence:
      'https://plohssfdy3.execute-api.ap-northeast-1.amazonaws.com/prod/',
    leelaAnalysis:
      'https://cwjfr09ae3.execute-api.ap-southeast-1.amazonaws.com/prod/',
    goRobot:
      'https://ypm5dyymk9.execute-api.ap-southeast-1.amazonaws.com/prod/lambda_bot/',
  },
  cdn: 'https://s3.ap-northeast-1.amazonaws.com/hei-jia-jia-jungo.weiqi.study-cdn/',
  backstage:
    'http://backstage.hjj-jungo.weiqi.study.s3-website.ap-northeast-2.amazonaws.com/',
  homepage:
    'http://hjj-jungo.weiqi.study.s3-website.ap-northeast-2.amazonaws.com/',
};

export default url;
