<template>
  <div
    class="loading text-white position-absolute d-flex flex-column align-items-center justify-content-center"
  >
    <b-spinner class="spinner"></b-spinner>
    <div class="loading-text mt-3">{{ $t('載入中') }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.loading {
  width: 130px;
  height: 130px;
  border-radius: 10px;
  background: $grayscale-30;
  z-index: 1050;
  top: calc(50% - 65px);
  left: calc(50% - 65px);
  .spinner {
    width: 48px;
    height: 48px;
  }
}
</style>
