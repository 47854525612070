import tsumeGame from '@/services/tsumeGame';

const state = {
  tsumeStatistics: {},
  verificationTsume: {},
  practiceTsumeList: null,
};

const getters = {
  tsumeStatistics(state) {
    return state.tsumeStatistics;
  },
  verificationTsume(state) {
    return state.verificationTsume;
  },
  practiceTsumeList(state) {
    return state.practiceTsumeList;
  },
};

const actions = {
  getTsumeStatistics({commit}) {
    return tsumeGame.getTsumeStatistics().then((response) => {
      commit('setTsumeUserData', response);
    });
  },
  getPracticeTsumeList({commit}, currentPlanId) {
    return tsumeGame.getTsumePracticeList(currentPlanId).then((tsumeList) => {
      commit('setPracticeTsumeList', tsumeList);
    });
  },
};

const mutations = {
  setTsumeUserData(state, value) {
    state.tsumeStatistics = value;
  },
  setVerificationTsume(state, tsume) {
    state.verificationTsume = tsume;
  },
  setPracticeTsumeList(state, tsumeList) {
    state.practiceTsumeList = tsumeList;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
