<template>
  <b-sidebar
    id="sidebar-backdrop"
    backdrop
    bg-variant="bgcontainer"
    no-header
    right
    :visible="visible"
    @hidden="$emit('close')"
  >
    <div
      class="switch-course w-100 h-100 d-flex flex-column justify-content-between"
      @click.stop=""
    >
      <div class="m-0 sub-title font-weight-bold text-center">
        {{ $t('切換課程') }}
        <i
          class="icon-Circle-X text-primary text-24 position-absolute"
          @click="$emit('close')"
        ></i>
      </div>
      <div
        class="course-container overflow-overlay scroll-bar d-flex flex-column justify-content-between align-items-center"
      >
        <div class="course-accordion w-100" role="tablist">
          <template v-for="type in courseTypes">
            <CourseTypeCollapse
              :key="type.type"
              :type="type"
              :courses="plans"
              @click-type="clickType"
              @change-course="changeCourse"
            ></CourseTypeCollapse>
          </template>
        </div>
      </div>
      <!-- todo: 10/25更新尚未有課程地圖，先做隱藏 -->
      <!-- <div class="wrap-btn-map bg-white">
      <b-button
        pill
        variant="primary"
        size="md"
        class="d-flex align-items-center mx-auto"
      >
        <i class="icon-Elert-info text-20 mr-1"></i>
        {{ $t('課程地圖') }}
      </b-button>
    </div> -->
    </div>
  </b-sidebar>
</template>

<script>
import CourseTypeCollapse from '@/components/Base/CourseTypeCollapse';
import {getLocalData, saveLocalData} from '@/lib/base/localData';
import BackEvent from '@/lib/base/backEvent.js';

export default {
  components: {
    CourseTypeCollapse,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      courseTypes: [
        {type: 'isAdult', title: '不分齡課程', show: true},
        {type: 'isKid', title: '兒童課程', show: true},
        {type: 'isTopic', title: '技術專題', show: true},
        {type: 'isNFT', title: 'NFT專屬課程', show: true},
      ],
    };
  },
  computed: {
    plans() {
      return this.$store.getters['course/plans'] || [];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  created() {
    BackEvent.addEvent(() => {
      this.$emit('close');
    });
    const courseTypesStatus = getLocalData('course-types-status');
    if (courseTypesStatus) {
      this.courseTypes = JSON.parse(courseTypesStatus);
    }
  },
  methods: {
    clickType(type) {
      type.show = !type.show;
      saveLocalData('course-types-status', JSON.stringify(this.courseTypes));
    },
    changeCourse(course) {
      if (course.disable) {
        return;
      }
      this.$store.dispatch('env/changePlanInit', course);
      this.$emit('close');
    },
    visibleChange() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .b-sidebar-backdrop {
  background: $grayscale-20 !important;
}
/deep/ .b-sidebar {
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0;
}
.switch-course {
  max-width: 320px;
  background: $bgcontainer;
  height: 100%;
  /deep/ .btn-link {
    padding: 0;
    text-decoration: none;
  }
  @media screen and (min-width: 321px) {
    max-width: calc(100vw - 55px);
  }
  @media screen and (min-width: 768px) {
    max-width: 375px;
  }
  .sub-title {
    height: 56px;
    line-height: 56px;
    position: relative;
    .icon-Circle-X {
      right: 12px;
      top: 12px;
      font-size: 32px;
      cursor: pointer;
    }
  }
  .course-container {
    padding: 0 16px;
    height: 100%;
  }
  .wrap-btn-map {
    padding: 8px 0;
  }
}
</style>
