/* eslint-disable require-jsdoc */
import {callLambda} from '@/lib/api/lambda';

/**
 * 取得推播列表
 * @param {number} limit 筆數
 * @returns {Promise} promise
 */
async function getNotifications(limit) {
  const res = await callLambda({
    category: 'notification',
    method: 'GET',
    apiName: 'list',
    params: {
      limit,
    },
  });
  return res.data;
}

/**
 * 更新推播列表
 * @param {number} lastReadNotificationTime 最後一筆推播通知的時間
 * @returns {Promise} promise
 */
async function updateNotifications(lastReadNotificationTime) {
  const res = await callLambda({
    category: 'notification',
    method: 'PUT',
    apiName: 'setLastReadTime',
    data: {
      lastReadNotificationTime,
    },
  });
  return res.data;
}

/**
 * 更新優惠券推播彈窗
 * @param {String} sk 推播通知的id
 * @param {Boolean} hasModalShown 是否已跳出過modal
 * @returns {Promise} promise
 */
async function setNotificationModal({sk, hasModalShown}) {
  const res = await callLambda({
    category: 'notification',
    method: 'PUT',
    apiName: 'setHasModalShown',
    data: {
      sk,
      hasModalShown,
    },
  });
  return res.data;
}

export default {
  getNotifications,
  updateNotifications,
  setNotificationModal,
};
