import courseService from '@/services/course';
import {getLocalData, saveLocalData} from '@/lib/base/localData';

/**
 * @returns {Object} initialize state
 */
const initialState = () => {
  return {
    courses: null,
    echelon: null,
    plans: null,
    anchorDay: null,
    currentPlan: {},
  };
};

const state = initialState();

const getters = {
  currentCourses(state) {
    return state.courses;
  },
  echelon(state) {
    return state.echelon;
  },
  currentPlan(state) {
    return state.currentPlan;
  },
  plans(state) {
    return state.plans;
  },
  noPlans(state) {
    return state.plans?.length === 0;
  },
  isCurrentPlan(state) {
    return (planId) => {
      return state.currentPlan.id === planId;
    };
  },
  anchorDay(state) {
    return state.anchorDay;
  },
};

const actions = {
  getCourses({commit}, currentPlanId, isAll = true) {
    return courseService.getCourses(currentPlanId, isAll).then((res) => {
      commit('setCourses', res.courses);
      commit('setEchelon', res.echelon);
    });
  },
  getPlans({commit}, username) {
    return courseService.getCoursePlans(username).then((plans) => {
      const localPlanInfo = JSON.parse(getLocalData('plan-info'));

      let planInfo = plans.find((plan) => {
        return (
          localPlanInfo?.id === plan.id &&
          !['trial-01', 'kidtrial-02'].includes(plan.id)
        );
      });

      if (!planInfo) {
        const filterPlan = plans.find(
          (plan) => !['trial-01', 'kidtrial-02'].includes(plan.id)
        );

        planInfo = filterPlan || plans?.[0] || {};
      }

      commit('setPlans', plans);
      commit('setCurrentPlan', planInfo);
      saveLocalData('plan-info', JSON.stringify(planInfo));
    });
  },
};

const mutations = {
  setCourses(state, courses) {
    state.courses = courses;
  },
  setEchelon(state, echelon) {
    state.echelon = echelon;
  },
  setPlans(state, plans) {
    state.plans = plans;
  },
  setCurrentPlan(state, plan) {
    state.currentPlan = plan;
  },
  setAnchorDay(state, day) {
    state.anchorDay = day;
  },
  resetState(state) {
    const _state = initialState();
    Object.assign(state, _state);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
