import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';

/**
 * 取得檢定資料
 * @param {String} planId 課程方案 ID
 * @returns {Promise} promise
 */
function getVerification(planId) {
  return callLambda({
    category: 'verification',
    method: 'get',
    apiName: `?coursePlan=${planId}`,
  }).then((res) => {
    return res.data;
  });
}

/**
 * 開始檢定
 * @param {Number} rank 棋力
 * @param {String} planId 課程方案 ID
 * @returns {Promise} promise
 */
function startVerification(rank, planId) {
  return callLambda({
    category: 'verification',
    apiName: 'start',
    method: 'post',
    data: {
      rank,
      coursePlan: planId,
    },
  }).then((res) => {
    return res.data;
  });
}

/**
 * 結束目前檢定
 * @param {Number} rank 棋力
 * @param {String} id 證書編號
 * @param {String} planId 課程方案 ID
 * @returns {Promise} promise
 */
function postOver(rank, id, planId) {
  return callLambda({
    category: 'verification',
    apiName: 'over',
    method: 'post',
    data: {
      rank,
      id,
      coursePlan: planId,
    },
  }).then((res) => {
    getTotalScores?.cache.clear();
    getRecords?.cache.clear();
    return res.data;
  });
}

/**
 * 開始檢定做題
 * @param {Number} rank 棋力
 * @param {String} id 檢定id
 * @param {String} type 做題類型
 * @returns {Promise} promise
 */
function startTsume(rank, id, type) {
  return callLambda({
    category: 'verification',
    apiName: 'startTsume',
    method: 'post',
    data: {
      rank,
      id,
      type,
    },
  }).then((res) => {
    return res.data.overTime;
  });
}

/**
 * 領取證書
 * @param {Number} rank 棋力
 * @param {String} id 檢定編號
 * @param {String} planId 課程方案 ID
 * @returns {Promise} promise
 */
function getCertification(rank, id, planId) {
  return callLambda({
    category: 'verification',
    apiName: 'cert',
    method: 'get',
    params: {
      rank,
      id,
      coursePlan: planId,
    },
  }).then((res) => {
    return res.data;
  });
}

/**
 * 取得所有檢定紀錄
 * @returns {Promise} promise
 */
const getTotalScores = _.memoize(function getTotalScores() {
  return callLambda({
    category: 'verification',
    apiName: 'scores',
    method: 'get',
  }).then((res) => {
    return res.data;
  });
});

/**
 * 取得所有成長紀錄
 * @returns {Promise} promise
 */
const getRecords = _.memoize(function getRecords() {
  return callLambda({
    category: 'verification',
    apiName: 'record',
    method: 'get',
  }).then((res) => {
    return res.data;
  });
});

/**
 * 儲存詰棋檢定的紀錄
 * @param {Number} rank 檢定等級
 * @param {String} tsumeId 題目id
 * @param {String} id 檢定id
 * @param {String} type 題目類型
 * @param {Boolean} isCorrect 對錯
 * @param {String} userAnswer 使用者答案
 * @returns {Promise} promise
 */
function updateTsumeVerificate(rank, tsumeId, id, type, isCorrect, userAnswer) {
  const data = {
    rank,
    tsumeId,
    id,
    type,
    isCorrect,
    userAnswer,
  };
  return callLambda({
    category: 'verification',
    apiName: 'tsume',
    method: 'post',
    data,
  });
}

/**
 * 取得檢定開放條件
 * @param {String} planId 課程方案 ID
 * @param {String} configType 條件類型 enum.practice
 * @returns {Promise} promise
 */
function getConditions(planId, configType) {
  return callLambda({
    category: 'verification',
    apiName: `conditions?coursePlan=${planId}&configType=${configType}`,
    method: 'get',
  }).then((res) => {
    return res.data;
  });
}

export default {
  getVerification,
  startVerification,
  getTotalScores,
  getCertification,
  postOver,
  getRecords,
  updateTsumeVerificate,
  startTsume,
  getConditions,
};
