import axios from 'axios';

/**
 * 取得最新版本
 */
const getNewestVersion = async () => {
  const url = `${window.location.origin}/version.json?${new Date().getTime()}`;
  const version = (await axios.get(url)).data;
  return version;
};

/**
 * 判斷是否有新版本
 * @param {String} version version
 * @returns {Boolean} 是否有新版本
 */
const isHaveNewVersion = async (version) => {
  const newVersion = await getNewestVersion();
  if (version && !newVersion) return false;
  if (!version && newVersion) return true;
  const originVersionArray = version.split('.');
  const newestVersionArray = newVersion.split('.');
  const maxLength = Math.max(
    originVersionArray.length,
    newestVersionArray.length
  );
  for (let i = 0; i < maxLength; i++) {
    const versionReq = /([0-9]+)([a-zA-Z]*)/;
    const [, originVersionNumber, originVersionABC] =
      originVersionArray[i].match(versionReq);
    const [, newVersionNumber, newVersionABC] =
      newestVersionArray[i].match(versionReq);
    const delta = Number(newVersionNumber) - Number(originVersionNumber);
    if (delta > 0) return true;
    if (delta < 0) return false;
    if (originVersionABC === '' && newVersionABC) return true;
    if (originVersionABC && newVersionABC === '') return false;
    if (originVersionABC !== newVersionABC) {
      newVersionABC.charCodeAt() - originVersionABC.charCodeAt();
    }
    if (i === maxLength - 1) return false;
  }
};

/**
 * 確認版本
 */
const checkVersion = async () => {
  try {
    const clientVersion = process.env.VUE_APP_GIT_TAG;
    const isHaveNew = await isHaveNewVersion(clientVersion);
    if (isHaveNew) {
      window.location.reload();
    }
  } catch (_) {
    console.log('Can not check version');
  }
};

export {checkVersion};

checkVersion();
