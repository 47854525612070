import i18n from '@/i18n/index.js';

const ERROR = {
  'test message': i18n.t('測試訊息'),
  NO_PVP_LOBBY: 'The lobby is not exist!',
  LOBBY_OWNER_DISCONNECTED: 'The lobby owner is disconnected!',
  GAME_IS_JUDGING: 'Game is judging!',
  OPPONENT_DISCONNECTED: 'Opponent is disconnected!',
  UNFINISHED_GAME: 'The user still has an unfinished game!',
  SELF_CREATED_LOBBY: 'Can not enter self-created lobby!',
  INCORRECT_ACTION: 'Incorrect action!',
};

export default ERROR;
