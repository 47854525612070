<template>
  <div class="wrap-system-update d-flex h-100 flex-column">
    <base-header :has-logo="true" :is-title-bold="true"></base-header>
    <div
      class="system-update-container d-flex flex-column align-items-center justify-content-center w-100 flex-fill"
    >
      <img
        :srcset="require('@/assets/img/app-updates.png?srcset')"
        class="img-system-updates"
      />
      <div class="content text-center">
        <div class="text-28 text-font-grayscale-2 font-weight-bold">
          {{ $t('系統維護中') }}
        </div>
        <div class="text-font-grayscale-2">
          {{ $t('我們正進行系統升級與維護，造成不便敬請見諒。') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    BaseHeader: () => import('@/components/Base/BaseHeader'),
  },
  computed: {
    systemState() {
      return this.$store.getters['maintenance/systemState'];
    },
  },
  watch: {
    systemState: {
      immediate: true,
      handler(newValue) {
        if (!newValue) this.$router.push({name: 'home'});
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-system-update {
  .img-system-updates {
    width: 250px;
    @media screen and (min-width: 768px) {
      width: 540px;
    }
  }
  .content {
    width: 280px;
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }
}
</style>
