<template>
  <div class="course-type-list mb-3">
    <div
      aria-controls="course"
      class="tab-type text-18 font-weight-bold py-1 text-center d-flex align-items-center justify-content-center"
      :class="type.show ? null : 'collapsed'"
      role="tab"
      @click="clickType(type)"
    >
      {{ $t(type.title) }}
      <b-icon
        icon="Chevron-up"
        font-scale="0.8"
        class="ml-1"
        :rotate="type.show ? 180 : 0"
      ></b-icon>
    </div>
    <b-collapse id="course" v-model="type.show" role="tabpanel">
      <div class="tab-courses">
        <template v-for="course in courses">
          <div
            v-if="course[type.type]"
            :key="course.id"
            href="#"
            class="course-item d-flex align-items-center justify-content-between"
            :class="{disabled: isCourseDisabled(course)}"
            @click.stop="changeCourse(course)"
          >
            {{ course.name }}
            <span v-if="isCourseDisabled(course)"
              >（{{ $t('尚未開課') }}）
            </span>
            <i
              v-if="course.id === currentPlan.id"
              class="icon-Check text-accent text-24"
            ></i>
          </div>
        </template>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Object,
      default() {
        return {};
      },
    },
    courses: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  methods: {
    isCourseDisabled(course) {
      return course.startedAt - Date.now() > 0;
    },
    clickType(type) {
      this.$emit('click-type', type);
    },
    changeCourse(course) {
      this.$emit('change-course', course);
    },
  },
};
</script>

<style lang="scss" scoped>
.course-type-list {
  box-shadow: 0px 2px 7px 0px #c7c7c74d;
  border-radius: 10px;
  background: $line-2;
}
.tab-type {
  background: $line-2;
  border-radius: 10px;
  line-height: 30px;
  color: $font-grayscale-1;
}
.tab-courses {
  background: white;
  border-radius: 0px 0px 10px 10px;
  .course-item {
    padding: 12px 16px;
    &:not(:last-of-type) {
      border-bottom: 1px solid #dcdfe5;
    }
  }
}
</style>
