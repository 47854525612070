import AppPlugin from '../base/appPlugin';
import {Dialog} from '@capacitor/dialog';

class RecordPlugin extends AppPlugin {
  constructor() {
    super('Record');
    this.onRecordEvent = null;
    this.title = null;
    this.message = null;
  }

  setAlertText({title, message}) {
    this.title = title;
    this.message = message;
  }
  async enable() {
    if (this.onRecordEvent && this.onRecordEvent.remove) {
      this.onRecordEvent.remove();
      this.onRecordEvent = null;
    }
    if (await this.checkIsHavePlugin()) {
      await this.capacitorPlugin.enable();
    }
  }
  async disable() {
    if (!this.onRecordEvent) {
      this.initIosRecordEvent();
    }
    if (await this.checkIsHavePlugin()) {
      await this.capacitorPlugin.disable();
    }
  }

  async getIsRecording() {
    if (await this.checkIsHavePlugin()) {
      const result = await this.capacitorPlugin.getIsRecording();
      return result && result.isRecording;
    }
    return false;
  }

  async initIosRecordEvent() {
    if (await this.checkIsHavePlugin()) {
      let isShowRecordAlert = false;
      this.onRecordEvent = this.capacitorPlugin.addListener(
        'onRecord',
        async () => {
          if (!isShowRecordAlert) {
            isShowRecordAlert = true;
            await Dialog.alert({
              title: this.title,
              message: this.message,
            });
            isShowRecordAlert = false;
          }
        }
      );
    }
  }
}

export default RecordPlugin;
