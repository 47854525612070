import Vue from 'vue';

Vue.directive('click-outside', {
  bind(element, binding, vnode) {
    element.clickOutsideEvent = function (event) {
      if (!(element === event.target || element.contains(event.target))) {
        vnode.context[binding.expression]();
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent);
  },
  unbind(element) {
    document.body.removeEventListener('click', element.clickOutsideEvent);
  },
});
