<template>
  <div id="app" class="h-100">
    <!-- <div
      class="back-btn position-absolute"
      style="bottom: 0; right: 0; z-index: 999999"
    >
      {{ BackEvent.backEventBus }}
      android測試按鈕
    </div> -->
    <data-loading v-if="isLoading"></data-loading>
    <PassAnimation
      v-if="isPassAnimation && viewMode === 'phone'"
      :leave="isLeave"
      :mode="passAnimationMode"
      @animation-end="destoryPassAnimation"
    ></PassAnimation>

    <Sidebar v-if="isLogin && !$isJungo"></Sidebar>

    <div
      class="h-100 overflow-hidden d-flex"
      :class="$device.isMobile ? 'flex-column' : 'flex-row-reverse'"
    >
      <main>
        <transition :name="$device.isMobile ? transitionName : ''">
          <keep-alive :include="['TsumeRoom', 'GameRoom', 'Pvp']" max="1">
            <router-view></router-view>
          </keep-alive>
        </transition>
      </main>
      <navigation />
      <system-info></system-info>
    </div>
    <!-- <BaseAiCourse /> -->
    <Offline />

    <modal-confirm
      v-model="isRedirectModalShow"
      :hide-cancel="true"
      :no-close="true"
      @confirm="
        isRedirectModalShow = false;
        $router.push('/');
      "
    >
      {{ $t('此頁面無法重複開啟，按確定後自動跳至首頁') }}
    </modal-confirm>
    <modal-confirm
      v-model="isLogoutModalShow"
      :hide-cancel="true"
      :no-close="true"
      @confirm="
        isLogoutModalShow = false;
        logout();
      "
    >
      {{ $t('已有其他使用者登入') }}
    </modal-confirm>
    <modal-confirm
      v-model="isLogoutOtherModalShow"
      :confirm-text="$t('登入其他帳號')"
      :cancel-text="$t('將他人登出')"
      :no-close="true"
      @confirm="
        isLogoutOtherModalShow = false;
        logout();
      "
      @cancel="
        isLogoutOtherModalShow = false;
        logoutOtherAccount();
      "
    >
      {{ $t('已有其他使用者登入本帳號') }}
    </modal-confirm>
    <modal-confirm
      v-model="isNewAppModalShow"
      :hide-cancel="true"
      :no-close="true"
      @confirm="openAppStore"
    >
      <!-- {{ $t('有新版本，請至商店下載更新') }} -->
      有新版本，請至商店下載更新
    </modal-confirm>
    <modal-confirm
      :value="isNftComingSoonShow"
      :confirm-text="$t('知道了')"
      :hide-cancel="true"
      :has-btn-close="true"
      @confirm="$store.commit('env/setIsNftComingSoonShow', false)"
    >
      {{ modalNftText }}
    </modal-confirm>
    <landscape-reminder v-if="!$device.isMobile"></landscape-reminder>
    <SwitchCourse
      :visible="isSwitchCourseSheetShow"
      @close="closeSwitchCourseSheet"
    ></SwitchCourse>
    <ModalLanguage v-if="isChangeLanguageModalShow"></ModalLanguage>
  </div>
</template>

<script>
import watchMixin from '@/mixin/watch';
import Navigation from './components/Base/Navigation.vue';
// import BaseAiCourse from './components/Base/BaseAiCourse.vue';
import SystemInfo from '@/components/SystemInfo';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import {getLocalData} from '@/lib/base/localData';
import {manifest, appConfigs} from '@/constant/env.js';
import DataLoading from '@/components/Base/DataLoading.vue';
import PassAnimation from '@/components/Base/PassAnimation.vue';
import Sidebar from '@/components/Base/Sidebar';
import Offline from '@/components/Base/Offline.vue';
import delay from '@/lib/base/delay.js';
import {App} from '@capacitor/app';
import {Toast} from '@capacitor/toast';
import {Device} from '@capacitor/device';
import socket from '@/lib/socket/socket.js';
import SoundService from '@/lib/base/soundService';
import AppEvent from '@/lib/base/appEvent.js';
import BackEvent from '@/lib/base/backEvent.js';
import {debounce} from 'lodash';
import fbTrack from '@/lib/base/fbTrack';
import ModalLanguage from '@/components/Base/ModalLanguage.vue';
import SwitchCourse from '@/components/Base/SwitchCourse';

export default {
  metaInfo: {
    title: manifest.name,
  },
  components: {
    Navigation,
    // BaseAiCourse,
    SystemInfo,
    DataLoading,
    PassAnimation,
    Offline,
    ModalConfirm,
    ModalLanguage,
    SwitchCourse,
    Sidebar,
    LandscapeReminder: () => import('@/components/Base/LandscapeReminder'),
  },
  mixins: [watchMixin],
  data() {
    return {
      isRedirectModalShow: false, // 分頁重複開啟modal
      isLogoutModalShow: false, // 提示被登出的modal
      isLogoutOtherModalShow: false, // 詢問是否踢掉其他登入者的modal
      isNewAppModalShow: false, // 提示有新的 app 要去更新
      appStoreUrl: '',
      defaultTransition: 'fade',
      transitionName: 'fade',
      isPassAnimation: false,
      passAnimationMode: 'game',
      isLeave: false,
      lastBack: 0,
      socket,
      checkPvpWatcher: null, // 檢查是否有對弈中人人對弈的vue watcher
      now: new Date().getTime(),
      BackEvent,
    };
  },
  provide() {
    return {
      appNow: () => this.now,
    };
  },
  computed: {
    viewMode() {
      return this.$store.state.env.viewMode;
    },
    screenHeight() {
      return this.$store.state.env.height;
    },
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    isLogin() {
      return this.$store.getters['user/isLogin'];
    },
    plans() {
      return this.$store.getters['course/plans'] || [];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    sheetHeight() {
      const sheetHeight = 50 * this.plans.length + 56;
      if (sheetHeight > this.screenHeight - 100) {
        return 'calc(100% - 100px)';
      }
      return sheetHeight + 'px';
    },
    isSwitchCourseSheetShow() {
      return this.$store.state.env.isSwitchCourseSheetShow;
    },
    isNftComingSoonShow() {
      return this.$store.state.env.isNftComingSoonShow;
    },
    nftPlans() {
      return this.$store.state.env.nftPlans;
    },
    isChangeLanguageModalShow() {
      return this.$store.state.env.isChangeLanguageModalShow;
    },
    currentLanguage() {
      return this.$store.state.env.currentLanguage;
    },
    modalNftText() {
      const hasPlan = this.plans.find((plan) => {
        return (
          this.nftPlans.includes(plan.id) &&
          this.$day(plan.startedAt).isBefore(this.$day('2022-10-25 23:59'))
        );
      });
      if (hasPlan) {
        return '恭喜您符合NFT領取資格！預計於 11/11 發放盲盒，敬請期待～';
      } else {
        return '您還不符合NFT領取資格，在 2022/10/25 23:59 前購買指定課程，就能免費獲得NFT喔！';
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (
          socket.isInit &&
          this.$route.name !== 'roomSetting' &&
          this.$route.name !== socket.router
        ) {
          socket.update({router: this.$route.name});
        }
      },
    },
    isLogin: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          if (this.checkPvpWatcher) {
            this.checkPvpWatcher();
          }
          this.checkPvpWatcher = this.waitUntil(
            {
              '$route.name': {
                $exists: true,
              },
              'socket.isInit': true,
            },
            () => {
              this.checkPvpUnfinishedGame();
            }
          );
          this.initialSocket();
          if (this.isLogin) {
            this.$store.dispatch('notification/getNotifications', 50);
          }
        }
      },
    },
  },
  async created() {
    fbTrack('PageView');
    this.scheduleUpdateNow();
    const deviceInfo = await Device.getInfo();
    window.isApp = deviceInfo.platform !== 'web';
    let appInfo = null;
    try {
      appInfo = await App.getInfo();
    } catch (error) {
      appInfo = {build: 0};
    }
    const appConfig = appConfigs?.[deviceInfo.platform];
    this.appStoreUrl = appConfig?.store;
    if (appConfig && appInfo.build < appConfig.build) {
      this.isNewAppModalShow = true;
    }

    if (deviceInfo.platform === 'android') {
      App.addListener('backButton', this.backButtonHandler);
    }

    if (deviceInfo.platform === 'web') {
      window.addEventListener(
        'resize',
        debounce(this.handleWindowResize, 20, {leading: true})
      );
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener(
          'resize',
          debounce(this.handleWindowResize, 20, {leading: true})
        );
      });
    }

    // android按鈕測試用
    // document
    //   .querySelector('.back-btn')
    //   .addEventListener('click', this.backButtonHandler);
    if (deviceInfo.platform === 'ios' || deviceInfo.platform === 'android') {
      window.addEventListener('orientationchange', () => {
        this.handleWindowResize();
      });
      window.addEventListener(
        'resize',
        debounce(this.handleWindowResize, 20, {leading: true})
      );
    }

    this.disableRecord();
    this.handleWindowResize();

    if (this.$device.isMobile) {
      this.$router.beforeEach(async (to, from, next) => {
        if (to.name === 'roomSetting' || from.name === 'roomSetting') {
          this.transitionName = to.fullPath.includes('roomSetting')
            ? 'slide-left'
            : 'slide-right';
          next();
          return;
        }
        const fromUpperTransitionType = to.meta.fromUpperTransitionType;
        const toUpperTransitionType = from.meta.toUpperTransitionType;
        const toPathLength = to.path.split('/').length;
        const fromPathLength = from.path.split('/').length;
        const transitionType =
          toPathLength >= fromPathLength
            ? fromUpperTransitionType
            : toUpperTransitionType;
        if (
          to.meta.transitionDepth != null &&
          from.meta.transitionDepth != null
        ) {
          if (to.meta.hasPassAnimation) {
            this.transitionName = this.defaultTransition;
          } else {
            this.transitionName =
              to.meta.transitionDepth < from.meta.transitionDepth
                ? 'slide-right'
                : 'slide-left';
          }
          next();
        } else if (transitionType === 'slide') {
          this.transitionName =
            toPathLength < fromPathLength ? 'slide-right' : 'slide-left';
          next();
        } else if (
          transitionType === 'passAnimation' &&
          this.$device.isMobile
        ) {
          this.passAnimationMode = to.meta.passAnimationMode;
          if (toPathLength >= fromPathLength) {
            this.isPassAnimation = true;
            await delay(1000);
            next();
            if (this.passAnimationMode === 'tsume') await delay(2000);
            this.isLeave = true;
          }
        } else {
          this.transitionName = this.defaultTransition;
          next();
        }
      });
    }

    if (getLocalData('jwt')) {
      const userLocalCache = getLocalData('user');
      if (userLocalCache) {
        this.$store.commit('user/setLogin', JSON.parse(userLocalCache));
      }
    }
    if (getLocalData('language')) {
      this.$store.dispatch('env/changeLanguage', getLocalData('language'));
    }
    App.addListener('appStateChange', async (state) => {
      const key = Object.keys(state)[0];
      const value = Object.values(state)[0];
      if (key === 'isActive' && value === false) {
        SoundService.stopSound();
        AppEvent.setAppStateActive(false);
      } else {
        AppEvent.setAppStateActive(true);
        const isSystemUpdate = await this.$store.dispatch(
          'maintenance/getSystemState'
        );
        if (isSystemUpdate) {
          this.$router.push({
            name: 'systemUpdate',
          });
        }
      }
    });
  },
  async beforeDestroy() {
    await App.removeAllListeners();
  },
  methods: {
    async handleWindowResize() {
      if (document.activeElement.nodeName === 'INPUT') return;
      await this.$store.dispatch('env/init');
      if (this.viewMode === 'phone') {
        if (this.screenHeight >= 500) {
          document.body.style.height = `${this.screenHeight}px`;
        } else {
          setTimeout(this.handleWindowResize, 100);
        }
      }
    },
    disableRecord() {
      this.$RecordPlugin.setAlertText({
        title: this.$t('提醒'),
        message: this.$t('關閉螢幕錄影才能繼續使用'),
      });
      // this.$RecordPlugin.disable();
    },
    destoryPassAnimation() {
      this.isPassAnimation = false;
      this.isLeave = false;
    },
    async backButtonHandler() {
      if (BackEvent.backEventBus.length !== 0) {
        BackEvent.emit();
        return;
      } else if (this.$route?.meta.depth === 1) {
        if (Date.now() - this.lastBack < 2000) {
          App.exitApp();
        } else {
          await Toast.show({
            text: this.$t('再按一次離開應用程式'),
          });
          this.lastBack = Date.now();
        }
      } else {
        window.history.back();
      }
    },
    initialSocket() {
      const jwt = getLocalData('jwt');

      if (jwt && !socket.isInit) {
        socket.init(jwt, this.$route.name);
        if (this.$route.name == 'home') {
          this.urlToPvpRoom();
        }
        socket.onAskIfLogoutOtherAccount(() => {
          this.isLogoutOtherModalShow = true;
        });
        socket.onLogout(() => {
          this.$nextTick(() => {
            this.logout();
            this.isLogoutModalShow = true;
          });
        });
        socket.onRedirectRouter(() => {
          this.isRedirectModalShow = true;
        });
      }
      // 監聽產品是否更新中
      socket.onUpdateEnv((result) => {
        const data = JSON.parse(result.message).isUnderMaintenance;
        this.$store.commit('maintenance/setMaintenance', data);
        if (data) {
          this.$router.push({name: 'systemUpdate'});
        } else {
          this.$router.push({name: 'home'});
        }
      });
    },
    logout() {
      this.$store.dispatch('user/logout');
    },
    logoutOtherAccount() {
      socket.sendLogoutOtherAccount();
    },
    urlToPvpRoom() {
      if (this.$route.query.pvp) {
        this.$store.commit('env/setIsLoading', true);
        socket.send({
          action: 'pvp',
          pvpAction: socket.SendEventEnum.PVP_ENTER_LOBBY,
          data: {
            lobbyId: this.$route.query.pvp,
          },
        });
        socket.once(socket.ResponseEventEnum.PVP_GAME_STARTED, () => {
          this.$store.commit('env/setIsLoading', false);
          this.$router.push('pvp');
        });
      }
    },
    checkPvpUnfinishedGame() {
      if (socket.isInit && !['pvp', 'pvp-wait'].includes(this.$route.name)) {
        socket.once(socket.ResponseEventEnum.PVP_EXIST_PVP_GAME, (result) => {
          const data = result.data;
          if (data) {
            this.$router.push('/pvp');
          }
        });
        socket.send({
          action: 'pvp',
          pvpAction: socket.SendEventEnum.PVP_GET_UNFINISHED_GAME,
        });
      }
    },
    scheduleUpdateNow() {
      if (!this.timmer) {
        this.timmer = setInterval(() => {
          this.now = new Date().getTime();
        }, 1000);
      }
    },
    openAppStore() {
      window.open(this.appStoreUrl);
    },
    dateFormat(plan) {
      return this.$day(plan.startedAt).format('YYYY-MM-DD');
    },
    async closeSwitchCourseSheet() {
      BackEvent.popEvent();
      this.$store.commit('env/setIsSwitchCourseSheetShow', false);
    },
  },
};
</script>

<style lang="scss">
html {
  font-size: 16px;
}
body {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.sheet-option {
  height: 50px;
  &.disabled {
    color: $font-grayscale-3;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $line-2;
  }
  .icon-Check {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 32px;
    color: $primary;
  }
}

// DEFAULT_TRANSITION
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
// SLIDE_TRANSITION
main {
  display: grid;
  grid-template: 'main';
  flex: 1;
  position: relative;
  overflow-x: hidden;
  z-index: 6;
  background-color: $bgcontainer;
}

main > * {
  grid-area: main; /* Transition: make sections overlap on same cell */
  // flex: 1 1 auto;
  background-color: $bgcontainer;
  position: relative;
  // height: 100%; /* To be fixed */
}

main > :first-child {
  z-index: 9; /* Prevent flickering on first frame when transition classes not added yet */
}

.slide-left-leave-to {
  animation: delayHide 0.01s 300ms forwards;
  animation: leaveToLeft 300ms both ease;
  z-index: 6;
}

.slide-left-enter-to {
  animation: delayHide 0.01s forwards;
  animation: enterFromRight 300ms both ease;
  z-index: 12;
}

.slide-right-leave-to {
  animation: delayHide 0.01s forwards;
  animation: leaveToRight 300ms both ease;
  z-index: 12;
}

.slide-right-enter-to {
  animation: delayHide 0.01s 300ms forwards;
  animation: enterFromLeft 300ms both ease;
  z-index: 6;
}
@keyframes delayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes delayHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes leaveToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
    filter: brightness(0.5);
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-50%);
    filter: brightness(0.5);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes leaveToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes enterFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
