import i18n from '@/i18n/index.js';
import dayjs from 'dayjs';

/**
 * @param {Date} date date
 * @param {String} format date format
 * @returns {String} formatted date
 */
const dateFormat = (date, format = 'YYYY/MM/DD') => dayjs(date).format(format);

/**
 * convert winningWay
 * @param {String} winningWay the winningWay from database
 * @param {Number} komi komi
 * @param {Number} boardSize gameMode
 * @returns {String} the winningWayText show to user
 */
const winningWayText = (winningWay, komi, boardSize) => {
  let winner = '';
  if (winningWay === '' || winningWay == null) {
    return i18n.t('對局中');
  }
  if (winningWay === 'Draw') {
    return '和局';
  }
  if (winningWay === 'B+C') {
    return i18n.t('黑勝');
  }
  if (winningWay === 'W+C') {
    return i18n.t('白勝');
  }
  if (winningWay === 'B+T') {
    return i18n.t('白超時負');
  }
  if (winningWay === 'W+T') {
    return i18n.t('黑超時負');
  }
  if (winningWay.substring(0, 2) === 'B+') {
    winner = 'black';
  } else if (winningWay.substring(0, 2) === 'W+') {
    winner = 'white';
  } else {
    return winningWay;
  }

  const latterPart = winningWay.substring(2);
  if (latterPart === 'R') {
    return winner == 'black' ? i18n.t('黑中盤勝') : i18n.t('白中盤勝');
  } else {
    if (!komi || !boardSize) {
      return winner == 'black' ? i18n.t('黑勝') : i18n.t('白勝');
    }

    const isBlackWin = winner == 'black';
    const result = Number(winningWay.substring(2));
    const komiNum = Number(isBlackWin ? komi / 2 : -(komi / 2));
    const scoreText = result + komiNum + (boardSize * boardSize) / 2;

    /* eslint-disable indent */
    return winner == 'black'
      ? i18n.t('黑勝（黑{scoreText}子）', {
          scoreText,
        })
      : i18n.t('白勝（白{scoreText}子）', {
          scoreText,
        });
  }
};

export default {
  winningWayText,
  dateFormat,
};
