/* eslint-disable require-jsdoc */
import Vue from 'vue';
import getDeviceInfo from '@/lib/base/getDeviceInfo';

/**
 * @return {Ojbect} - helpers to detect user device details
 */
const deviceHelper = () => {
  const deviceDetails = getDeviceInfo();
  const {
    viewMode,
    device,
    isElectron,
    isPwa,
    isWeChatMp,
    isFlutterApp,
  } = deviceDetails;
  return {
    isDesktop: viewMode === 'web',
    isTablet: viewMode === 'pad',
    isMobile: viewMode === 'phone',
    isAndroid: device === 'android',
    isIos: device === 'ios',
    isElectron,
    isPwa,
    isWeChatMp,
    isFlutterApp,
  };
};

Vue.prototype.$device = deviceHelper();

export {deviceHelper};
