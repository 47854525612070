import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';
import {removeLocalData} from '@/lib/base/localData';

/**
 * 取得使用者資料
 * @returns {Promise} promise
 */
const getUserData = _.memoize(function getUserData() {
  return callLambda({
    category: 'user',
    apiName: '',
    method: 'get',
  }).then((res) => {
    res.data.profileUrl = `${
      res.data.profileUrl
    }?timestamp=${new Date().getTime()}`;
    return res.data;
  });
});

/**
 * 登入
 * @param {String} username 帳號
 * @param {String} password 密碼
 * @returns {Promise} promise
 */
function login(username, password) {
  return callLambda({
    category: 'user',
    apiName: 'login',
    method: 'post',
    data: {
      username,
      password,
    },
  });
}

/**
 * 第三方登入
 * @param {String} token token
 * @param {String} platform 平台
 * @returns {Promise} promise
 */
function thirdPartyLogin({token, platform, options = {}}) {
  return callLambda({
    category: 'user',
    apiName: 'thirdPartyLogin',
    method: 'post',
    data: {token, platform, options},
  });
}

/**
 * 登出
 * @returns {Promise} promise
 */
function logout() {
  return callLambda({
    category: 'user',
    apiName: 'logout',
    method: 'post',
  });
}

/**
 * 更新使用者資訊
 * @param {Object} user 要更新的user資料
 * @returns {Promise} promise
 */
function updateUser(user) {
  return callLambda({
    category: 'user',
    method: 'put',
    apiName: '',
    data: user,
  }).then((res) => {
    removeLocalData('user');
    getUserData?.cache.clear();
    return res.data;
  });
}

/**
 * 更改使用者密碼
 * @param {String} oldPassword 舊密碼
 * @param {String} newPassword 新密碼
 * @returns {Promise} promise
 */
function changePassword({oldPassword, newPassword}) {
  return callLambda({
    category: 'user',
    apiName: 'changePassword',
    method: 'post',
    data: {
      oldPassword,
      newPassword,
    },
  }).then((res) => {
    return res.data;
  });
}

/**
 * 重設使用者密碼
 * @param {String} password 密碼
 * @param {String} verificationId 驗證碼ID
 * @param {String} verificationCode 驗證碼
 * @param {Boolean} isEmail 是否是email發驗證信
 * @returns {Promise} promise
 */
function resetPassword({password, verificationId, verificationCode, isEmail}) {
  return callLambda({
    category: 'user',
    apiName: 'resetPasswordByOtp',
    method: 'post',
    data: {
      password,
      verificationId,
      verificationCode,
      isEmail,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 發送忘記密碼之驗證碼
 * @param {String} internationalPhoneNumber 手機號碼
 * @param {String} email 電子信箱
 * @param {String} redirect 驗證信轉址
 * @returns {Promise} promise
 */
function sendResetPasswordOtp({internationalPhoneNumber, email, redirect}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'sendResetPasswordOtp',
    data: {
      internationalPhoneNumber,
      email,
      redirect,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證忘記密碼之驗證碼
 * @param {String} username 帳號
 * @param {String} verificationId 驗證碼ID
 * @param {String} verificationCode 驗證碼
 * @returns {Promise} promise
 */
function validateResetPasswordOtp({
  username,
  verificationId,
  verificationCode,
}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'validateResetPasswordOtp',
    data: {
      username,
      verificationId,
      verificationCode,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 發送註冊之驗證碼
 * @param {String} internationalPhoneNumber 手機號碼
 * @returns {Promise} promise
 */
function sendSignupOpt({internationalPhoneNumber}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'sendSignupOtp',
    data: {
      internationalPhoneNumber,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證註冊之驗證碼
 * @param {String} username 使用者分類
 * @param {String} verificationId 驗證碼ID
 * @param {String} verificationCode 驗證碼
 * @returns {Promise} promise
 */
function validateSignupOpt({username, verificationId, verificationCode}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'validateSignupOtp',
    data: {
      username,
      verificationId,
      verificationCode,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 註冊
 * @param {String} username 使用者分類
 * @param {String} academyId 班級ID
 * @param {String} password 使用者密碼
 * @param {String} realName 使用者真名
 * @param {String} nickName 使用者暱稱
 * @returns {Promise} promise
 */
function signup({username, academyId, password, phone, realName, nickName}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'signup',
    data: {
      username,
      academyId,
      password,
      phone,
      realName,
      nickName,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 啟用序號
 * @param {String} code 班級ID
 * @param {String} academyId 班級ID
 * @returns {Promise} promise
 */
function activateByCode({code, academyId}) {
  return callLambda({
    category: 'user',
    method: 'post',
    apiName: 'activateByCode',
    data: {code, academyId},
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得使用者下棋資訊
 * @returns {Promise} promise
 */
function getUserStatistics() {
  return callLambda({
    category: 'user',
    apiName: 'statistics',
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得使用者消費記錄
 * @returns {Promise} promise
 */
function getOwnPurchaseLog() {
  return callLambda({
    category: 'user',
    method: 'get',
    apiName: 'getOwnPurchaseLog',
  });
}

/**
 * 取得使用者學習歷程
 * @param {Object} params 查詢資料
 * @param {String} params.username 使用者名稱
 * @param {Number} params.limit 資料筆數
 * @param {Number} params.startedAt 學習記錄起始時間
 * @param {Number} params.endedAt 學習記錄結束時間
 * @param {Boolean} params.isAscending 最新紀錄
 * @returns {Promise} promise
 */
function getLearningProcess(params) {
  return callLambda({
    category: 'user',
    method: 'get',
    apiName: 'getLearningProcess',
    params,
  });
}

/**
 * 寄送email註冊驗證信
 * @param {String} email 使用者email
 * @param {String} academyId 班級ID
 * @param {String} [password] 使用者密碼
 * @param {String} [realName] 使用者真名
 * @param {String} [nickName] 使用者暱稱
 * @param {String} redirect 轉址
 * @returns {Promise} promise
 */
function sendSignupOtpEmail({
  email,
  academyId,
  password,
  realName,
  nickName,
  redirect,
}) {
  return callLambda({
    category: 'user',
    apiName: 'sendSignupOtpEmail',
    method: 'post',
    data: {
      email,
      academyId,
      password,
      realName,
      nickName,
      redirect,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證email註冊信
 * @param {String} id 後端回傳網址夾帶id
 * @param {String} code 後端回傳網址夾帶code
 * @returns {Promise} promise
 */
function validateSignupOtpEmail(id, code) {
  return callLambda({
    category: 'user',
    apiName: 'validateSignupOtpEmail',
    method: 'post',
    data: {
      id,
      code,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 綁定信箱/手機發送驗證碼或驗證信
 * @param {String} internationalPhoneNumber 手機號碼（含區碼）
 * @param {String} email 信箱
 * @param {String} redirect 後端回傳網址夾帶code
 * @param {String} type [EMAIL_SET || PHONE_SET]
 * @returns {Promise} promise
 */
function bindAccountSendOtp({internationalPhoneNumber, email, redirect, type}) {
  return callLambda({
    category: 'user',
    apiName: 'sendOtp',
    method: 'post',
    data: {
      internationalPhoneNumber,
      email,
      redirect,
      type,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證信箱驗證信/手機驗證碼
 * @param {String} verificationId 驗證碼id
 * @param {String} verificationCode 驗證碼code
 * @param {String} type [EMAIL_SET || PHONE_SET]
 * @returns {Promise} promise
 */
function bindAccountValidateOtp({verificationId, verificationCode, type}) {
  return callLambda({
    category: 'user',
    apiName: 'validateOtp',
    method: 'post',
    data: {
      verificationId,
      verificationCode,
      type,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 驗證完成並綁定新的 username
 * @param {String} verificationId 驗證碼id
 * @param {String} verificationCode 驗證碼code
 * @param {String} type [EMAIL_SET || PHONE_SET]
 * @returns {Promise} promise
 */
function setUsernameByOtp({verificationId, verificationCode, type}) {
  return callLambda({
    category: 'user',
    apiName: 'setUsernameByOtp',
    method: 'post',
    data: {
      verificationId,
      verificationCode,
      type,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得 nft 列表
 * @returns {Promise} promise
 */
async function getNfts() {
  const result = await callLambda({
    category: 'user',
    apiName: 'nfts',
    method: 'get',
  });
  return result.data;
}

/**
 * 取得 nft
 * @param {Object} uuid uuid
 * @returns {Promise} promise
 */
async function getNft(uuid) {
  const result = await callLambda({
    category: 'user',
    apiName: `nft/${uuid}`,
    method: 'get',
  });
  return result.data;
}

/**
 * 驗證信箱驗證信/手機驗證碼
 * @param {String} type 驗證碼id
 * @param {String} content 驗證碼code
 * @param {Array} images 圖片 -> aws sdk上傳至s3 中的 key
 * @returns {Promise} promise
 */
function createUserReport({type, content, images}) {
  return callLambda({
    category: 'user',
    apiName: 'createUserReport',
    method: 'post',
    data: {
      type,
      content,
      images,
    },
  }).then((result) => {
    return result.data;
  });
}

export default {
  getUserData,
  login,
  thirdPartyLogin,
  logout,
  updateUser,
  changePassword,
  resetPassword,
  sendResetPasswordOtp,
  validateResetPasswordOtp,
  sendSignupOpt,
  validateSignupOpt,
  signup,
  activateByCode,
  getUserStatistics,
  getOwnPurchaseLog,
  getLearningProcess,
  sendSignupOtpEmail,
  validateSignupOtpEmail,
  getNfts,
  getNft,
  bindAccountSendOtp,
  bindAccountValidateOtp,
  setUsernameByOtp,
  createUserReport,
};
