import maintenanceService from '@/services/maintenance';

const state = {
  systemState: null,
};

const getters = {
  systemState(state) {
    return state.systemState;
  },
};

const actions = {
  async getSystemState({commit}) {
    const result = await maintenanceService.getSystemState();
    commit('setMaintenance', result);
    return result;
  },
};

const mutations = {
  setMaintenance(state, data) {
    state.systemState = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
