import Vue from 'vue';
import VueRouter from 'vue-router';
import {Device} from '@capacitor/device';
import {getLocalData} from '@/lib/base/localData';
import store from '@/store/index.js';
import {SplashScreen} from '@capacitor/splash-screen';
import delay from '@/lib/base/delay';
import getDeviceInfo from '@/lib/base/getDeviceInfo';
import SystemUpdate from '@/views/SystemUpdate.vue';

Vue.use(VueRouter);
const deviceDetails = getDeviceInfo();
const {viewMode} = deviceDetails;
const isMobile = viewMode === 'phone';

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import('@/views/Login/Introduction.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
      depth: 1,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/Login/Signup.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/guest-login-detail',
    name: 'guest-login-detail',
    component: () => import('@/views/Login/GuestLoginDetail.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/signup-status',
    name: 'signup-status',
    component: () => import('@/views/Login/SignupStatus.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
      depth: 1,
    },
    beforeEnter(from, to, next) {
      const hasIntroShow = getLocalData('hasIntroShow');
      if (hasIntroShow == null) {
        return next({
          name: 'intro',
          query: {redirect: to.fullPath},
        });
      }

      next();
    },
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: () => import('@/views/Login/ForgetPassword.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('@/views/TermsOfService.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/PrivacyPolicy.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/Login/ResetPassword.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: false,
    },
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('@/views/Notification/NotificationList.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: false,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/serial-number',
    name: 'serial-number',
    component: () => import('@/views/Login/SerialNumber.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
      nonLimitPage: true,
    },
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('@/views/Home.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
      nonLimitPage: true,
    },
  },
  {
    path: '/ai-course/:aiCourseId/course-uniqueKey/:courseUniqueKey/day/:day',
    name: 'hjj-ai-course',
    component: () => import('@/views/AiCourse/index.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/practice',
    name: 'practice',
    component: () => import('@/views/Practice/index.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
    },
  },
  {
    path: '/verification',
    name: 'verification',
    component: () => import('@/views/Verification/Verification.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      depth: 1,
    },
  },
  {
    path: '/verification/past-scores',
    name: 'past-scores',
    component: () => import('@/views/Verification/PastScores.vue'),
    meta: {
      hasNavigation: isMobile ? false : true,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/verification/pass-scores/:scoreId',
    name: 'score',
    component: () => import('@/views/Verification/Score.vue'),
    meta: {
      hasNavigation: isMobile ? false : true,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
    },
  },
  {
    path: '/practice/pvp-home',
    name: 'pvp-home',
    component: () => import('@/views/Pvp/PvpHome.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      transitionDepth: 1,
    },
  },
  {
    path: '/pvp-menu',
    name: 'pvp-menu',
    component: () => import('@/views/Pvp/PvpMenu.vue'),
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      requireAuth: true,
      transitionDepth: 2,
    },
  },
  {
    path: '/pvp-wait/:id',
    name: 'pvp-wait',
    component: () => import('@/views/Pvp/PvpWait.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      transitionDepth: 3,
    },
  },
  {
    path: '/pvp-join/:id',
    name: 'pvp-join',
    component: () => import('@/views/Pvp/PvpJoin.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
    },
  },
  {
    path: '/pvp',
    name: 'pvp',
    component: () => import('@/views/Pvp/Pvp.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      depth: 1,
      transitionDepth: 4,
      hasPassAnimation: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('@/views/Personal/Personal.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
      nonLimitPage: true,
    },
    children: [
      {
        path: '/web-statistics',
        name: 'web-statistics',
        component: () => import('@/views/Personal/Statistics.vue'),
        meta: {
          hasNavigation: true,
          requireAuth: true,
        },
      },
      {
        path: '/web-certificate',
        name: 'web-certificate',
        component: () => import('@/views/Personal/Certificate.vue'),
        meta: {
          hasNavigation: true,
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: '/personal/edit',
    name: 'personal-edit',
    component: () => import('@/views/Personal/InfoEdit.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/edit/nickname',
    name: 'nickname',
    component: () => import('@/views/Personal/NickName.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/edit/avatar',
    name: 'avatar',
    component: () => import('@/views/Personal/Avatar.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/learning-process',
    name: 'learning-process',
    component: () => import('@/views/Personal/LearningProcess.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/statistics',
    name: 'statistics',
    component: () => import('@/views/Personal/Statistics.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/growth',
    name: 'growth',
    component: () => import('@/views/Personal/GrowthRecord.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/contact',
    name: 'contact',
    component: () => import('@/views/Personal/Contact.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/coupon',
    name: 'coupon',
    component: () => import('@/views/Personal/Coupon.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/course-status',
    name: 'course-status',
    component: () => import('@/views/Personal/CourseStatus.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/certificate',
    name: 'certificate',
    component: () => import('@/views/Personal/Certificate.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/kifus',
    name: 'kifus',
    component: () => import('@/views/Kifu/Kifus.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/live-course-record',
    name: 'live-course-record',
    component: () => import('@/views/Personal/LiveCourseRecords.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/kifu/:createdTime/:gameMode',
    name: 'kifu-preview',
    component: () => import('@/views/Kifu/KifuPreview.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/personal/setting',
    name: 'setting',
    component: () => import('@/views/Personal/Setting.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
      transitionDepth: 2,
    },
  },
  {
    path: '/personal/setting/password',
    name: 'password',
    component: () => import('@/views/Personal/Password.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/academy',
    name: 'academy',
    component: () => import('@/components/Personal/Academy.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/bindEmail',
    name: 'bindEmail',
    component: () => import('@/views/Personal/BindEmail.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/bindStatus',
    name: 'bindStatus',
    component: () => import('@/views/Personal/BindStatus.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/bindMobile',
    name: 'bindMobile',
    component: () => import('@/views/Personal/BindMobile.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
      transitionDepth: 3,
    },
  },
  {
    path: '/personal/setting/removeAccount',
    name: 'removeAccount',
    component: () => import('@/views/Personal/RemoveAccount.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/personal/nfts',
    name: 'nfts',
    component: () => import('@/views/Personal/Nfts.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/personal/nft/:uuid',
    name: 'nft',
    component: () => import('@/views/Personal/Nft.vue'),
    meta: {
      hasNavigation: !isMobile,
      requireAuth: true,
    },
  },
  {
    path: '/practice/gamelist',
    name: 'gamelist',
    component: () => import('@/views/Practice/AiGame.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
    },
  },
  {
    path: '/practice/tsumelist',
    name: 'tsumelist',
    component: () => import('@/views/Practice/TsumePracticeList.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
    },
  },
  {
    path: '/roomSetting',
    name: 'roomSetting',
    component: () => import('@/views/Personal/RoomSetting.vue'),
    meta: {
      hasNavigation: false,
      requireAuth: true,
      fromUpperTransitionType: 'slide',
      toUpperTransitionType: 'slide',
      nonLimitPage: true,
    },
  },
  {
    path: '/practice/game',
    name: 'practiceGame',
    component: () => import('@/views/Room/GameRoom.vue'),
    props: {mode: 'practiceGame'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'game',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/course/game/:courseUniqueKey',
    name: 'courseGame',
    component: () => import('@/views/Room/GameRoom.vue'),
    props: {mode: 'courseGame'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'game',
      requireAuth: true,
      chessboardRoom: true,
      nonLimitPage: true,
    },
  },
  {
    path: '/verification/game/:verificationId/:gameId',
    name: 'verificationGame',
    component: () => import('@/views/Room/GameRoom.vue'),
    props: {mode: 'verificationGame'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'game',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/practice/tsume/ranks/:rank/types/:type',
    name: 'practiceTsume',
    component: () => import('@/views/Room/TsumeRoom.vue'),
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'tsume',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/course/tsume/:courseUniqueKey/types/:type',
    name: 'courseTsume',
    component: () => import('@/views/Room/TsumeRoom.vue'),
    props: {mode: 'courseTsume'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'tsume',
      requireAuth: true,
      chessboardRoom: true,
      nonLimitPage: true,
    },
  },
  {
    path: '/verification/tsume/id/:id/types/:type/overTime/:overTime',
    name: 'verificationTsume',
    component: () => import('@/views/Room/TsumeRoom.vue'),
    props: {mode: 'verificationTsume'},
    meta: {
      hasNavigation: false,
      fromUpperTransitionType: 'passAnimation',
      toUpperTransitionType: 'slide',
      passAnimationMode: 'tsume',
      requireAuth: true,
      chessboardRoom: true,
    },
  },
  {
    path: '/live/list',
    name: 'liveList',
    component: () => import('@/views/Live/LiveList.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      depth: 1,
      nonLimitPage: true,
    },
  },
  {
    path: '/no-lesson/:type',
    name: 'no-lesson',
    component: () => import('@/views/NoLesson.vue'),
    meta: {
      hasNavigation: true,
      requireAuth: true,
      nonLimitPage: true,
    },
  },
  {
    path: '/systemUpdate',
    name: 'systemUpdate',
    component: SystemUpdate,
    meta: {
      hasNavigation: false,
      requireAuth: false,
      nonLimitPage: true,
    },
  },
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (window.isApp == null) {
    const deviceInfo = await Device.getInfo();
    window.isApp = deviceInfo.platform !== 'web';
  }
  const token = getLocalData('jwt');
  const isSystemUpdate = await store.dispatch('maintenance/getSystemState');
  if (isSystemUpdate && to.name !== 'systemUpdate') {
    return next({
      name: 'systemUpdate',
    });
  }
  if (token) {
    if (store.getters['user/userData'].currentPlan == null) {
      await store.dispatch('user/loginWithJwt');
    }

    if (store.getters['course/plans'] == null) {
      await store.dispatch(
        'course/getPlans',
        store.getters['user/userData'].username
      );
    }

    const permission = store.getters['env/permission'];
    const hasUnfinishedGame = store.getters['aiGame/hasUnfinishedGame'];
    // user logged in, prevent navigation to login page
    if (['login', 'signup'].includes(to.name)) {
      return next({
        name: 'home',
      });
      // user didn't buy any course, redirect to intro page
    } else if (
      (((['gamelist'].includes(to.name) &&
        !permission?.aiGame &&
        !hasUnfinishedGame) ||
        (['tsumelist'].includes(to.name) && !permission?.tsume)) &&
        isMobile) ||
      (['verification'].includes(to.name) && !permission?.verification)
    ) {
      return next({
        name: 'no-lesson',
        params: {type: to.name},
      });
    } else if (
      [
        'practice',
        'pvp-home',
        'pvp-menu',
        'pvp-wait',
        'gamelist',
        'tsumelist',
      ].includes(to.name) &&
      !isMobile
    ) {
      return next({
        name: 'home',
      });
    } else {
      next();
    }
  } else {
    return next();
  }
});

router.afterEach(() => {
  const token = getLocalData('jwt');
  if (token) {
    delay(500);
    SplashScreen.hide();
  } else {
    window.addEventListener('load', () => {
      SplashScreen.hide();
    });
  }
  store.commit('env/setIsLoading', false);
});

export default router;
