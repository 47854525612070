import {callLambda} from '@/lib/api/lambda';

/**
 * 取得產品現在是否正在更新中
 * @param {String} code 棋院代號
 * @returns {Array} Array
 */
function getSystemState() {
  return callLambda({
    category: 'maintenance',
    method: 'get',
    apiName: 'state',
  }).then((res) => {
    return res.data;
  });
}

export default {
  getSystemState,
};
