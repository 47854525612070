import rankList from '@/json/rank.json';

const aiLevel2UserRankMapping = [
  {aiLevel: 10, userRank: 0, captureAiLevel: 1001},
  {aiLevel: 20, userRank: 1, captureAiLevel: 1010},
  {aiLevel: 25, userRank: 2, captureAiLevel: 1020},
  {aiLevel: 30, userRank: 3, captureAiLevel: 1030},
  {aiLevel: 35, userRank: 4, captureAiLevel: 1040},
  {aiLevel: 40, userRank: 5, captureAiLevel: 1050},
  {aiLevel: 48, userRank: 6},
  {aiLevel: 56, userRank: 7},
  {aiLevel: 64, userRank: 8},
  {aiLevel: 72, userRank: 9},
  {aiLevel: 80, userRank: 10},
  {aiLevel: 90, userRank: 11},
  {aiLevel: 100, userRank: 12},
  {aiLevel: 110, userRank: 13},
  {aiLevel: 120, userRank: 14},
  {aiLevel: 130, userRank: 15},
  {aiLevel: 136, userRank: 16},
  {aiLevel: 142, userRank: 17},
  {aiLevel: 148, userRank: 18},
  {aiLevel: 154, userRank: 19},
  {aiLevel: 160, userRank: 20},
  {aiLevel: 170, userRank: 21},
  {aiLevel: 180, userRank: 22},
  {aiLevel: 190, userRank: 23},
  {aiLevel: 200, userRank: 24},
  {aiLevel: 210, userRank: 25},
  {aiLevel: 220, userRank: 26},
  {aiLevel: 230, userRank: 27},
  {aiLevel: 240, userRank: 28},
  {aiLevel: 250, userRank: 29},
  {aiLevel: 280, userRank: 30},
  {aiLevel: 310, userRank: 31},
  {aiLevel: 340, userRank: 32},
  {aiLevel: 370, userRank: 33},
  {aiLevel: 440, userRank: 34},
  {aiLevel: 520, userRank: 35},
  {aiLevel: 560, userRank: 36},
];

/**
 * aiLevel 對照 rank
 * @param {Number} aiLevel 傳來的 aiLevel
 * @returns {String} 回傳棋力
 */
function processAiLevel(aiLevel) {
  const userRank = aiLevel2UserRankMapping.find(
    (mapping) => mapping.aiLevel >= aiLevel
  )?.userRank;
  return rankList.find((item) => item.value === userRank)?.label;
}

/**
 * rank 對照 aiLevel
 * @param {Number|String} rank 傳來的棋力
 * @returns {String} 回傳aiLevel
 */
function processRank(rank) {
  if (typeof rank === 'string') {
    rank = rankList.find((item) => item.label === rank)?.value;
  }
  return aiLevel2UserRankMapping.find((mapping) => mapping.userRank === rank)
    ?.aiLevel;
}

export default {
  processAiLevel,
  processRank,
};
