import {checkVersion} from './lib/base/checkVersion';
('./lib/base/checkVersion');
import Vue from 'vue';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import './assets/scss/theme.scss';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Vuelidate from 'vuelidate';
import Meta from 'vue-meta';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n/index.js';
import {onApiError, callLambda} from '@/lib/api/lambda';
import Message from '@/lib/base/message';
import PortalVue from 'portal-vue';
import '@/assets/icomoon/style.css';
import delay from '@/lib/base/delay';
import {removeCanvasOnDestroy} from '@/lib/base/canvas.js';
import {Device} from '@capacitor/device';
import filters from './lib/base/filters';
import JPush from '@/lib/jpush';
import JShare from '@/lib/jshare';
import RecordPlugin from '@/lib/recordPlugin';
import SoundService from '@/lib/base/soundService';
import AppEvent from '@/lib/base/appEvent.js';
import './lib/plugins/device';
import './lib/plugins/directives';
import day from 'dayjs';
import './lib/plugins/locale';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import notificationHandler from '@/lib/base/notificationHandler.js';
import VCalendar from 'v-calendar';
import {clientVersion, stage, gtm} from '@/constant/env';
import VueGtm from '@gtm-support/vue2-gtm';
import '@/lib/base/fbPixel.js';

if (stage === 'prod') {
  import('@/lib/analytics');
}

/**
 * 設定螢幕方向
 * @param {String} orientation 方向
 */
const setScreenOrientation = async (orientation) => {
  try {
    await window.screen.orientation.lock(orientation);
    // eslint-disable-next-line no-empty
  } catch (_) {}
};

if (Vue.prototype.$device.isTablet) {
  setScreenOrientation('landscape');
} else {
  setScreenOrientation('portrait');
}

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

if (!window.isUnitTest) {
  require('wgo.js/wgo/wgo.js');
  require('wgo.js/wgo/kifu.js');
  require('wgo.js/wgo/sgfparser.js');
  require('wgo.js/wgo/player.js');
  require('wgo.js/wgo/basicplayer.js');
  require('wgo.js/wgo/basicplayer.component.js');
  require('wgo.js/wgo/basicplayer.infobox.js');
  require('wgo.js/wgo/basicplayer.commentbox.js');
  require('wgo.js/wgo/basicplayer.control.js');
  require('wgo.js/wgo/player.editable.js');
  require('wgo.js/wgo/scoremode.js');
  require('wgo.js/wgo/player.permalink.js');
}

Vue.config.productionTip = false;

onApiError(function (
  error,
  {category, apiName, data, params, headers, method}
) {
  if (
    error.response &&
    error.response.status === 401 &&
    apiName !== 'hjjPushTags'
  ) {
    const url = new URL(window.location.href);
    if (
      !url.href.includes('reset-password') &&
      !url.href.includes('sign-up-status')
    ) {
      store.dispatch('user/logout');
    }
  }
  if (error.response && error.response.status === 503) {
    router.push({name: 'maintenance'});
  }
  if (error && ['ECONNRESET', 'ETIMEDOUT'].includes(error.code)) {
    return delay(25).then(() => {
      return callLambda({category, apiName, data, params, headers, method});
    });
  }
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue, {
  BButton: {
    size: [
      'oval-lg',
      'oval-sm',
      'fixed-lg',
      'fixed-md',
      'fixed-sm',
      'sm',
      'md',
      'lg',
    ],
  },
});
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(Toast);
Vue.use(Vuelidate);
Vue.use(Meta);
Vue.use(PortalVue);
Vue.component('VueSlider', VueSlider);
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
});

if (gtm) {
  Vue.use(VueGtm, {
    id: gtm,
    defer: false,
    compatibility: true,
    nonce: '2726c7f26c',
    enabled: stage === 'prod',
    debug: process.env.NODE_ENV === 'development',
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });
  Vue.prototype.$gtmTrack = (event) => {
    try {
      if (Array.isArray(window.dataLayer)) window.dataLayer?.push({event});
    } catch (error) {
      console.log(error);
    }
  };
}

Vue.prototype.$t = function (key, values) {
  let result = '';
  if (this.$i18n) {
    result = this.$i18n.t(key, values);
  }
  return result;
};

Vue.prototype.$day = day;
Vue.prototype.$Message = Message;
Vue.prototype.$JShare = new JShare();
const jpush = new JPush();
Vue.prototype.$JPush = jpush;
Vue.prototype.setScreenOrientation = setScreenOrientation;
Vue.prototype.$RecordPlugin = new RecordPlugin();
Vue.prototype.$isJungo = clientVersion === 'hjj-jungo';
AppEvent.on(AppEvent.eventNameEnum.APP_URL_OPEN, (data) => {
  console.log('appUrlOpen');
  let url = new URL(data.url);
  if (url.searchParams.has('al_applink_data')) {
    const applinkData = JSON.parse(url.searchParams.get('al_applink_data'));
    url = new URL(applinkData.target_url);
  }
  if (url.searchParams.has('pvpId')) {
    const pvpId = url.searchParams.get('pvpId');
    router.push({name: 'pvp-join', params: {id: pvpId}});
  }
  if (url.searchParams.has('email')) {
    // todo: 2022/10/5上線版本暫時不使用branch.io的網址，但後續可能會調整流程有可能還是會使用，故先保留
    const type = url.searchParams.get('email');
    const id = url.searchParams.get('id');
    const code = url.searchParams.get('code');
    router.push({name: type, query: {id, code, type: 'email'}});
  }
  if (url.href.includes('/liveList')) {
    router.push({name: 'liveList'});
  }
});

/**
 * 點擊推波事件處理
 * @param {Object} info 點擊推波內容
 */
const clickNotificationHandler = async (info) => {
  jpush.clearTempInfo();
  notificationHandler(info);
};

Device.getInfo().then(async (deviceInfo) => {
  if (deviceInfo.platform !== 'web') {
    jpush.updateTags();
    AppEvent.on(AppEvent.eventNameEnum.APP_STATE_CHANGE, () => {
      checkVersion();
      jpush.updateTags();
    });
    jpush.capacitorPlugin.addListener('onClickNotification', (info) => {
      clickNotificationHandler(info);
    });
    const info = await jpush.getTempInfo();
    clickNotificationHandler(info);
  } else if (deviceInfo.platform === 'web') {
    const url = new URL(window.location.href);
    if (url.searchParams.has('pvpId')) {
      const pvpId = url.searchParams.get('pvpId');
      window.location.href = window.location.origin + `#/pvp-join/${pvpId}`;
    }
    if (url.searchParams.has('email')) {
      const type = url.searchParams.get('email');
      const id = url.searchParams.get('id');
      const code = url.searchParams.get('code');
      window.location.href =
        window.location.origin + `#/${type}/?id=${id}&code=${code}&type=email`;
    }
  }
  if (deviceInfo.platform === 'android') {
    Vue.prototype.$RecordPlugin.enable();
  }
});

Vue.prototype.$playSound = (...args) => {
  SoundService.playSound(...args);
  return true;
};

Vue.mixin({
  beforeDestroy() {
    removeCanvasOnDestroy(this.$el);
  },
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
