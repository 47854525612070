import {registerPlugin} from '@capacitor/core';

class AppPlugin {
  constructor(pluginName) {
    this.isHavePlugin = null;
    this.capacitorPlugin = registerPlugin(pluginName);
    this.checkIsHavePlugin();
  }

  async checkIsHavePlugin() {
    if (this.isHavePlugin === null) {
      try {
        await this.capacitorPlugin.echo({message: 'init'});
        this.isHavePlugin = true;
      } catch (error) {
        this.isHavePlugin = false;
      }
    }
    return this.isHavePlugin;
  }
}

export default AppPlugin;
