/* eslint-disable require-jsdoc */
import {callLambda} from '@/lib/api/lambda';
import _ from 'lodash';

/**
 * 取得直播課程
 * @returns {Promise} promise
 */
async function getStudentLiveCourses() {
  const res = await callLambda({
    category: 'liveCourse',
    method: 'GET',
    apiName: 'student/courses',
  });
  return res.data;
}

/**
 * 取得直播課程紀錄
 * @param {String} liveCourseId liveCourseId
 * @param {String} classId classId
 * @returns {Promise} promise
 */
async function getLiveCoursesRecord({liveCourseId, classId}) {
  const res = await callLambda({
    category: 'liveCourse',
    method: 'GET',
    apiName: 'record',
    params: {
      liveCourseId,
      classId,
    },
  });
  return res.data;
}

/**
 * 取得直播課程
 * @returns {Promise} promise
 */
async function getTeacherLiveCourses() {
  const res = await callLambda({
    category: 'liveCourse',
    method: 'GET',
    apiName: 'courses',
  });
  return res.data;
}

/**
 * 取得兒童啟蒙班直播課程
 * @returns {Promise} promise
 */
async function getKid2WeekLiveCourses() {
  const res = await callLambda({
    category: 'liveCourse',
    method: 'GET',
    apiName: 'kid2weekCourse',
  });
  return res.data;
}

/**
 * 取得標籤直播課程
 * @param {Object} params 篩選資料
 * @returns {Promise} promise
 */
const getLiveCoursesByTags = _.memoize(
  async function getLiveCoursesByTags(params) {
    const res = await callLambda({
      category: 'liveCourse',
      method: 'GET',
      apiName: 'getCoursesByTags',
      params,
    });
    return res.data;
  },
  (params) => JSON.stringify(params)
);

/**
 * 取得標籤
 * @param {Object} params 篩選資料
 * @returns {Promise} promise
 */
async function getTags(params) {
  const res = await callLambda({
    category: 'liveCourse',
    method: 'GET',
    apiName: 'tags',
    params,
  });
  return res.data;
}

/**
 * 訂閱直播
 * @param {String} liveCourseId  直播課 ID
 * @param {String} coursePlan  方案 ID
 * @returns {Promise} promise
 */
async function postNotification(liveCourseId, coursePlan) {
  const res = await callLambda({
    category: 'liveCourse',
    method: 'POST',
    apiName: 'notification',
    data: {
      liveCourseId,
      coursePlan,
    },
  });
  return res.data;
}

/**
 * 取消訂閱直播
 * @param {String} liveCourseId  直播課 ID
 * @returns {Promise} promise
 */
async function deleteNotification(liveCourseId) {
  const res = await callLambda({
    category: 'liveCourse',
    method: 'DELETE',
    apiName: 'notification',
    data: {
      liveCourseId,
    },
  });
  return res.data;
}

/**
 * 新增直播課程
 * @param {String} name 名字
 * @param {String} classId 班級id
 * @param {Number} startDate 開始日
 * @param {Number} startAt 開始時間
 * @param {Number} endAt 結束時間
 * @returns {Promise} promise
 */
async function addLiveCourse(name, classId, startDate, startAt, endAt) {
  const res = await callLambda({
    category: 'liveCourse',
    method: 'POST',
    apiName: 'add',
    data: {
      name,
      classId,
      startDate,
      startAt,
      endAt,
    },
  });
  return res.data;
}

/**
 * 刪除直播課程
 * @param {String} classId 班級id
 * @returns {Promise} promise
 */
function deleteLiveCourse(classId) {
  return callLambda({
    category: 'liveCourse',
    method: 'DELETE',
    apiName: `courses/${classId}`,
  }).then((res) => {
    return res;
  });
}

/**
 *
 * @param {String} liveCourseId 班級id
 * @param {String} timestamp 時間
 * @returns {Promise} promise
 */
function postLiveCourseRecord(liveCourseId, timestamp) {
  return callLambda({
    category: 'liveCourse',
    method: 'POST',
    apiName: 'record',
    data: {
      liveCourseId,
      timestamp,
    },
  }).then((res) => {
    return res;
  });
}

export default {
  getStudentLiveCourses,
  getTeacherLiveCourses,
  getLiveCoursesByTags,
  getKid2WeekLiveCourses,
  getTags,
  addLiveCourse,
  deleteLiveCourse,
  getLiveCoursesRecord,
  postNotification,
  deleteNotification,
  postLiveCourseRecord,
};
