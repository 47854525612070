import verificationService from '@/services/verification';

/**
 * @returns {Object} initialize state
 */
const initialState = () => {
  return {
    verificationData: null,
    currentScore: {},
    totalScores: null,
    records: null,
    isMilestoneShow: false,
    currentVerificationTsume: {},
    milestoneVideoCover: '',
    hasNewScore: false,
  };
};

const state = initialState();

const getters = {
  verificationData(state) {
    return state.verificationData;
  },
  hasNewScore(state) {
    return state.hasNewScore;
  },
  currentScore(state) {
    return state.currentScore;
  },
  totalScores(state) {
    return state.totalScores;
  },
  pastScores(state) {
    const filterScores = (state.totalScores || []).filter((score) => {
      return score.state === 'OVER';
    });
    return filterScores.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });
  },
  records(state) {
    return state.records;
  },
};

const actions = {
  async getVerification({commit}, currentPlanId) {
    try {
      const data = await verificationService.getVerification(currentPlanId);
      commit('setVerificationData', data);
      if (data?.state === 'SCORE') {
        commit('setCurrentScore', data);
      }
      return data;
    } catch (error) {
      commit('setVerificationData', {});
      throw error;
    }
  },
  getTotalScores({commit}) {
    return verificationService.getTotalScores().then((totalScores) => {
      commit('setTotalScores', totalScores);
    });
  },
  getRecords({commit}) {
    return verificationService.getRecords().then((records) => {
      commit('setRecords', records);
    });
  },
};

const mutations = {
  setVerificationData(state, data) {
    state.verificationData = data;
  },
  setTotalScores(state, totalScores) {
    state.totalScores = totalScores;
  },
  setRecords(state, records) {
    state.records = records;
  },
  setCurrentVerificationTsume(state, data) {
    state.currentVerificationTsume = data;
  },
  setCurrentScore(state, data) {
    state.currentScore = data;
  },
  setHasNewScore(state, data) {
    state.hasNewScore = data;
  },
  setMilestoneVideoCover(state, data) {
    state.milestoneVideoCover = data;
  },
  setMilestone(state, data) {
    state.isMilestoneShow = data;
  },
  resetState(state) {
    const _state = initialState();
    Object.assign(state, _state);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
