import {callLambda} from '@/lib/api/lambda';

/**
 * 取得使用者下棋資訊
 * @returns {Promise} promise
 */
function getGameStatistics() {
  return callLambda({
    category: 'aiGame',
    apiName: 'aiGameStatistics',
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得黑嘉嘉ai對弈列表
 * @param {String} planId 課程方案 ID
 * @returns {Promise} promise
 */
function getAiGameCourseConfigs(planId) {
  return callLambda({
    category: 'aiGame',
    apiName: `hjjAiGameCourseConfigs?coursePlan=${planId}`,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得使用者某課程的ai對弈紀錄
 * @param {String} planId 課程方案 ID
 * @returns {Promise} promise
 */
function getAiGameUserRecord(planId) {
  return callLambda({
    category: 'aiGame',
    apiName: `hjjAiGameUserRecord?coursePlan=${planId}`,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * ai對弈落點提示功能
 * @param {Number} id game id
 * @returns {Promise} promise
 */
function supportGame(id) {
  const data = {
    id,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'supportGame',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 創建新下棋練習/ai對弈（注意aiId是列表中的lv，aiLevel則是ai的棋力）
 * @param {String} userColor 玩家擇色
 * @param {Number} aiLevel ai等級
 * @param {Number} boardSize 棋盤尺寸
 * @param {String} style 棋風
 * @param {String} opponentName ai名字
 * @param {String} coursePlan coursePlan
 * @param {String} aiId ai的lv
 * @returns {Promise} promise
 */
function createPracticeGame(
  userColor,
  aiLevel,
  boardSize,
  style,
  opponentName,
  coursePlan,
  aiId
) {
  const data = {
    userColor,
    boardSize,
    aiLevel,
    style,
    opponentName,
    isAdaptive: false,
    coursePlan,
    aiId,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'practiceGame',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 創建黑嘉嘉課程的對局
 * @param {String} courseUniqueKey 課程唯一鍵
 * @param {String} coursePlan coursePlan
 * @returns {Promise} promise
 */
function createCourseGame(courseUniqueKey, coursePlan) {
  const data = {
    courseUniqueKey,
    coursePlan,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'hjjCourseGame',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 創建黑嘉嘉檢定的對局
 * @param {Number} rank 棋力
 * @param {String} verificationId 檢定id
 * @param {String} gameId 下棋id
 * @param {String} coursePlan coursePlan
 * @returns {Promise} promise
 */
function createVerificationGame(rank, verificationId, gameId, coursePlan) {
  const data = {
    rank,
    gameId,
    id: verificationId,
    coursePlan,
  };
  return callLambda({
    category: 'verification',
    apiName: 'createAiGame',
    data,
    method: 'post',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得尚未完成的下棋練習
 * @returns {Promise} promise
 */
function getUnfinishedGame() {
  return callLambda({
    category: 'aiGame',
    apiName: 'unfinishedGame',
    params: {
      gameMode: 'practice',
    },
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得黑嘉嘉課程下棋紀錄
 * @param {String} courseUniqueKey 課程唯一鍵
 * @returns {Promise} promise
 */
function getUnfinishedCourseGame(courseUniqueKey) {
  return callLambda({
    category: 'aiGame',
    apiName: `hjjCourseGame/${encodeURIComponent(courseUniqueKey)}`,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 取得黑嘉嘉檢定的對局
 * @param {String} verificationId 檢定id
 * @param {String} gameId 下棋id
 * @returns {Promise} promise
 */
function getUnfinishedVerificationGame(verificationId, gameId) {
  const params = {
    gameId,
    id: verificationId,
  };
  return callLambda({
    category: 'verification',
    apiName: `unfinishedGame?${verificationId}&${gameId}`,
    params,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

/**
 * 更新目前下棋練習對局
 * @param {Number} createdTime 對局創建時間
 * @param {Object} move 落子位置
 * @returns {Promise} promise
 */
function updateGame(createdTime, move) {
  const data = {
    createdTime,
    move,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'gameKifu',
    data,
    method: 'put',
  });
}

/**
 * ai下棋
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function nextMove(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: 'nextMove',
    data: {
      createdTime,
    },
    method: 'put',
  });
}

/**
 * 算輸贏數地
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function getJudgement(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: 'judgement',
    params: {
      createdTime,
    },
    method: 'get',
  });
}

/**
 * 確認算輸贏數地結果
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function confirmJudgement(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: 'judgement',
    data: {
      createdTime,
    },
    method: 'put',
  });
}

/**
 * 認輸、結束對局
 * @param {Number} createdTime 對局創建時間
 * @returns {Promise} promise
 */
function resign(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: 'resignation',
    data: {
      createdTime,
    },
    method: 'put',
  }).then((result) => {
    return result;
  });
}

/**
 * 取得棋譜列表資料
 * @param {Number} amount 取得資料數量
 * @param {String} username 使用者名稱
 * @param {Array} gameMode 取得資料的類型
 * @param {String} pvpLastKey pvp 最後的 key
 * @param {String} pveLastKey pve 最後的 key
 * @returns {Promise} promise
 */
function getKifus(amount, username, gameMode, pvpLastKey, pveLastKey) {
  return callLambda({
    category: 'aiGame',
    apiName: 'getKifuList',
    method: 'post',
    data: {
      amount,
      username,
      gameMode,
      pvpLastKey,
      pveLastKey,
    },
  }).then((result) => {
    return result;
  });
}

/**
 * 取得單一棋譜資料
 * @param {Number} createdTime id
 * @returns {Promise} promise
 */
function getKifu(createdTime) {
  return callLambda({
    category: 'aiGame',
    apiName: '',
    method: 'get',
    params: {
      createdTime,
    },
  }).then((result) => {
    return result.data;
  });
}

/**
 * 棋譜覆盤
 * @param {Number} id 此棋譜id
 * @param {Number} gameMode 此棋譜下期模式(ex.practice)
 * @param {Number} coursePlan 用戶當下使用的課程方案
 * @returns {Promise} promise
 */
function reviewGame({id, gameMode, coursePlan, username}) {
  const data = {
    id,
    gameMode,
    coursePlan,
    username,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'reviewGame',
    method: 'post',
    data,
  }).then((result) => {
    return result.data;
  });
}

/**
 * 棋譜變化圖(限19路)
 * @param {Number} sgf 棋譜sgf
 * @param {Number} gameId 棋譜id
 * @param {Number} step 手數
 * @returns {Promise} promise
 */
function variation(sgf, gameId, step) {
  const data = {
    sgf,
    gameId,
    step,
  };
  return callLambda({
    category: 'aiGame',
    apiName: 'variation',
    method: 'post',
    data,
  }).then((result) => {
    return result.data;
  });
}

export default {
  createPracticeGame,
  createCourseGame,
  createVerificationGame,
  getUnfinishedGame,
  getUnfinishedCourseGame,
  getUnfinishedVerificationGame,
  updateGame,
  nextMove,
  getJudgement,
  confirmJudgement,
  resign,
  getGameStatistics,
  getKifu,
  getKifus,
  reviewGame,
  getAiGameCourseConfigs,
  getAiGameUserRecord,
  supportGame,
  variation,
};
