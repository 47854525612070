import AppPlugin from '../base/appPlugin';
import {Share} from '@capacitor/share';
import {clientVersion} from '@/constant/env';
import {FileSharer} from 'capacitor-plugin-filesharer';
import {Device} from '@capacitor/device';
import {Filesystem, Directory} from '@capacitor/filesystem';
import i18n from '@/i18n/index.js';

// keytool -v -list -keystore android.keystore
class JShare extends AppPlugin {
  constructor() {
    super('JShare');
    this.usePrimitiveApiList = ['hjjtw'];
    this.JSHAREPlatform = {
      WechatSession: 1,
      WechatTimeLine: 2,
    };
    this.JSHAREMediaType = {
      text: 1,
      image: 2,
      link: 3,
    };
    this.isUsePrimitiveApi = this.usePrimitiveApiList.some(
      (item) => item === clientVersion
    );
  }

  async sharePvp(pvpId, text) {
    const version = clientVersion.replace('hjj-', '').replace('hjj', '');
    const url = `https://heijiajia-${version}.app.link/pvp?pvpId=${pvpId}`;
    const title = i18n.t('你有新的對弈邀請！');
    const platform = this.JSHAREPlatform.WechatSession;
    const mediaType = this.JSHAREMediaType.link;

    // eslint-disable-next-line require-jsdoc
    const share = async (isPrimitiveApi) => {
      if (isPrimitiveApi || this.isUsePrimitiveApi) {
        await Share.share({
          title: '',
          text,
          url,
          dialogTitle: null,
        });
      } else {
        if (await this.checkIsHavePlugin()) {
          const message = {url, text, title, platform, mediaType};
          const result = await this.capacitorPlugin.share(message);
          if (result.state === 'error') {
            return Promise.reject(result);
          }
        }
      }
    };
    try {
      await share();
    } catch (e) {
      if (!this.isUsePrimitiveApi) {
        await share(true);
      }
    }
  }
  async shareImage(base64) {
    const text = '';
    const title = '';
    const image = base64;
    const platform = this.JSHAREPlatform.WechatTimeLine;
    const mediaType = this.JSHAREMediaType.image;
    const filename = 'share.png';
    // eslint-disable-next-line require-jsdoc
    const share = async (isPrimitiveApi) => {
      const message = {image, text, title, platform, mediaType};
      if (isPrimitiveApi || this.isUsePrimitiveApi) {
        const deviceInfo = await Device.getInfo();
        if (deviceInfo.platform === 'ios') {
          await FileSharer.share({
            filename,
            base64Data: message.image.split(';base64,')[1],
          });
        } else if (deviceInfo.platform === 'android') {
          const path = await Filesystem.writeFile({
            path: filename,
            data: message.image,
            directory: Directory.Documents,
          });
          await Share.share({
            title: message.title,
            text: message.text,
            url: path.uri,
            dialogTitle: null,
          });
        }
      } else {
        if (await this.checkIsHavePlugin()) {
          const deviceInfo = await Device.getInfo();
          if (deviceInfo.platform === 'android') {
            const filename = `share${new Date().getTime()}.png`;
            await Filesystem.writeFile({
              path: filename,
              data: message.image,
              directory: Directory.Cache,
            });
            message.image = filename;
          }
          const result = await this.capacitorPlugin.share(message);
          if (result.state === 'error') {
            return Promise.reject(result);
          }
        }
      }
    };

    try {
      await share();
    } catch (e) {
      if (!this.isUsePrimitiveApi) {
        await share(true);
      }
    }
  }

  async savePhoto(base64, filename) {
    if (await this.checkIsHavePlugin()) {
      const deviceInfo = await Device.getInfo();
      const platform = deviceInfo.platform;
      if (platform === 'android') {
        await Filesystem.writeFile({
          path: filename,
          data: base64,
          directory: Directory.Documents,
        });
      } else if (platform === 'ios') {
        await this.capacitorPlugin.savePhoto({image: base64});
      }
    }
  }
}

export default JShare;
