import store from '@/store';
import router from '@/router';
import {Browser} from '@capacitor/browser';
import Message from '@/lib/base/message';
import i18n from '@/i18n/index.js';

/**
 * 推波事件處理
 * @param {Object} info 點擊推波內容
 */
const clickNotificationHandler = async (info) => {
  if (info) {
    const {name, url, coursePlan} = info;
    if (coursePlan) {
      const plans = store.getters['course/plans'];
      const currentPlan = store.getters['course/currentPlan'];
      const newPlan = plans.find((plan) => plan.id === coursePlan);

      if (newPlan !== -1 && newPlan.id !== currentPlan?.id) {
        store.commit('course/setCurrentPlan', newPlan);
        Message.success(i18n.t('已幫您切換至{name}課程', {name: newPlan.name}));
      }
    }
    if (url) {
      Browser.open({
        url,
        presentationStyle: 'popover',
      });
    }
    if (name) {
      let params;
      if (info.params) {
        params =
          typeof info.params === 'string'
            ? JSON.parse(info.params)
            : info.params;
      }

      router.push({name, params});
    }
  }
};
export default clickNotificationHandler;
