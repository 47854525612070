const lang = {
  我的課程: '我的课程',
  測試訊息: '测试信息',
  課程: '课程',
  練習: '练习',
  檢定: '测评',
  個人: '我的',
  儲存: '保存',
  對戰: '对战',
  上次觀看: '上次观看',
  開始學習: '开始学习',
  第: '第',
  課: '课',
  歡迎來到黑嘉嘉圍棋教室: '欢迎来到黑嘉嘉围棋教室',
  早安: '早上好',
  午安: '下午好',
  晚安: '晚上好',
  有新課程: '有新课程',
  下棋: '下棋',
  做題: '做题',
  棋力檢定: '棋力测评',
  載入中: '加载中',
  AI計算中: 'AI计算中',
  黑勝: '黑胜',
  白勝: '白胜',
  黑中盤勝: '黑中盘胜',
  白中盤勝: '白中盘胜',
  請耐心等待: '请耐心等待',
  '有地方沒下完，還不能申請算輸贏': '有地方没下完，还不能申请判胜负',
  手以上才能申請算輸赢哦: '手以上才能申请判胜负哦',
  '（黑{blackScore}，黑收後）': '黑{blackScore}，黑收后',
  '（黑{blackScore}，白收後）': '黑{blackScore}，白收后',
  '準備好和我一起進入圍棋的世界了嗎？': '准备好和我一起进入围棋的世界了吗？',
  黑嘉嘉圍棋教室: '黑嘉嘉围棋教室',
  '由淺入深，量身打造學習體驗': '由浅入深，量身打造学习体验',
  '通過線上檢定，取得棋力證書': '通过线上测评，取得棋力证书',
  開啟圍棋之旅: '开启围棋之旅',
  請輸入帳號: '请输入帐号',
  '請輸入8-15位密碼': '请输入8-15位密码',
  查無此帳號: '查无此帐号',
  '*請輸入帳號': '*请输入帐号',
  '*請輸入密碼': '*请输入密码',
  '*查無此帳號': '*查无此帐号',
  '*您的帳號已被停用': '*您的帐号已被停用',
  '*不能少於6位數': '*不能少于6位数',
  '*密碼錯誤': '*密码错误',
  登入: '登录',
  '忘記密碼？': '忘记密码？',
  我同意: '我同意',
  服務條款: '服务条款',
  和: '和',
  隱私政策: '隐私政策',
  '還沒有帳號？前往': '还没有帐号？前往',
  官網購買課程: '官网购买课程',
  尚未同意服務條款和隱私政策: '尚未同意服务条款和隐私政策',
  '*伺服器發生錯誤，請稍後再試': '*服务器出错，请稍后再试',
  提醒: '提醒',
  關閉螢幕錄影才能繼續使用: '请关闭录屏功能后继续使用',
  下棋累計數: '累计下棋数',
  做題累計數: '累计做题数',
  下棋勝率: '下棋胜率',
  做題正確率: '做题正确率',
  證書: '证书',
  棋譜: '棋谱',
  密碼: '密码',
  設定: '设置',
  聯絡我們: '联系我们',
  登出: '退出登录',
  成長紀錄: '成长记录',
  編輯: '编辑',
  頭像: '头像',
  名字: '名字',
  編輯名字: '编辑名字',
  編輯個人頭像: '编辑个人头像',
  完成: '完成',
  請輸入新名字: '请输入新名字',
  '*請輸入新名字': '*请输入新名字',
  更改密碼: '修改密码',
  請輸入舊密碼: '请输入旧密码',
  '*請輸入舊密碼': '*请输入旧密码',
  '*舊密碼輸入錯誤': '*旧密码输入错误',
  '請輸入8-15位新密碼': '请输入8-15位新密码',
  '*請輸入新密碼': '*请输入新密码',
  '*兩次新密碼輸入不同': '*两次新密码输入不同',
  請再次輸入新密碼: '请再次输入新密码',
  '*請再次輸入新密碼': '*请再次输入新密码',
  '*新密碼和舊密碼一樣': '*新密码和旧密码一样',
  '*不能少於8碼': '*不能少于8位数',
  密碼更改失敗: '密码更改失败',
  密碼更改成功: '密码更改成功',
  返回上一頁: '返回上一页',
  棋力證書: '棋力证书',
  音效: '音效',
  直接落子: '直接落子',
  檢定說明: '测评说明',
  歷史成績單: '历史成绩单',
  開始檢定: '开始测评',
  檢定進行中: '测评进行中',
  成績將於隔日早上10點公布: '成绩将于隔日早上10点公布',
  '{count}個項目。': '{count}个项目。',
  你已完成所有項目: '你已完成所有项目',
  棋力檢定包含: '棋力测评包含',
  公布: '公布',
  '各項目皆需在規定時間內完成作答。': '各项目皆需在规定时间内完成作答。',
  '分數達評分標準即通過測驗 , 將升級個人棋力並授予證書。':
    '分数达评分标准即通过测验 , 将升级个人棋力并授予证书。',
  隔日早上10點: '隔日早上10点',
  評分標準: '评分标准',
  題: '题',
  大局: '大局',
  佈局: '布局',
  戰鬥: '战斗',
  官子: '官子',
  綜合: '综合',
  好棋率: '好棋率',
  盤: '盘',
  滿分100分: '满分100分',
  得分超過: '得分超过',
  所有項目加總滿分為100分: '所有项目加总满分为100分',
  '測驗時間{time}分鐘': '测验时间{time}分钟',
  '共{count}題': '共{count}题',
  '每題{score}分': '每题{score}分',
  計分項目為: '计分项目为',
  以上: '以上',
  視為通過: '视为通过',
  開始: '开始',
  已完成: '已完成',
  目前沒有成績單: '目前没有成绩单',
  檢定後歷史成績單將會顯示在這: '测评后历史成绩单将会显示在这',
  已通過: '已通过',
  未通過: '未通过',
  成績單: '成绩单',
  棋力檢定成績單: '棋力测评成绩单',
  分: '分',
  得分: '得分',
  勝負: '胜负',
  其他: '其他',
  已領取: '已领取',
  領取證書: '领取证书',
  再次檢定: '再次测评',
  請再試一次: '请再试一次',
  你已完成所有檢定: '你已完成所有测评',
  '恭喜你完成所有課程！': '恭喜你完成所有课程！',
  立即觀賞完課特輯: '立即观赏完课特辑',
  提子: '提子',
  算輸贏: '判胜负',
  題目: '题目',
  我的答案: '我的答案',
  解答: '解答',
  將繼續上次未下完的棋局: '将继续上次未下完的棋局',
  對局中: '对局中',
  看起來還沒下完哦: '看起来还没下完哦',
  目前沒有棋譜: '目前没有棋谱',
  下棋後個人棋譜將會顯示在這: '下棋后个人棋谱将会显示在这',
  共有: '共有',
  個棋譜: '个棋谱',
  白超時負: '白超时负',
  黑超時負: '黑超时负',
  '黑{v1}又{v2}/4子勝': '黑{v1}又{v2}/4子胜',
  '黑{v2}/4子勝': '黑{v2}/4子胜',
  '白{v1}又{v2}/4子勝': '白{v1}又{v2}/4子胜',
  '白{v2}/4子勝': '白{v2}/4子胜',
  '網路不穩定，將自動重新整理': '网络不稳定，将自动刷新页面',
  '棋局結束啦，請點擊［算輸贏］按鈕！': '棋局结束啦，请点击［判胜负］按钮！',
  還沒輪到你哦: '还没轮到你哦',
  請先離開: '请先退出',
  數地: '数地',
  模式: '模式',
  投降: '投降',
  取消: '取消',
  確定: '确定',
  返回: '返回',
  再一局: '再一局',
  再一次: '再一次',
  '確定要認輸？': '确定要认输？',
  下棋練習: '下棋练习',
  '開放條件：通過10k檢定': '开放条件：通过10级测评',
  解鎖條件: '解锁条件',
  開放: '开放',
  中級: '中级',
  高級: '高级',
  基礎: '基础',
  入門: '入门',
  初學: '初学',
  初級: '初级',
  '是否繼續上次的對局？': '是否继续上次的对局？',
  繼續: '继续',
  不繼續: '不继续',
  返回首頁: '返回首页',
  看解答: '看解答',
  做題練習: '做题练习',
  切換難度: '切换难度',
  難度: '难度',
  '新手 30k': '新手 30k',
  '基礎 25k': '基础 25k',
  '入門 20k': '入门 20k',
  '初學 15k': '初学 15k',
  '高級 9級~15級': '高级 9级~5级',
  '衝段 4級~1段': '冲段 4级~1段',
  答對: '答对',
  共: '共',
  黑棋如何做活: '黑棋如何做活',
  黑棋如何殺掉白棋: '黑棋如何杀掉白棋',
  黑棋如何吃掉白棋: '黑棋如何吃掉白棋',
  黑棋如何不被白棋吃掉: '黑棋如何不被白棋吃掉',
  黑棋如何比氣吃掉白棋: '黑棋如何比气吃掉白棋',
  黑棋如何用接不歸吃掉白棋: '黑棋如何用接不归吃掉白棋',
  黑棋要從哪個方向叫吃白棋: '黑棋要从哪个方向打吃白棋',
  黑棋要如何把白棋切斷: '黑棋要如何分断白棋',
  如何把黑棋連在一起: '如何把黑棋连在一起',
  黑棋要如何把空圍好: '黑棋要如何把空围好',
  黑棋如何用門吃吃掉白棋: '黑棋如何用门吃吃掉白棋',
  黑棋如何用反提吃掉白棋: '黑棋如何用反提吃掉白棋',
  黑棋要如何打劫: '黑棋要如何打劫',
  黑棋如何避免被白棋雙叫吃: '黑棋如何避免被白棋双打吃',
  黑棋如何做出眼睛: '黑棋如何做眼',
  黑棋如何製造打劫: '黑棋如何制造打劫',
  黑棋如何下成雙活: '黑棋如何下成双活',
  黑棋如何破掉白棋的眼: '黑棋如何破掉白棋的眼',
  黑棋如何用征子吃掉白棋: '黑棋如何用征子吃掉白棋',
  黑棋如何利用打劫做活: '黑棋如何利用打劫做活',
  黑棋如何利用打劫殺掉白棋: '黑棋如何利用打劫杀掉白棋',
  黑棋如何反提吃掉白棋: '黑棋如何反提吃掉白棋',
  如何把黑棋連起來: '如何把黑棋连起来',
  黑棋如何反提吃白: '黑棋如何反提吃白',
  黑先吃白: '黑先吃白',
  黑先殺白: '黑先杀白',
  黑棋如何逃跑: '黑棋如何逃跑',
  打劫殺白: '打劫杀白',
  打劫做活: '打劫做活',
  打劫活: '打劫活',
  黑先做活: '黑先做活',
  黑棋如何雙叫吃白棋: '黑棋如何双打吃白棋',
  黑先如何切斷白棋: '黑先如何分断白棋',
  黑先如何連接: '黑先如何连接',
  題正確: '题正确',
  題錯誤: '题错误',
  '確定要離開？': '确认退出？',
  題目列表: '题目列表',
  正確: '正确',
  錯誤: '错误',
  上一題: '上一题',
  下一題: '下一题',
  離開將會自動結算: '退出将自动结算',
  點選下列網址或掃描QRCode: '点选下列网址或扫描二维码',
  '加入［黑嘉嘉圍棋教室］LINE 好友': '添加［黑嘉嘉围棋教室］微信好友',
  '加入LINE@': '关注公众号',
  '{rank}級棋力檢定證書': '{rank}级棋力测评证书',
  '{text}棋力檢定證書': '{text}棋力检定证书',
  參加棋力檢定測驗已達標準: '参加棋力测评已达标准:',
  '特頒鑑定證書，以資證明': '颁发棋力证书，特此证明',
  目前沒有棋力證書: '尚未得到棋力证书',
  棋力檢定通過後證書將會顯示在這: '棋力测评通过后证书将会显示在这',
  '成為正式會員 , 棋力 {rank} 級': '成为正式会员 , 棋力 {rank} 级',
  '通過棋力檢定 , 棋力上升至 {rank} 級': '通过测评 , 棋力上升至 {rank} 级',
  '發生未知錯誤，再試一次': '发生未知错误，再试一次',
  手: '手',
  路: '路',
  日期: '日期',
  棋譜資訊: '棋谱信息',
  黑方: '黑方',
  白方: '白方',
  結果: '结果',
  知道了: '知道了',
  之後可以在歷史成績單查看: '之后可以在历史成绩单查看',
  證書儲存成功: '证书保存成功',
  證書儲存失敗: '证书保存失败',
  圖片下載發生錯誤: '图片加载出错',
  再按一次離開應用程式: '再按一次退出程序',
  請輸入暱稱: '请输入昵称',
  暱稱: '昵称',
  註冊: '注册',
  '已經有帳號？前往': '已有帐号？前往',
  請輸入手機號碼: '请输入手机号码',
  請輸入票券序號: '请输入票券序号',
  '*手機號碼必須為數字': '*手机号码必须为数字',
  '*此帳號已註冊過': '*此帐号已被注册',
  '*請輸入驗證碼': '*请输入验证码',
  '*驗證碼錯誤': '*验证码错误',
  '*請輸入暱稱': '*请输入昵称',
  '*請輸入手機號碼': '*请输入手机号码',
  '*請輸入序號': '*请输入序号',
  '*此序號不存在': '*此序号不存在',
  '*此序號已退費': '*此序号已退费',
  '*請輸入正確的手機號碼': '*请输入正确的手机号码',
  '*手機號碼已被使用': '*手机号码已被使用',
  '*序號已被使用': '*序号已被使用',
  '*序號已失效': '*序号已失效',
  '*操作過於頻繁，請稍後再嘗試': '*操作过于频繁，请稍后再试',
  '*驗證碼已逾期': '*验证码已失效',
  輸入驗證碼: '输入验证码',
  傳送驗證碼: '发送验证码',
  再一次傳送: '再一次发送',
  序號: '序号',
  '沒有序號嗎？前往': '没有序号吗？前往',
  獲得序號: '取得序号',
  忘記密碼: '忘记密码',
  送出: '送出',
  '系統將為你登出帳號，': '系统将为你退出帐号，',
  '確定要返回上一頁嗎？': '确定要返回上一页吗？',
  成功啟用序號: '成功启用序号',
  重設密碼: '重新设置密码',
  註冊成功: '注册成功',
  總分: '总分',
  更改成功: '更改成功',
  服務條款和隱私政策: '服务条款和隐私政策',
  '*手機號碼尚未註冊': '*手机号码尚未注册',
  '*請再次輸入密碼': '*请再次输入密码',
  '*兩次密碼輸入不同': '*两次密码输入不同',
  '我在［黑嘉嘉圍棋教室］等你一決高下～':
    '我在［黑嘉嘉围棋教室］等你一决高下～',
  '你有新的對弈邀請！': '你有新的对弈邀请！',
  勝利: '胜利',
  失敗: '失败',
  很熟練喔: '很熟练喔',
  要再加強: '要再加强',
  請再次輸入密碼: '请再次输入密码',
  解說影片: '视频解说',
  歡迎來到: '欢迎来到',
  時限: '时限',
  讀秒: '读秒',
  讀秒秒數: '读秒秒数',
  讀秒次數: '读秒次数',
  讓子: '让子',
  手合: '手合',
  '段差一級讓一子，有讓子上限': '段差一级让一子，有让子上限',
  '{v1}分鐘': '{v1}分钟',
  '{v1}秒': '{v1}秒',
  '{v1}次': '{v1}次',
  '{v1}路  - {v2} 分鐘 - {v3}秒{v4}次': '{v1}路  - {v2} 分钟 - {v3}秒{v4}次',
  不讓子: '不让子',
  建立對局: '建立对局',
  邀請好友下棋: '邀请好友下棋',
  點擊分享連結按鈕邀請好友一起對弈: '点击分享链接按钮邀请好友一起对弈',
  對局條件: '对局条件',
  棋盤: '棋盘',
  '{v1}路': '{v1}路',
  有: '有',
  無: '无',
  離開: '离开',
  分享連結: '分享链接',
  '確定要離開對局？': '确定要离开对局？',
  同意: '同意',
  拒絕: '拒绝',
  對方想要申請算輸贏: '对方想要申请判胜负',
  '是否同意？': '是否同意？',
  正在等待對方回應: '正在等待对方回应',
  '對方拒絕算輸贏，請繼續對局': '对方拒绝判胜负，请继续对局',
  '還沒下完，還不能算輸贏哦！': '还没下完，还不能判胜负哦！',
  有一方不同意輸贏結果: '有一方不同意输赢结果',
  對方婉拒你的邀請: '对方婉拒你的邀请',
  '對方網路不穩定，請耐心等候': '对方网络异常，请耐心等候',
  '落子異常，刷新棋盤資訊中...': '落子异常，刷新棋盘资讯中...',
  '對方斷線，不能算輸贏': '对方断线，不能判胜负',
  請確認網路連線的狀態後再次嘗試: '请确认网络连线的状态后再次尝试',
  '對局仍在進行中，確定要離開嗎？': '对局仍在进行中，确定要离开吗？',
  '發言時間過近，請{v1}秒後再發言': '发言时间过近，请{v1}秒后再发言',
  只有落子方可以申請算輸贏: '只有落子方可以申请判胜负',
  請先完成目前的操作: '请先完成目前的操作',
  '黑{blackScore}，黑收後': '黑{blackScore}，黑收后',
  '黑{blackScore}，白收後': '黑{blackScore}，白收后',
  '( 你已確認結果，對方仍在確認中 )': '( 你已确认结果，对方仍在确认中 )',
  對方已婉拒或離開房間: '对方已婉拒或离开房间',
  等待好友: '等待好友',
  房間不存在: '房间不存在',
  此房間已關閉: '此房间已关闭',
  複製成功: '拷贝成功',
  秒: '秒',
  次: '次',
  無法加入自己創建的房間: '无法加入自己创建的房间',
  繼續練習: '继续练习',
  天: '天',
  '即將開課，敬請期待': '即将开课，敬请期待',
  '（黑{blackScore}子）': '（黑{blackScore}子）',
  '非預期的錯誤發生，請重新整理': '非预期的错误发生，请刷新页面',
  '棋局還沒結束哦！': '棋局还没结束哦！',
  '此頁面無法重複開啟，按確定後自動跳至首頁':
    '此页面无法重复开启，按确定后自动跳至首页',
  已有其他使用者登入: '已有其他使用者登录',
  登入其他帳號: '登录其他帐号',
  將他人登出: '将他人退出帐号',
  已有其他使用者登入本帳號: '已有其他使用者登录本帐号',
  '有新版本，請至商店下載更新': '有新版本，请至应用商店下载更新',
  請記得於時間內完成: '请于时间内完成',
  逾期將視為放棄: '逾期将视为放弃',
  '共有 {totalKifus} 個棋譜，': '共有 {totalKifus} 个棋谱，',
  代表有覆盤: '代表有复盘',
  '問題手，': '问题手，',
  查看變化圖: '查看变化图',
  '好棋！': '好棋！',
  '下得真好～': '下得真好～',
  仍有進行中的對局: '仍有进行中的对局',
  離開將自動判輸: '离开将自动判输',
  '確定要離開嗎？': '确定要离开吗？',
  對方想要再一局: '对方申请再次对局',
  '國家/地區': '国家/地区',
  台灣: '台湾',
  香港: '香港',
  馬來西亞: '马来西亚',
  新加坡: '新加坡',
  作答結束: '作答结束',
  請先確認算輸贏結果: '请先确认判胜负结果',
  我的學習進度: '我的学习进度',
  數據紀錄: '数据记录',
  '目前版本：{version}': '当前版本：{version}',
  修改密碼: '修改密码',
  系統設定: '系统设置',
  我的棋譜: '我的棋谱',
  目前沒有網路: '目前没有网络',
  無法使用耶: '无法使用耶',
  '完課了還是可以自己練習喔！': '完课了还是可以自己练习喔！',
  課程影片也可以無限次重複觀看: '课程视频也可以无限次重复观看',
  手數: '手数',
  '{winCount}勝': '{winCount}胜',
  '{loseCount}敗': '{loseCount}败',
  '勝率{winRate}%': '胜率{winRate}%',
  '累計{winCount}題': '累计{winCount}题',
  '正確率{winRate}%': '正确率{winRate}%',
  開始上課: '开始上课',
  棋聚一堂: '棋聚一堂',
  一起學棋: '一起学棋',
  一起下棋: '一起下棋',
  切換課程: '切换课程',
  這邊可以切換新的課程喔: '这边可以切换新的课程喔',
  查看完課特輯: '查看完课特辑',
  學習進度: '学习进度',
  你還沒有任何課程: '你还没有任何课程',
  前往官網了解: '前往官网了解',
  限時倒數: '限时倒数',
  勝利條件: '胜利条件',
  '對方 Pass': '对方 Pass',
  '對方 Pass，建議點擊［算輸贏］按鈕結束棋局':
    '对方 Pass，建议点击［判胜负］按钮结束棋局',
  黑: '黑',
  子: '子',
  確定落子: '确定落子',
  認輸: '认输',
  手數顯示: '手数显示',
  直播: '直播',
  你與黑嘉嘉的專屬課程: '你与黑嘉嘉的专属课程',
  課程影片: '课程视频',
  已切換課程: '已切换课程',
  視頻解說: '视频解说',
  查看解答: '查看解答',
  目前沒有直播: '当前没有直播',
  直播開放後將會顯示在這: '直播开放后会显示在这',
  房間: '房间',
  進入直播: '进入直播',
  已結束: '已结束',
  未開始: '未开始',
  觀看回放: '观看回放',
  第一手: '第一手',
  最終手: '最终手',
  上十手: '上十手',
  下十手: '下十手',
  上一手: '上一手',
  下一手: '下一手',
  問題手: '问题手',
  離開變化圖: '离开变化图',
  兒童啟蒙: '儿童启蒙',
  手數隱藏: '手数隐藏',
  請將裝置: '请将装置',
  橫向: '横向',
  使用: '使用',
  保存更改: '保存更改',
  查看: '查看',
  個人資訊更改成功: '个人资讯更改成功',
  個人資訊更改失敗: '个人资讯更改失败',
  編輯個人資訊: '编辑个人资讯',
  我的成績單: '我的成绩单',
  你有新的成績: '你有新的成绩',
  查看成績單: '查看成绩单',
  在: '在',
  立即: '立即',
  成績將於5分鐘後公布: '成绩将于5分钟后公布',
  '本次{rank}棋力檢定：未通過': '本次{rank}棋力测评：未通过',
  維修中: '维修中',
  尚未開課: '尚未开课',
  當前課程不開放檢定: '当前课程不开放测评',
  尋找對手中: '匹配对手中',
  快速配對: '快速匹配',
  和朋友下棋: '和朋友下棋',
  '仍有進行中的對局，無法配對': '仍有进行中的对局，无法配对',
  找不到對手: '找不到对手',
  配對已取消: '匹配已取消',
  創建對局: '创建对局',
  建立: '建立',
  '{v1}路  - {v2} 分鐘': '{v1}路  - {v2} 分钟',
  '{v3}秒{v4}次': '{v3}秒{v4}次',
  邀請再一局: '邀请再一局',
  複製連結: '复制链接',
  發表情: '发表情',
  '第 {newDayText} 堂': '第 {newDayText} 堂',
  開放檢定: '开放测评',
  提示: '提示',
  點擊: '点击',
  按鈕可切換購買的新課程喔: '按钮可切换购买的新课程喔',
  '課程－做題': '课程－做题',
  '檢定－做題': '测评－做题',
  覆盤: '复盘',
  我要覆盤: '我要复盘',
  覆盤中: '复盘中',
  已覆盤: '已复盘',
  此局手數未達20無法進行覆盤: '此局手數未達20無法進行复盘',
  '課程 - 下棋': '课程 - 下棋',
  // 覆盤的評語會會因地區不同而用詞不同，CN版之後可能會改，先不做簡繁轉換，之後CN出來了再改
  '這手棋下得真好！': '这手棋下得真好！',
  '这是个很棒的【征子】，对方一定逃不走！':
    '這是個很棒的【征子】，對方一定逃不走！',
  '這是漂亮的【雙叫吃】，一定可以吃到其中一邊！':
    '这是漂亮的【双打吃】，一定可以吃到其中一边！',
  '這是厲害的【抱吃】！對方死定了！': '這是厲害的【抱吃】！對方死定了！',
  '這手【叫吃】的方向很正確': '这手【打吃】的方向很正确',
  '這是厲害的【門封】，對方逃不走了！': '这手【枷吃】很厉害，对方逃不走了！',
  '這是厲害的【接不歸】，對方想救也救不回去！':
    '这是厉害的【接不归】，对方想救也救不回去！',
  '這手【撲吃】下得很好，撲完再把對手吃回來！':
    '这手【扑吃】下得很好，扑完再把对手吃回来！',
  '這手棋是漂亮的【長氣】，氣比對方多，攻殺就會贏！':
    '这手棋是漂亮的【长气】，气比对方多，攻杀就会赢！',
  '漂亮的【逃走】，差點被對方吃掉！': '漂亮的【逃跑】，差点被对方吃掉！',
  '這是【要子】，非吃不可！': '这是【要子】，非吃不可！',
  '這手【連接】非常好，棋子變強了': '这手【连接】非常好，棋子变强了',
  '這是個很棒的【切斷】，對手慘了！': '这是个很棒的【切断】，对手惨了！',
  '這是個很棒的【雙】，連接得非常好！': '这是个很棒的【双】，连接得非常好！',
  '這是個很棒的【刺】，瞄準對方的斷點！':
    '这是个很棒的【刺】，瞄准对方的断点！',
  '這個【長】很棒': '这个【长】很棒',
  '這個【撞】很棒': '这个【撞】很棒',
  '這個【彎】很棒': '这个【弯】很棒',
  '這個【並】很棒': '这个【并】很棒',
  '這個【立】很棒': '这个【立】很棒',
  '這個【衝】很棒': '这个【冲】很棒',
  '這是個很棒的【擋】，這樣對手就過不來了！':
    '这是个很棒的【挡】，这样对手就过不来了！',
  '這個【扳】很棒': '这个【扳】很棒',
  '這個【尖】很棒': '这个【尖】很棒',
  '這個【虎】很棒': '这个【虎】很棒',
  '這個【跳】很棒': '这个【跳】很棒',
  '這個【飛】很棒': '这个【飞】很棒',
  '這個【定石】選得真好！': '这个【定石】选得真好！',
  '【切斷】這裡比較厲害！': '【切断】这里比较厉害！',
  '這個【連接】很重要唷': '这个【连接】很重要唷',
  '下在這裡會更厲害喔！': '下在这里会更厉害喔！',
  '這是【虎口】，會被吃掉！': '这是【虎口】，会被吃掉！',
  '這裡有個【雙叫吃】！一定可以吃到其中一邊！':
    '这里有个【双打吃】！一定可以吃到其中一边！',
  '下這裡就可以【征子】對方唷！': '下这里就可以【征吃】对方哦！',
  '應該下【門封】！把對方直接關起來！': '应该下【枷吃】！把对方直接关起来！',
  '這裡可以使用【抱吃】！對方死定了！': '这里可以使用【抱吃】！对方死定了！',
  '這樣【叫吃】方向才正確': '这样【打吃】方向才正确',
  '這裡有個【接不歸】，對方救不走的！': '这里有个【接不归】，对方救不走的！',
  '這裡可以使用【撲吃】！對方吃掉，我們再吃回來！':
    '这里可以使用【扑】！对方吃掉，我们再吃回来！',
  '這裡剩一氣！快逃跑！': '这里剩一气！快逃跑！',
  '這個要立刻吃掉，別讓對方逃走！': '这个要立刻吃掉，别让对方逃走！',
  '這裡不要救，再救也沒用！': '这里不要救，再救也没用！',
  '這個【叫吃】是吃不到對方的，不要下比較好唷！':
    '这个【打吃】是吃不到对方的，不要下比较好哦！',
  '這裡不能下，下這裡是送對手吃唷！': '这里不能下，下这里是送对手吃哦！',
  '要先下這裡把氣變多，才能繼續戰鬥！': '要先下这里把气变多，才能继续战斗！',
  '這裡要快點【連接】，被切斷的話會危險的！':
    '这里要快点【连接】，被切断的话会危险的！',
  '下這裡可以【切斷】對方，再看要攻擊哪一塊？':
    '下这里可以【切断】对方，再看要攻击哪一块？',
  下這裡可以一邊逃走一邊連接: '下这里可以一边逃走一边连接',
  '這個【定石】這樣下不太適合，下在這比較好':
    '这个【定式】这样下不太适合，下在这比较好',
  '現在這裡已經不重要了，應該【手拔】，下這裡更好！！':
    '现在这里已经不重要了，应该【手拔】，下这里更好！！',
  '現在應該【手拔】，下這個要點！': '现在应该【手拔】，下这个要点！',
  '這裡下【長】會更好喔！': '这里下【长】会更好喔！',
  '這裡下【彎】會更好喔！': '这里下【弯】会更好喔！',
  '這裡下【並】會更好喔！': '这里下【并】会更好喔！',
  '這裡下【立】會更好喔！': '这里下【立】会更好喔！',
  '這裡下【擠】會更好喔！': '这里下【挤】会更好喔！',
  '這裡下【撞】會更好喔！': '这里下【撞】会更好喔！',
  '這裡要用這種方式【叫吃】才厲害喔！': '这里要用这种方式【打吃】才厉害喔！',
  '這裡下【沖】會更好喔！': '这里下【冲】会更好喔！',
  '這裡下【擋】會更好喔！': '这里下【挡】会更好喔！',
  '這裡下【扳】會更好喔！': '这里下【扳】会更好喔！',
  '這裡下【尖】會更好喔！': '这里下【尖】会更好喔！',
  '這裡下【虎】會更好喔！': '这里下【虎】会更好喔！',
  '這裡下【跳】會更好喔！': '这里下【跳】会更好喔！',
  '這裡下【飛】會更好喔！': '这里下【飞】会更好喔！',
  '這裡下【刺】會更好喔！': '这里下【刺】会更好喔！',
  '這裡下【雙】來連接會更好喔！': '这里下【双】来连接会更好喔！',
  '現在應該【做眼】，來做活這塊棋！': '现在应该【做眼】，来做活这块棋！',
  '現在應該【破眼】，來攻擊這塊棋！': '现在应该【破眼】，来攻击这块棋！',
  '這個【連接】很重要唷～': '这个【连接】很重要喔！',
  好棋: '好棋',
  AI對弈: 'AI对弈',
  好友對戰: '好友对战',
  直播講堂: '直播讲堂',
  找朋友: '找朋友',
  對AI: '对AI',
  如何使用平台: '如何使用平台',
  '通關 AI 對弈 Lv.{aiLevel}': '通关 AI 对弈 Lv.{aiLevel}',
  '學習進度星星達 {count} 顆': '学习进度星星达 {count} 颗',
  下列條件滿足後開放檢定: '下列条件满足后开放测评',
  '【檢定】改版說明': '【测评】改版说明',
  '【直播講堂】規則說明': '【直播讲堂】规则说明',
  '【AI 對弈】改版說明': '【AI 对弈】改版说明',
  獲勝即解鎖下一關: '获胜即解锁下一关',
  連贏: '连赢',
  '次解鎖下一關(': '次解锁下一关(',
  '達成 ': '达成 ',
  ' 檢定「通關 AI 對弈 Lv.': '测评「通关 AI 对弈 Lv.',
  '」條件': '」条件',
  落點提示: '落点提示',
  '【落點提示】使用說明': '【落点提示】使用说明',
  '在對局中使用【落點提示】，AI 將提供 3 個落點，其中一點會是最佳落點。落點提示功能每一局只能使用 10 次。':
    '在对局中使用【落点提示】，AI 将提供 3 个落点，其中一点会是最佳落点。落点提示功能每一局只能使用 10 次。',
  查看正解: '查看正解',
  正解: '正解',
  回到問題手: '回到问题手',
  '切換{text}': '切换{text}',
  切換程度: '切换程度',
  切換類型: '切换类型',
  切換老師: '切换老师',
  切換時間: '切换时间',
  今天: '今天',
  明天: '明天',
  本週: '本周',
  下週: '下周',
  分類: '分类',
  老師: '老师',
  時間: '时间',
  '已結束（可播放的課程）': '已结束（可播放的课程）',
  全部: '全部',
  篩選條件: '筛选条件',
  研修: '研修',
  段位班: '段位班',
  '研修班（含進階班）': '研修班（含进阶班）',
  於: '于',
  下架: '下架',
  程度: '程度',
  類型: '类型',
  您目前篩選的條件沒有資料: '您目前筛选的条件没有资料',
  設定提醒失敗: '设定提醒失败',
  '直播講堂到期日還有 {day} 天，請點此前往續費':
    '直播讲堂到期日还有 {day} 天，请点此前往续费',
  您的直播講堂已到期: '您的直播讲堂已到期',
  播放: '播放',
  此直播無法回放: '此直播无法回放',
  不分齡: '不分龄',
  兒童: '儿童',
  變化圖運算中: '变化图计算中',
  變化圖: '变化图',
  規則說明: '规则说明',
  直播講堂列表: '直播讲堂列表',
  '對局已結束，可以算輸贏囉！': '对局已结束，可以申请判胜负喽！',
  '檢定－下棋': '测评－下棋',
  邀請對局: '邀请对局',
  '在黑嘉嘉圍棋教室等你一決高下～': '在黑嘉嘉围棋教室等你一决高下～',
  無讓子: '无让子',
  直播時數: '直播时数',
  歷史紀錄: '历史纪录',
  直播講堂到期日: '直播讲堂到期日',
  直播講堂規則說明: '直播讲堂规则说明',
  '到期：直播講堂到期後，將無法觀看任何內容。':
    '到期：直播讲堂到期后，将无法观看任何内容。',
  '計算方式：以每月 30 天，不分大小月的方式來計算。如有購買多堂課程或續購直播講堂方案，時數將自動累加延長。':
    '计算方式：以每月 30 天，不分大小月的方式来计算。如有购买多堂课程或续购直播讲堂方案，时数将自动累加延长。',
  'LIVE結束：每個直播講堂結束後將保留 90 天。若錯過喜歡的講堂，可以在直播列表的篩選器中，選擇時間的【已結束（可播放的課程）】分類，找到該講堂點擊【播放】觀看喔！':
    'LIVE结束：每个直播讲堂结束后将保留 90 天。若错过喜欢的讲堂，可以在直播列表的筛选器中，选择时间的【已结束（可播放的课程）】分类，找到该讲堂点击【播放】观看喔！',
  個月: '个月',
  贈送: '赠送',
  '必修直播講堂（購買兒童啟蒙班）': '必修直播讲堂（购买儿童启蒙班）',
  '您還沒有購買任何課程，快來加入我們一起快樂學圍棋！':
    '您还没有购买任何课程，快来加入我们一起快乐学围棋！',
  您可以前往官網購買課程: '您可以前往官网购买课程',
  '需購買課程才能開放{type}': '需购买课程才能开放{type}',
  '當前課程不開放{type}': '当前课程不开放{type}',
  您可以切換其他課程或前往官網購買: '您可以切换其他课程或前往官网购买',
  推播通知: '推播通知',
  编辑: '编辑',
  刪除帳號: '删除帐号',
  開始試下: '开始试下',
  結束試下: '结束试下',
  '這盤棋沒有問題手喔！': '这盘棋没有问题手喔！',
  功能: '功能',
  來源: '來源',
  這邊可以查看推播通知喔: '这边可以查看推播通知喔',
  已經滑到底了: '已经滑到底了',
  目前沒有推播通知: '目前没有推播通知',
  取得推播通知錯誤: '取得推播通知错误',
  '已幫您切換至{name}課程': '已帮您切换至{name}课程',
  前往官網購買時數: '前往官网购买时数',
  購買直播講堂: '购买直播讲堂',
  購買: '购买',
  '【推播通知】功能說明': '【推播通知】功能说明',
  '貼心小提醒！': '贴心小提醒！',
  'iOS 設定方式': 'iOS 设定方式',
  安卓設定方式: '安卓设定方式',
  課後做題: '课后做题',
  '加入LINE@聯繫客服': '加入LINE@联系客服',
  '聯繫［黑嘉嘉圍棋教室］LINE 客服刪除帳號':
    '联系［黑嘉嘉围棋教室］LINE 客服删除帐号',
  '試下（限教學）': '试下（限教学）',
  登入失敗: '登录失败',
  學習歷程: '学习历程',
  今天休息: '今天休息',
  明天再加油: '明天再加油',
  該名稱不得使用: '该名称不得使用',
  棋力: '棋力',
  立即前往: '立即前往',
  追求更棒的自己: '追求更棒的自己',
  當前形勢: '当前形势',
  項目: '项目',
  數據記錄: '数据记录',
  棋盤座標: '棋盘座标',
  圍棋紀錄: '围棋纪录',
  所有功能: '所有功能',
  '嘉嘉小提醒：': '嘉嘉小提醒：',
  '- 最近一筆': '- 最近一笔',
  目前沒有紀錄: '目前没有纪录',
  電子信箱: '电子信箱',
  請輸入電子信箱: '请输入电子信箱',
  '按下註冊後請至信箱收信，完成信箱驗證後即可登入！':
    '按下注册后请至信箱收信，完成信箱验证后即可登入！',
  '設定 8-15 位英數密碼': '设定 8-15 位英数密码',
  已送出: '已送出',
  '請至信箱收信，完成信箱驗證後即可登入！':
    '请至信箱收信，完成信箱验证后即可登入！',
  '歡迎加入黑嘉嘉圍棋教室，一起快樂學習！':
    '欢迎加入黑嘉嘉围棋教室，一起快乐学习！',
  時效已過: '时效已过',
  驗證失敗: '验证失败',
  '此驗證信時效已到期，將自動轉回首頁': '此验证信时效已到期，将自动转回首页',
  '自動跳轉至首頁({debounceSec})': '自动跳转至首页({debounceSec})',
  尚未註冊: '尚未註冊',
  序號已被使用: '序号已被使用',
  密碼錯誤: '密码错误',
  '想要隨時隨地上課，也可以下載手機 APP 唷！':
    '想要随时随地上课，也可以下载手机 APP 唷！',
  課程專屬平台: '课程专属平台',
  請輸入密碼: '请输入密码',
  手機號碼: '手机号码',
  請輸入正確的電子信箱: '请输入正确的电子信箱',
  '伺服器發生錯誤，請稍後再試': '伺服器发生错误，请稍后再试',
  '成為學員，享受對弈樂趣': '成为学员，享受对弈乐趣',
  請輸入驗證碼: '请输入验证码',
  請至信箱收信: '请至信箱收信',
  兩次密碼輸入不同: '两次密码输入不同',
  該使用者已註冊: '该使用者已注册',
  自動跳轉登入頁面: '自动跳转登入页面',
  開始你的圍棋學習之旅: '开始你的围棋学习之旅',
  '按下送出後請至信箱收信，點擊信中連結即可重設密碼。':
    '按下送出後請至信箱收信，點擊信中連結即可重設密碼。',
  '此驗證信時效已到期，將幫您導回登入頁。':
    '此验证信时效已到期，将帮您导回登入页。',
  重設密碼成功: '重设密码成功',
  請使用新設定的密碼登入: '请使用新设定的密码登入',
  請至信箱收信以重設密碼: '请至信箱收信以重设密码',
  此棋院代號已綁定: '此棋院代号已绑定',
  此棋院代號不存在: '此棋院代号不存在',
  '輸入成功，您已與': '输入成功，您已与',
  棋院綁定: '棋院绑定',
  確定要移除: '确定要移除',
  成功移除所屬棋院: '成功移除所属棋院',
  移除: '移除',
  我所屬的棋院: '我所属的棋院',
  尚無所屬棋院: '尚无所属棋院',
  請輸入棋院代號: '请输入棋院代号',
  棋院代號: '棋院代号',
  基本資料: '基本资料',
  不分齡課程: '不分龄课程',
  兒童課程: '儿童课程',
  技術專題: '技术专题',
  NFT專屬課程: 'NFT专属课程',
  課程地圖: '课程地图',
  更多: '更多',
  商城: '商城',
  '圍棋社群（Discord）': '围棋社群（Discord）',
  '實體活動（Accupass）': '实体活动（Accupass）',
  官方網站: '官方网站',
  重要連結: '重要连结',
  快速連結: '快速连结',
  其他功能: '其他功能',
  '目前沒有持有 NFT': '目前没有持有 NFT',
  優惠券: '优惠券',
  編輯棋院: '编辑棋院',
  姓名: '姓名',
  電話: '电话',
  編輯姓名: '编辑姓名',
  信箱: '信箱',
  棋院: '棋院',
  級: '级',
  段: '段',
  '預計於 11/18 揭曉 NFT 造型，': '预计于 11/18 揭晓 NFT 造型，',
  '敬請期待！': '敬请期待！',
  '查無此 NFT': '查无此 NFT',
  查看棋譜: '查看棋谱',
  屬性: '属性',
  細節: '细节',
  詳細賦能及說明: '详细赋能及说明',
  下載失敗: '下载失败',
  敘述: '叙述',
  '黑{number}目勝': '黑{number}目胜',
  '白{number}目勝': '白{number}目胜',
  可使用: '可使用',
  已失效: '已失效',
  前往購買: '前往购买',
  關閉: '关闭',
  前往查看: '前往查看',
  '完成綁定後，可以用該{type}登入帳號。':
    '完成绑定后，可以用该{type}登入帐号。',
  '原帳號發生無法登入等問題，可以透過綁定的{type}恢復帳號。':
    '原帐号发生无法登入等问题，可以透过绑定的{type}恢复帐号。',
  '手機號碼及信箱只會被用來寄送黑嘉嘉圍棋教室通知簡訊/郵件；我們或任何第三方公司不會透過這個聯絡資料寄送垃圾簡訊/郵件。':
    '手机号码及信箱只会被用来寄送黑嘉嘉围棋教室通知简讯/邮件；我们或任何第三方公司不会透过这个联络资料寄送垃圾简讯/邮件。',
  '您使用的{type}已與其他黑嘉嘉圍棋教室帳號綁定。':
    '您使用的{type}已与其他黑嘉嘉围棋教室帐号绑定。',
  '恭喜你通過 {rank} 檢定！': '恭喜你通过 {rank} 检定！',
  '＼棋味無窮 初段成就達成／': '＼棋味无穷 初段成就达成／',
  '真是太棒了，你已經成為小小棋士囉～想要和其他小朋友一起下棋嗎？快來看看家裡附近有哪些圍棋教室！':
    '真是太棒了，你已经成为小小棋士啰～想要和其他小朋友一起下棋吗？快来看看家里附近有哪些围棋教室！',
  '繼續跟著黑咩、白咩一起，向歸一爺爺學習更多的圍棋知識吧～系統將提供專屬折扣，快去看看！':
    '继续跟著黑咩、白咩一起，向归一爷爷学习更多的围棋知识吧～系统将提供专属折扣，快去看看！',
  '你已經跨越初學者階段，準備好進入19路大棋盤了嗎～系統將提供專屬折扣，快去看看！':
    '你已经跨越初学者阶段，准备好进入19路大棋盘了吗～系统将提供专属折扣，快去看看！',
  '持之以恆的學習，就能得到滿滿的收穫！為了鼓勵你繼續加油，系統將提供專屬折扣，快去看看！':
    '持之以恒的学习，就能得到满满的收获！为了鼓励你继续加油，系统将提供专属折扣，快去看看！',
  '太厲害了，你已達成重要的里程碑—通過10級檢定！系統將提供專屬折扣，快去看看！':
    '太厉害了，你已达成重要的里程碑—通过10级检定！系统将提供专属折扣，快去看看！',
  '此刻你已掌握19x19宇宙的多數觀念，能在每次對弈中獲得無窮的樂趣！系統將提供專屬折扣，快去看看！':
    '此刻你已掌握19x19宇宙的多数观念，能在每次对弈中获得无穷的乐趣！系统将提供专属折扣，快去看看！',
  '我們又往「初段」的路上更邁進一步了！為了鼓勵你繼續加油，系統將提供專屬折扣，快去看看！':
    '我们又往「初段」的路上更迈进一步了！为了鼓励你继续加油，系统将提供专属折扣，快去看看！',
  '太棒了，你已完成重大成就—通過初段檢定！系統將提供限時折扣金500元，期待與你在通往神乎棋技的道路上再次相':
    '太棒了，你已完成重大成就—通过初段检定！系统将提供限时折扣金500元，期待与你在通往神乎棋技的道路上再次相',
  虛手: '虚手',
  對手虛手: '对手虚手',
  白: '白',
  問題類別: '问题类别',
  請選擇類別: '请选择类别',
  請輸入內容: '请输入内容',
  '恭喜你完成第 {index} 課': '恭喜你完成第 {index} 课',
  課程結束: '课程结束',
  看下一課: '看下一课',
  記憶力題型: '记忆力题型',
  題目尚未設定: '题目尚未设定',
  '不分齡－全部': '不分龄－全部',
  '兒童－全部': '儿童－全部',
  列表查詢: '列表查询',
  進行中: '进行中',
  所有直播: '所有直播',
  尚未開始的直播: '尚未开始的直播',
  '已結束的直播（重播）': '已结束的直播（重播）',
  搜尋直播講堂或老師名稱: '搜寻直播讲堂或老师名称',
  搜尋: '搜寻',
  狀態: '状态',
  日曆查詢: '日历查询',
  週: '周',
  月: '月',
  年: '年',
  日: '日',
  一: '一',
  二: '二',
  三: '三',
  四: '四',
  五: '五',
  六: '六',
  沒有直播講堂: '没有直播讲堂',
  沒有直播: '没有直播',
  試試其他篩選條件或關鍵字: '试试其他筛选条件或关键字',
  清除: '清除',
  最近的搜尋: '最近的搜寻',
  目前沒有直播時數: '目前没有直播时数',
  您可以前往官網購買方案: '您可以前往官网购买方案',
  沒有搜尋到: '没有搜寻到',
  相關的直播課程: '相关的直播课程',
  沒有找到相關直播課: '没有找到相关直播课',
  已設通知: '已设通知',
  通知我: '通知我',
  重播: '重播',
};

export default lang;
