<template>
  <div>
    <modal-wrapper
      :is-modal-wrapper-show="sheetShow"
      :is-show-transition="true"
      @close="close"
    >
      <div
        class="modal-language overflow-hidden position-absolute bg-white"
        @click.stop
      >
        <div
          class="head pl-3 py-2 d-flex justify-content-between align-items-center"
        >
          <span class="font-weight-bold text-18"> {{ $t('選擇語言') }}</span>
          <i
            class="icon-X text-32 text-primary cursor-pointer"
            @click="close"
          ></i>
        </div>
        <div class="list">
          <div
            v-for="language in languages"
            :key="language.value"
            class="item d-flex justify-content-between align-items-center cursor-pointer"
            :class="{active: language.value === currentLanguage}"
            @click="onClickChangeLanguage(language.value)"
          >
            <span> {{ language.label }}</span>
            <i
              v-if="language.value === currentLanguage"
              class="icon-Check text-32"
            ></i>
          </div>
        </div>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
import languages from '@/json/languages.json';

export default {
  components: {
    ModalWrapper,
  },
  data() {
    return {
      languages,
      sheetShow: true,
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.state.env.currentLanguage;
    },
  },
  methods: {
    close() {
      this.$store.commit('env/setIsChangeLanguageModalShow', false);
    },
    onClickChangeLanguage(language) {
      this.$store.dispatch('env/changeLanguage', language);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-language {
  width: 300px;
  border-radius: 16px;
  .head {
    color: $font-grayscale-1;
  }
  .icon-X {
    padding: 6px;
  }
  .item {
    line-height: 32px;
    padding: 9px 16px;
    transition: all 0.3s;
    border-top: 1px solid $line-1;

    &:hover {
      color: $secondary;
    }
    &.active {
      color: $secondary;
    }
  }
}
</style>
