class BackEvent {
  constructor() {
    this.backEventBus = [];
  }
  addEvent(event, tag) {
    this.backEventBus.push({tag, cb: event});
  }
  emit() {
    const cb = this.backEventBus[this.backEventBus.length - 1].cb;
    cb();
  }
  popEvent() {
    this.backEventBus.splice(this.backEventBus.length - 1, 1);
  }
  clearEvents(tag) {
    if (tag != null) {
      this.backEventBus = this.backEventBus.filter((event) => {
        return event.tag !== tag;
      });
    } else {
      this.backEventBus = [];
    }
  }
}

export default new BackEvent();
