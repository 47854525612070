/* eslint-disable require-jsdoc */
import {callLambda} from '@/lib/api/lambda';
// import _ from 'lodash';

function quickMatch({boardSize, hasHandicap}) {
  const params = {
    boardSize,
    hasHandicap,
  };
  return callLambda({
    category: 'pvp',
    method: 'get',
    apiName: 'quickMatch',
    params,
  })
    .then((res) => {
      return res.data || '';
    })
    .catch((error) => {
      return error;
    });
}

/**
 * 取得對戰單一棋譜資料
 * @param {String} id id
 * @returns {Promise} promise
 */
function getPvpKifu(id) {
  return callLambda({
    category: 'pvp',
    apiName: id,
    method: 'get',
  }).then((result) => {
    return result.data;
  });
}

export default {
  quickMatch,
  getPvpKifu,
};
