<template>
  <b-container v-if="isOffline" class="offline-container">
    <div>
      <img
        width="200"
        class="img-fluid"
        :srcset="require('@/assets/img/offline.png')"
      />
      <p>
        {{ $t('目前沒有網路') }}
        <br />
        {{ $t('無法使用耶') }}
      </p>
    </div>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      isOffline: false,
      // isOffline: true,
    };
  },
  async created() {
    window.addEventListener('offline', () => {
      this.isOffline = true;
    });
    window.addEventListener('online', () => {
      this.isOffline = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.offline-container {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: $bgcontainer;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
  }
  p {
    margin: 24px 0 0 0;
    text-align: center;
    color: rgba(48, 49, 51, 0.5);
    font-size: 20px;
    font-weight: bold;
    line-height: 1.7;
  }
}
</style>
