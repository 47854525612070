import Vue from 'vue';
import VueI18n from 'vue-i18n';
import numberFormats from './numberFormats';
import {locale} from '@/constant/env';

import TW from './lang/TW';
import CN from './lang/CN';
import JP from './lang/JP';

Vue.use(VueI18n);

const missingKeys = {};

const i18n = new VueI18n({
  locale,
  messages: {'zh-TW': TW, 'zh-CN': CN, 'ja-JP': JP},
  numberFormats,
  missing: (locale, key) => {
    console.log('missing key', JSON.stringify(missingKeys), key);
    return key;
  },
});

export default i18n;
