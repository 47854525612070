/* eslint-disable require-jsdoc */
import axios from 'axios';
import url from './url';
import {getLocalData} from '@/lib/base/localData';

const apiErrorHandlers = [];

function onApiError(callback) {
  apiErrorHandlers.push(callback);
}

/**
 * 呼叫lambda函式，並可以將資料存在 APP 內(但是不包含讀取)
 * @param {String} category lambda的API類別
 * @param {String} apiName 該API的名稱
 * @param {Object} data 傳入該API的資料
 * @param {Object} method [post,get] API的方法等
 * @param {Object} headers request的header
 *
 * @returns {Promise<Object>} API回傳結果
 */
function callLambda({
  category,
  apiName = '',
  data,
  params,
  headers = {},
  method,
}) {
  method = method.toLowerCase();
  const apiUrl = url.lambda[category] + apiName;

  const sendHeaders = Object.assign(headers, {
    Authorization: getLocalData('jwt'),
    Accept: 'application/json',
  });

  // #endregion
  return axios({
    method,
    url: apiUrl,
    data,
    params,
    headers: sendHeaders,
  }).catch((error) => {
    for (const handler of apiErrorHandlers) {
      handler(error, {category, apiName, data, params, headers, method});
    }

    return Promise.reject(error);
  });
}

export {callLambda, onApiError};
