import Vue from 'vue';
import '@/assets/scss/message.scss';
import ERROR from '@/constant/error';
import soundService from '@/lib/base/soundService';

const defaultConfig = {
  position: 'top-center',
  hideProgressBar: true,
  closeButton: false,
  duration: 1000 * 3,
};

class Message {
  static success(message, duration = defaultConfig.duration) {
    soundService.playSound('messagePop');
    const id = Vue.$toast.success(
      message,
      Object.assign(defaultConfig, {
        timeout: duration,
        icon: {
          iconClass: 'icon-Circle-check',
          iconChildren: '',
          iconTag: 'i',
        },
      })
    );
    return () => {
      Vue.$toast.dismiss(id);
    };
  }

  static info(message, duration = defaultConfig.duration) {
    soundService.playSound('messagePop');
    const id = Vue.$toast.info(
      message,
      Object.assign(defaultConfig, {
        timeout: duration,
        icon: {
          iconClass: 'icon-Elert-circle',
          iconChildren: '',
          iconTag: 'i',
        },
      })
    );
    return () => {
      Vue.$toast.dismiss(id);
    };
  }

  static warning(message, duration = defaultConfig.duration) {
    soundService.playSound('messagePop');
    const id = Vue.$toast.warning(
      message,
      Object.assign(defaultConfig, {
        timeout: duration,
        icon: {
          iconClass: 'icon-Elert-circle',
          iconChildren: '',
          iconTag: 'i',
        },
      })
    );
    return () => {
      Vue.$toast.dismiss(id);
    };
  }

  static error(message, duration = defaultConfig.duration, defaultText = '') {
    soundService.playSound('messagePop');
    if (message instanceof Object) {
      const data = message?.response?.data;
      if (Array.isArray(data?.errors)) {
        message = ERROR[data?.errors[0]];
      } else {
        message = ERROR[data?.message];
      }
      message = message || defaultText;
    }
    const id = Vue.$toast.error(
      message,
      Object.assign(defaultConfig, {
        timeout: duration,
        icon: {
          iconClass: 'icon-Circle-X',
          iconChildren: '',
          iconTag: 'i',
        },
      })
    );
    return () => {
      Vue.$toast.dismiss(id);
    };
  }
}

export default Message;
