const mixinObject = {
  methods: {
    waitUntil(
      argsConditionMap,
      callback,
      config = {deep: true, immediate: true}
    ) {
      const args = Object.keys(argsConditionMap);

      const cancelWatcher = this.$watch(
        (vm) => {
          const obj = {};
          args.forEach((arg) => (obj[arg] = getValueFromDotNotation(vm, arg)));
          return obj;
        },
        (data) => {
          const isCheckPassed = Object.keys(data).every((key) => {
            if (typeof argsConditionMap[key] === 'object') {
              if (argsConditionMap[key].$exists === true && data[key] == null) {
                return false;
              }
              if (
                argsConditionMap[key].$not != null &&
                data[key] === argsConditionMap[key].$not
              ) {
                return false;
              }
              return true;
            }
            return argsConditionMap[key] === data[key];
          });
          if (isCheckPassed) {
            this.$nextTick(() => {
              cancelWatcher();
            });
            callback();
          }
        },
        config
      );

      return cancelWatcher;
    },
  },
};

/**
 * 解析dot notation，取得物件屬性
 * @param {Object} obj 物件
 * @param {String} dotNotation 以dot表示的物件屬性
 * @returns {Any} 物件屬性
 */
function getValueFromDotNotation(obj, dotNotation) {
  const attrs = dotNotation.split('.');
  let current = obj;
  while (attrs.length > 0) {
    const attr = attrs.shift();
    current = current[attr];
  }
  return current;
}

export default mixinObject;
