import getDeviceInfo from '@/lib/base/getDeviceInfo';
import {clientVersion} from '@/constant/env.js';
import {saveLocalData} from '@/lib/base/localData';
import store from '@/store/index.js';
import i18n from '@/i18n/index.js';
import Message from '@/lib/base/message';

const state = {
  viewMode: '',
  device: '',
  orientation: '',
  width: 0,
  height: 0,
  isLoading: false,
  isReviewGameLoading: false,
  isSwitchCourseSheetShow: false,
  isChangeLanguageModalShow: false,
  isSidebarShow: false,
  hasVerificationNotification: false,
  isNftComingSoonShow: false,
  currentLanguage: 'ja-JP',
  nftPlans: [
    'kidstart-v2',
    'kidstartplus',
    'kidint',
    'kidadv',
    '30k-10k-v2',
    '10k-8k',
    '7k-6k',
    'k-d',
    '1d-4d',
    'kidstart',
    '30k-10k',
    '1d-2d',
    '1d-2d-22sum',
  ],
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  isReviewGameLoading(state) {
    return state.isReviewGameLoading;
  },
  viewMode(state) {
    return state.viewMode;
  },
  device(state) {
    return state.device;
  },
  isCN() {
    return clientVersion === 'hjjcn';
  },
  isJungo() {
    return clientVersion === 'hjj-jungo';
  },
  permission() {
    return store.getters['course/currentPlan']?.contents || {};
  },
};

const actions = {
  init({commit}) {
    const data = getDeviceInfo();
    commit('setEnv', data);
  },
  changePlanInit({}, plan) {
    saveLocalData('plan-info', JSON.stringify(plan));
    if (plan.id === 'kid2week') {
      store.dispatch('liveCourse/getKid2WeekLiveCourses');
    } else {
      store.commit('liveCourse/setDailyLiveList', []);
    }
    store.commit('course/setCurrentPlan', plan);
    store.dispatch('course/getCourses', plan.id);
    store.dispatch('verification/getVerification', plan.id);
    store.dispatch('tsumeGame/getPracticeTsumeList', plan.id);
    store.dispatch('course/getCourses', plan.id);
    Message.success(i18n.t('已切換課程'), 3000);
  },
  changeLanguage({commit}, language) {
    i18n.locale = language;
    saveLocalData('language', language);
    commit('setCurrentLanguage', language);
  },
};

const mutations = {
  setEnv(state, data) {
    const {viewMode, device, orientation, height, width} = data;
    state.viewMode = viewMode;
    state.device = device;
    state.orientation = orientation;
    state.width = width;
    state.height = height;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setIsReviewGameLoading(state, value) {
    state.isReviewGameLoading = value;
  },
  setIsSwitchCourseSheetShow(state, value) {
    state.isSwitchCourseSheetShow = value;
  },
  setIsSidebarShow(state, value) {
    state.isSidebarShow = value;
  },
  setHasVerificationNotification(state, value) {
    state.hasVerificationNotification = value;
  },
  setIsNftComingSoonShow(state, value) {
    state.isNftComingSoonShow = value;
  },
  setIsChangeLanguageModalShow(state, value) {
    state.isChangeLanguageModalShow = value;
  },
  setCurrentLanguage(state, value) {
    state.currentLanguage = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
