import notificationService from '@/services/notification';

const state = {
  notifications: [],
};

const getters = {
  notifications(state) {
    return state.notifications;
  },
  hasUnread(state) {
    return state.notifications.some((notification) => !notification.isRead);
  },
};

const actions = {
  async getNotifications({commit}, limit) {
    const result = await notificationService.getNotifications(limit);
    commit('setNotifications', result);
  },
  async updateNotifications({commit, state}) {
    await notificationService.updateNotifications(
      state.notifications[0].createdAt
    );
    await actions.getNotifications({commit}, 50);
  },
  async setNotificationModal({commit}, sk) {
    await notificationService.setNotificationModal({
      sk,
      hasModalShown: true,
    });
    await actions.getNotifications({commit}, 50);
  },
};

const mutations = {
  setNotifications(state, data) {
    state.notifications = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
