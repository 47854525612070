/* eslint-disable require-jsdoc */
import Cookies from 'js-cookie';
import {shareJwt} from '@/constant/env';

const isHttps = document.location.protocol === 'https:';
const isApp = () => navigator.userAgent.includes('heijiajia') || window.isApp;

/**
 * 儲存到使用者端本地資料 (cookie and LocalStorage)
 * @param {String} key key
 * @param {String} data data
 */
function saveLocalData(key, data) {
  if (key === 'jwt' && !isApp()) {
    if (shareJwt && isHttps) {
      Cookies.set('jwt', data, {
        domain: location.hostname.substring(location.hostname.indexOf('.')),
      });
    } else {
      Cookies.set('jwt', data);
    }
  } else {
    localStorage.setItem(key, data);
  }
}

/**
 * 取得使用者端本地資料 (cookie and LocalStorage)
 * @param {String} key key
 * @return {String} 取得的值
 */
function getLocalData(key) {
  if (key === 'jwt' && !isApp()) {
    return Cookies.get('jwt');
  }
  return localStorage.getItem(key);
}

/**
 * 刪除使用者端本地資料 (cookie and LocalStorage)
 * @param {String} key key
 */
function removeLocalData(key) {
  if (key === 'jwt' && !isApp()) {
    if (shareJwt && isHttps) {
      Cookies.remove('jwt', {
        domain: location.hostname.substring(location.hostname.indexOf('.')),
      });
    }
    Cookies.remove('jwt');
    localStorage.removeItem('jwt');
  } else {
    localStorage.removeItem(key);
  }
}

/**
 * 設定限時儲存使用者端本地資料
 * @param {String} key key
 * @param {String} data key
 * @param {String} expiry key
 */
function setTemporaryData(key, data, expiry) {
  const item = {
    data,
    expiry: Number(expiry),
  };
  localStorage.setItem(key, JSON.stringify(item));
}

/**
 * 取得限時使用者端本地資料
 * @param {String} key key
 * @return {String} 取得的值
 */
function getDataWithExpiry(key) {
  let item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  item = JSON.parse(item);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.data;
}

export {
  saveLocalData,
  getLocalData,
  removeLocalData,
  setTemporaryData,
  getDataWithExpiry,
};
