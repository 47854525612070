/* eslint-disable require-jsdoc */
const removeCanvasOnDestroy = (element) => {
  try {
    // nodeType 8 is html comment, querySelector is nt working on html comment
    if (element && element.nodeType !== 8) {
      const canvasElementList = element.querySelectorAll('canvas');
      canvasElementList.forEach((canvas) => {
        canvas.height = 0;
        canvas.width = 0;
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export {removeCanvasOnDestroy};
