import dayjs from 'dayjs';
import {saveLocalData, removeLocalData} from '@/lib/base/localData';
import userService from '@/services/user';
import aiGameService from '@/services/aiGame';
import verificationService from '@/services/verification';
import router from '@/router';
import socket from '@/lib/socket/socket.js';
import store from '@/store/index.js';

/**
 * @returns {Object} initialize state
 */
const initialState = () => {
  return {
    userData: {},
    nickName: null,
    username: null,
    gender: null,
    verifyOptData: {},
    userStatistics: {},
    purchaseLogs: null,
    guestDetail: null,
  };
};
const state = initialState();
const getters = {
  userData(state) {
    return state.userData;
  },
  isLogin(state) {
    return !!state.userData?.username;
  },
  verifyOptData(state) {
    return state.verifyOptData;
  },
  userStatistics(state) {
    return state.userStatistics;
  },
  hasPermission(state) {
    return (permission) =>
      state.userData.currentPlanPermissions &&
      state.userData.currentPlanPermissions.includes(permission);
  },
  hasLegacyAdvancedPlanActivated(state) {
    return state.userData.isIntelligentVersionActive;
  },
  liveCourseExpiredDay() {
    const now = dayjs();
    const expired = dayjs(state.userData?.liveCourseEndAt);
    const hour = expired.diff(now, 'hour');
    const expiredDay = Math.ceil(hour / 24);
    return expiredDay;
  },
  purchaseLogs(state) {
    return state.purchaseLogs;
  },
  isTeacher(state) {
    return state.userData?.academyInfo?.role?.isTeacher;
  },
  isEmailSignUp() {
    return state.userData.username.indexOf('@') > 0;
  },
  isBindedEmail(state) {
    return state.userData.username.indexOf('@') < 0 && state.userData?.email;
  },
  isBindedMobile() {
    return (
      state.userData.username.indexOf('@') > 0 && state.userData?.phone !== '-'
    );
  },
};
const actions = {
  login({commit}, {username, password}) {
    removeLocalData('jwt');
    return userService.login(username, password).then((loginData) => {
      commit('setJwt', `Bearer ${loginData.data.token}`);
    });
  },
  thirdPartyLogin({commit}, {token, platform, options}) {
    return userService
      .thirdPartyLogin({token, platform, options})
      .then((loginData) => {
        commit('setJwt', `Bearer ${loginData.data.token}`);
        if (platform === 'guest') {
          commit('setGuestDetail', loginData.data);
        }
      });
  },
  loginWithJwt({commit}) {
    return userService.getUserData().then((user) => {
      commit('setLogin', user);
    });
  },
  logout({commit}) {
    commit('setLogout');
    return router.push({name: 'login'});
  },
  changePassword({}, {oldPassword, newPassword}) {
    return userService.changePassword({oldPassword, newPassword});
  },
  resetPassword({}, {password, verificationId, verificationCode, isEmail}) {
    return userService.resetPassword({
      password,
      verificationId,
      verificationCode,
      isEmail,
    });
  },
  sendResetPasswordOtp({}, {internationalPhoneNumber, email, redirect}) {
    return userService.sendResetPasswordOtp({
      internationalPhoneNumber,
      email,
      redirect,
    });
  },
  validateResetPasswordOtp({commit}, verifyOptData) {
    commit('setOpt', verifyOptData);
    return userService.validateResetPasswordOtp(verifyOptData);
  },
  sendSignupOpt({}, {internationalPhoneNumber}) {
    return userService.sendSignupOpt({
      internationalPhoneNumber,
    });
  },
  validateSignupOpt({commit}, verifyOptData) {
    commit('setOpt', verifyOptData);
    return userService.validateSignupOpt(verifyOptData);
  },
  signup({}, {username, academyId, password, phone, realName, nickName}) {
    return userService.signup({
      username,
      academyId,
      password,
      phone,
      realName,
      nickName,
    });
  },
  sendSignupOtpEmail(
    {},
    {email, academyId, password, realName, nickName, redirect}
  ) {
    return userService.sendSignupOtpEmail({
      email,
      academyId,
      password,
      realName,
      nickName,
      redirect,
    });
  },
  validateSignupOtpEmail({}, {id, code}) {
    return userService.validateSignupOtpEmail(id, code);
  },
  activateByCode({}, code) {
    return userService.activateByCode(code);
  },
  getUserStatistics({commit}) {
    return userService.getUserStatistics().then((response) => {
      commit('setUserStatistics', response);
    });
  },
  async getOwnPurchaseLog({commit}) {
    const result = await userService.getOwnPurchaseLog();
    commit('setPurchaseLogs', result.data);
  },
};
const mutations = {
  setLogin(state, user) {
    saveLocalData('user', JSON.stringify(user));
    state.userData = user;
  },
  setJwt(state, jwt) {
    saveLocalData('jwt', jwt);
  },
  setLogout() {
    socket.destroy();
    userService.logout();
    removeLocalData('jwt');
    removeLocalData('user');
    // removeLocalData('plan-info');
    removeLocalData('course-types-status');
    userService.getUserData?.cache.clear();
    aiGameService.getPracticeGameList?.cache.clear();
    verificationService.getTotalScores?.cache.clear();
    verificationService.getRecords?.cache.clear();
    store.commit('user/resetState');
    store.commit('course/resetState');
    store.commit('verification/resetState');
  },
  setOpt(state, verifyOptData) {
    state.verifyOptData = verifyOptData;
  },
  setUserStatistics(state, value) {
    state.userStatistics = value;
  },
  setPurchaseLogs(state, data) {
    state.purchaseLogs = data;
  },
  setGuestDetail(state, data) {
    state.guestDetail = data;
  },
  resetState(state) {
    const _state = initialState();
    Object.assign(state, _state);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
